import { Checkbox, FormControl, MenuItem, Select } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
// material-ui
import Typography from "@material-ui/core/Typography";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWalletTwoTone";
import Back from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import People from "@material-ui/icons/PeopleTwoTone";
import DateTime from "components/DateTime";
import FormPayment from "components/FormPayment";
import InvoiceItem from "components/InvoiceItem";
// reactor
import PageContainer from "components/Page";
import { REACT_APP_API } from "config";
import { CUSTOMER, CUSTOMERS, PAYMENT } from "constants/routes";
import { buildForURL, goBack } from "helpers";
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// styles
import styles from "./styles";

const grid = 32;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  width: "100%",
});

const refundReasons = [
  {
    name: "Duplicate",
    code: "duplicate",
  },
  {
    name: "Fraudulent",
    code: "fraudulent",
  },
  {
    name: "Requested by Customer",
    code: "requested_by_customer",
  },
];

class WrapperInvoicePage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    invoice: PropTypes.object,
    customer: PropTypes.object,
    goods: PropTypes.array,
    loading: PropTypes.bool,
    updateInvoice: PropTypes.func,
    updatePayment: PropTypes.func,
    deleteInvoice: PropTypes.func,
    createPayment: PropTypes.func,
    deleteInvoiceItem: PropTypes.func,
    createInvoiceItem: PropTypes.func,
    updateInvoiceItem: PropTypes.func,
    refundPayment: PropTypes.func,
    refreshKey: PropTypes.number,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.myRef = React.createRef();
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      numPages: null,
      pageNumber: 1,
      init: false,
      formPaymentOpen: false,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshKey !== this.props.refreshKey) {
      this.setState({
        ...nextProps.invoice,
        taxPercent: nextProps.invoice.taxPercent / 100,
        shipping: nextProps.invoice.shipping / 100,
      });
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  async onDragEnd(result) {
    // dropped outside the list
    const { updateInvoiceItem, refresh } = this.props;
    const { items } = this.state;
    if (!result.destination) {
      return;
    }

    const m = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items: m,
    });
    await updateInvoiceItem(items[result.source.index].id, {
      order: result.destination.index + 1,
    });
    refresh();
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async save(name, numeric) {
    const { updateInvoice, refresh, invoice } = this.props;

    if (invoice[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateInvoice(invoice.id, {
      [name]: numeric
        ? Math.round(Number(this.state[name]) * 100)
        : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async savePayment(name, numeric) {
    const { updatePayment, refresh, invoice } = this.props;

    this.setState({
      loading: true,
    });

    await updatePayment(invoice.lastPaymentID, {
      [name]: numeric
        ? Math.round(Number(this.state.lastPayment[name]) * 100)
        : this.state.lastPayment[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  deleteInvoice() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteInvoice, invoice, history } = this.props;
    await deleteInvoice(invoice.id);
    history.push(CUSTOMERS);
  }

  async refund() {
    const { refundPayment, refresh } = this.props;
    const { refundReason, lastPaymentID } = this.state;
    await refundPayment(lastPaymentID.id, {
      refundReason,
    });
    refresh();
  }

  render() {
    const {
      classes,
      loading,
      invoice,
      customer,
      history,
      deleteInvoiceItem,
      updateInvoiceItem,
      createInvoiceItem,
      createPayment,
      refresh,
      goods,
    } = this.props;

    const {
      creationDate,
      taxPercent,
      shipping,
      terms,
      items,
      formPaymentOpen,
      paid,
      status,
      lastPayment,
      refundReason,
    } = this.state;

    let paidWidget = {
      label: "Unpaid",
      color: "#ff9800",
      icon: <MoneyOffIcon style={{ color: "white" }} />,
    };

    if (paid) {
      paidWidget = {
        label: "Paid",
        color: "#4caf50",
        icon: <CheckIcon style={{ color: "white" }} />,
      };
    }

    return (
      <PageContainer
        helmet="Invoice"
        noPadding
        loadingMessage={"Loading Invoice"}
        loading={loading}
        header={
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: 10 }}
          >
            <Breadcrumbs arial-label="Breadcrumb" separator={"-"}>
              <Chip
                label={"Back"}
                className={classes.backChip}
                onClick={() => goBack(history, CUSTOMERS)}
                variant="outlined"
                avatar={
                  <Avatar className={classes.avatar}>
                    <Back className={classes.icon} />
                  </Avatar>
                }
              />
              {customer ? (
                <Chip
                  label={`${customer.fullName}`}
                  className={classes.backChip}
                  onClick={() => history.push(`${CUSTOMER}?id=${customer.id}`)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <People className={classes.icon} />
                    </Avatar>
                  }
                />
              ) : (
                []
              )}
              {invoice && invoice.payment ? (
                <Chip
                  label={`Payment ${invoice.payment.id}`}
                  className={classes.backChip}
                  onClick={() =>
                    history.push(`${PAYMENT}?id=${invoice.payment.id}`)
                  }
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <AccountBalanceWallet className={classes.icon} />
                    </Avatar>
                  }
                />
              ) : (
                []
              )}
              <Chip
                label={`Invoice #${invoice ? invoice.id : ""}`}
                className={classes.backChip}
                variant="contained"
                color="primary"
                avatar={
                  <Avatar className={classes.avatar}>
                    <InsertDriveFile className={classes.icon} />
                  </Avatar>
                }
              />
            </Breadcrumbs>
          </Grid>
        }
      >
        {invoice ? (
          <div>
            <Grid
              container
              style={{
                flexGrow: 1,
                maxWidth: 1200,
                margin: "auto",
                padding: 20,
              }}
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Paper className="card" style={{ color: "#3f51b5" }}>
                      <Paper
                        className="cardHeader"
                        style={{ background: "#3f51b5" }}
                      >
                        <AccountBalanceWallet style={{ color: "white" }} />
                      </Paper>
                      <Grid container justify="flex-end">
                        <Grid item>
                          <Typography
                            display="block"
                            variant="h6"
                            color="inherit"
                          >
                            Total
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Typography display="block" variant="h3">
                            USD {formattedPrice(invoice && invoice.total)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className="card" style={{ color: paidWidget.color }}>
                      <Paper
                        className="cardHeader"
                        style={{ background: paidWidget.color }}
                      >
                        {paidWidget.icon}
                      </Paper>
                      <Grid container justify="flex-end">
                        <Grid item>
                          <Typography
                            display="block"
                            variant="h6"
                            color="inherit"
                          >
                            Status
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Typography
                            display="block"
                            variant="h3"
                            color="inherit"
                          >
                            {paidWidget.label}
                          </Typography>
                          {paidWidget.subtitle ? (
                            <Chip label={paidWidget.subtitle} />
                          ) : (
                            []
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={12}>
                    <ExpansionPanel defaultExpanded>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <Typography display="block" variant="h6">
                            Customer
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <Grid container spacing={1} justify="flex-end">
                          <Grid item xs={6}>
                            <Typography display="block" variant="body2">
                              {customer && customer.companyName}
                            </Typography>
                            <Typography display="block" variant="body2">
                              {customer && customer.fullName}
                            </Typography>
                            <Typography display="block" variant="body2">
                              {customer && customer.email}
                            </Typography>
                            <Typography display="block" variant="body2">
                              {customer && customer.phone}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography display="block" variant="body2">
                              {customer && customer.address1}
                            </Typography>
                            <Typography display="block" variant="body2">
                              {customer && customer.address2}
                            </Typography>
                            <Typography display="block" variant="body2">
                              {customer && customer.zip}{" "}
                              {customer && customer.city}{" "}
                              {customer && customer.state}
                            </Typography>
                            <Typography display="block" variant="body2">
                              {customer && customer.country}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                history.push({
                                  pathname: CUSTOMER,
                                  search: buildForURL({
                                    id: customer.id,
                                    back: `${window.location.pathname}${window.location.search}`,
                                  }),
                                })
                              }
                            >
                              Go to Customer
                            </Button>
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                      <Divider />
                    </ExpansionPanel>
                  </Grid>
                  <Grid item xs={12}>
                    <ExpansionPanel defaultExpanded>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <Typography display="block" variant="h6">
                            Invoice Items
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <div style={{ width: "100%" }}>
                          <Grid container spacing={2}>
                            <DragDropContext
                              onDragEnd={this.onDragEnd.bind(this)}
                            >
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(
                                      snapshot.isDraggingOver
                                    )}
                                  >
                                    {items.map((i, index) => (
                                      <Draggable
                                        key={i.id}
                                        draggableId={i.id}
                                        index={index}
                                      >
                                        {(p, s) => (
                                          <div
                                            ref={p.innerRef}
                                            {...p.draggableProps}
                                            {...p.dragHandleProps}
                                            style={getItemStyle(
                                              s.isDragging,
                                              p.draggableProps.style
                                            )}
                                          >
                                            <InvoiceItem
                                              invoiceItem={i}
                                              updateInvoiceItem={
                                                updateInvoiceItem
                                              }
                                              deleteInvoiceItem={
                                                deleteInvoiceItem
                                              }
                                              refresh={refresh}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                            <Grid item>
                              <Chip
                                label="New Item"
                                onClick={async () => {
                                  await createInvoiceItem({
                                    invoiceID: invoice.id,
                                    name: "New Item",
                                  });
                                  refresh();
                                }}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                    </ExpansionPanel>
                  </Grid>
                  <Grid item xs={12}>
                    <ExpansionPanel defaultExpanded>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <Typography display="block" variant="h6">
                            Invoice Price Summary
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <div style={{ width: "100%" }}>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Subtotal:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <InputBase
                                disabled
                                fullWidth
                                className={classes.inputBase}
                                value={`$${formattedPrice(invoice.subTotal)}`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Taxes (%):
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <InputBase
                                onKeyPress={(e) => {
                                  if (e.key === "Enter")
                                    this.save("taxPercent", true);
                                }}
                                disabled={loading}
                                fullWidth
                                onBlur={() => this.save("taxPercent", true)}
                                className={classes.inputBase}
                                value={taxPercent}
                                onChange={this.handleChange("taxPercent")}
                              />
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Shipping:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <InputBase
                                onKeyPress={(e) => {
                                  if (e.key === "Enter")
                                    this.save("shipping", true);
                                }}
                                disabled={loading}
                                fullWidth
                                onBlur={() => this.save("shipping", true)}
                                className={classes.inputBase}
                                value={shipping}
                                onChange={this.handleChange("shipping")}
                              />
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Total:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <InputBase
                                disabled
                                fullWidth
                                className={classes.inputBase}
                                value={`$${formattedPrice(invoice.total)}`}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                    </ExpansionPanel>
                  </Grid>
                  <Grid item xs={12}>
                    <ExpansionPanel defaultExpanded>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <Typography display="block" variant="h6">
                            Invoice Terms
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <div style={{ width: "100%" }}>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                              <InputBase
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") this.save("terms");
                                }}
                                disabled={loading}
                                fullWidth
                                multiline
                                onBlur={() => this.save("terms")}
                                className={classes.inputBase}
                                value={terms}
                                onChange={this.handleChange("terms")}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                    </ExpansionPanel>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="column" spacing={1}>
                  <Grid item xs={12}>
                    <ExpansionPanel defaultExpanded>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <Typography display="block" variant="h6">
                            Invoice Info
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <div style={{ width: "100%" }}>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Invoice Number:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Chip
                                label={`#${invoice && invoice.invoiceNumber}`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Status:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <FormControl
                                className={classes.formControl}
                                fullWidth
                                size="small"
                                variant="outlined"
                              >
                                <Select
                                  inputProps={{
                                    name: "Project",
                                    id: "project-simple",
                                  }}
                                  value={status}
                                  onChange={(e) => {
                                    this.setState(
                                      { status: e.target.value },
                                      () => this.save("status")
                                    );
                                  }}
                                >
                                  <MenuItem value={"Draft"}>Draft</MenuItem>
                                  <MenuItem value={"Sent"}>Sent</MenuItem>
                                  <MenuItem value={"Completed"}>
                                    Completed
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Invoice Date:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <DateTime
                                noTime
                                onKeyPress={(e) => {
                                  if (e.key === "Enter")
                                    this.save("creationDate");
                                }}
                                onBlur={() => this.save("creationDate")}
                                className={classes.inputBase}
                                date={creationDate}
                                onChange={(e) => {
                                  this.handleChange("creationDate")(e);
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            justify="flex-end"
                            style={{ marginTop: 8 }}
                          >
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  window.open(
                                    `${REACT_APP_API}/bazaar/invoices/${invoice.id}/preview`
                                  )
                                }
                              >
                                Download PDF
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                    </ExpansionPanel>
                  </Grid>
                  <Grid item xs={12}>
                    <ExpansionPanel defaultExpanded>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <Typography display="block" variant="h6">
                            Payment
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.details}>
                        <div style={{ width: "100%" }}>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Payment ID:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <InputBase
                                disabled
                                fullWidth
                                className={classes.inputBase}
                                value={`${invoice.lastPaymentID}`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Transaction ID:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <InputBase
                                disabled
                                fullWidth
                                className={classes.inputBase}
                                value={`${
                                  invoice.lastPaymentID
                                    ? invoice.lastPayment.transactionID
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Payment Date:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              {invoice.lastPayment ? (
                                <InputBase
                                  disabled
                                  className={classes.inputBase}
                                  value={moment(
                                    invoice.lastPayment.paymentDate
                                  ).format("YYYY-MM-DD[T]HH:mm:ss")}
                                  type="datetime-local"
                                />
                              ) : (
                                <Typography display="block" variant="body2">
                                  No Date
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Payment Method:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <InputBase
                                disabled
                                fullWidth
                                className={classes.inputBase}
                                value={`${
                                  invoice.lastPaymentID
                                    ? invoice.lastPayment.paymentMethod
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={4}>
                              <Typography display="block" variant="body2">
                                Mark as Paid
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Checkbox
                                defaultChecked={invoice.paid}
                                onChange={(e) => {
                                  this.setState(
                                    { paid: e.target.checked },
                                    () => this.save("paid")
                                  );
                                }}
                                color="primary"
                              />
                            </Grid>
                          </Grid>
                          <Chip
                            label="Add Payment"
                            variant="outlined"
                            onClick={() =>
                              this.setState({ formPaymentOpen: true })
                            }
                          />
                        </div>
                      </ExpansionPanelDetails>
                      <Divider />
                    </ExpansionPanel>
                  </Grid>
                  {invoice && invoice.lastPaymentID ? (
                    <Grid item xs={12}>
                      <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <div>
                            <Typography display="block" variant="h6">
                              Payment Notes
                            </Typography>
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.details}>
                          <div style={{ width: "100%" }}>
                            <Grid container alignItems="center" spacing={2}>
                              <Grid item xs={12}>
                                <Typography display="block" variant="body2">
                                  Notes (Visible by admins only)
                                </Typography>
                                <InputBase
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter")
                                      this.savePayment("notes");
                                  }}
                                  disabled={loading}
                                  fullWidth
                                  multiline
                                  onBlur={() => this.savePayment("notes")}
                                  className={classes.inputBase}
                                  value={lastPayment.notes}
                                  onChange={(e) => {
                                    const p = invoice.lastPayment;
                                    p.notes = e.target.value;
                                    this.setState({ lastPayment: p });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography display="block" variant="body2">
                                  Message (Visible by customers)
                                </Typography>
                                <InputBase
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter")
                                      this.savePayment("message");
                                  }}
                                  disabled={loading}
                                  fullWidth
                                  multiline
                                  onBlur={() => this.savePayment("message")}
                                  className={classes.inputBase}
                                  value={lastPayment.message}
                                  onChange={(e) => {
                                    const p = invoice.lastPayment;
                                    p.message = e.target.value;
                                    this.setState({ lastPayment: p });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </ExpansionPanelDetails>
                        <Divider />
                      </ExpansionPanel>
                    </Grid>
                  ) : (
                    []
                  )}
                  {invoice && invoice.lastPaymentID ? (
                    <Grid item xs={12}>
                      <ExpansionPanel defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <div>
                            <Typography display="block" variant="h6">
                              Payment Refund
                            </Typography>
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.details}>
                          {lastPayment && lastPayment.refunded ? (
                            <div>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                  <Typography display="block" variant="body2">
                                    RefundID
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Chip label={lastPayment.refundID} />
                                </Grid>
                              </Grid>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                  <Typography display="block" variant="body2">
                                    Reason
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Chip label={lastPayment.refundReason} />
                                </Grid>
                              </Grid>
                            </div>
                          ) : (
                            <div>
                              <Typography display="block" color="secondaryText">
                                Select a reason
                              </Typography>
                              <Grid container spacing={1}>
                                {refundReasons.map((rr) => (
                                  <Grid key={rr.code} item>
                                    <Chip
                                      label={rr.name}
                                      color={
                                        refundReason === rr.code
                                          ? "primary"
                                          : undefined
                                      }
                                      onClick={() =>
                                        this.setState({ refundReason: rr.code })
                                      }
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                              {refundReason ? (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={this.refund.bind(this)}
                                >
                                  Refund
                                </Button>
                              ) : (
                                []
                              )}
                            </div>
                          )}
                        </ExpansionPanelDetails>
                        <Divider />
                      </ExpansionPanel>
                    </Grid>
                  ) : (
                    []
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          []
        )}
        <FormPayment
          open={formPaymentOpen}
          invoice={invoice}
          customer={customer}
          close={() => {
            this.setState({
              formPaymentOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedPayment: undefined,
                }),
              200
            );
          }}
          goods={goods}
          createPayment={createPayment}
          refresh={refresh}
        />
      </PageContainer>
    );
  }
}
export default withStyles(styles)(WrapperInvoicePage);
