import { GOOD } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import GoodPage from "./container/GoodPage";

export default function getSettingsRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: GOOD,
    withSidebar: true,
    withAppBar: true,
    component: GoodPage,
  };
}
