import { checkAccess } from "helpers/auth";
import { DEFAULT } from "constants/routes";
import DefaultPage from "./container/DefaultPage";

export default function getDefaultRoute(userAuth) {
  const requireAuth = {};
  return {
    path: DEFAULT,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withAppBar: true,
    component: DefaultPage,
  };
}
