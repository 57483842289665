import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperPricingPage from "../component/WrapperPricingPage";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getPricingById: (...args) => dispatch(Actions.getPricingById(...args)),
  updatePricing: (...args) => dispatch(Actions.updatePricing(...args)),
  deletePricing: (...args) => dispatch(Actions.deletePricing(...args)),
  deletePricingCurrency: (...args) =>
    dispatch(Actions.deletePricingCurrency(...args)),
  updatePricingCurrency: (...args) =>
    dispatch(Actions.updatePricingCurrency(...args)),
  createPricingCurrency: (...args) =>
    dispatch(Actions.createPricingCurrency(...args)),
});
class PricingPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getPricingById: PropTypes.func,
    updatePricing: PropTypes.func,
    deletePricing: PropTypes.func,
    deletePricingCurrency: PropTypes.func,
    updatePricingCurrency: PropTypes.func,
    createPricingCurrency: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      pricingID: urlParams.id,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { pricingID } = this.state;
    const { getPricingById } = this.props;

    const resp = await getPricingById(pricingID);
    this.setState({ pricing: resp.payload, loading: false });
  }

  render() {
    const {
      location,
      history,
      updatePricing,
      deletePricing,
      deletePricingCurrency,
      createPricingCurrency,
      updatePricingCurrency,
    } = this.props;

    const { urlParams, pricing, loading } = this.state;

    return (
      <WrapperPricingPage
        history={history}
        location={location}
        urlParams={urlParams}
        pricing={pricing}
        loading={loading}
        updatePricing={updatePricing}
        deletePricing={deletePricing}
        deletePricingCurrency={deletePricingCurrency}
        createPricingCurrency={createPricingCurrency}
        updatePricingCurrency={updatePricingCurrency}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PricingPage);
