import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const categories = `/${service}/categories`;

export function getCategories() {
  const url = `${categories}`;
  return {
    method: GET,
    url,
  };
}

export function getCategoryById(id) {
  const url = `${categories}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCategory(id, body) {
  const url = `${categories}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCategory(id) {
  const url = `${categories}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCategory(body) {
  const url = `${categories}`;
  return {
    method: POST,
    url,
    body,
  };
}
