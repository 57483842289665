import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Link from "@material-ui/core/Link";
import Chip from "@material-ui/core/Chip";

// helpers
import { formattedPrice } from "helpers/stripe";

import { PLACEHOLDER } from "config";

// styles
import styles from "./styles";

class CartGood extends Component {
  static propTypes = {
    classes: PropTypes.object,
    cartGood: PropTypes.object,
    updateCart: PropTypes.func,
    refresh: PropTypes.func,
    price: PropTypes.bool,
    divider: PropTypes.bool,
    index: PropTypes.number,
    cart: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { cartGood } = this.props;
    this.state = {
      quantity: cartGood.quantity,
    };
  }

  getQuantity() {
    const JSX = [];
    for (let index = 1; index <= 10; index++) {
      JSX.push(
        <MenuItem key={index} value={index}>
          {index}
        </MenuItem>
      );
    }

    return JSX;
  }

  async changeQty(e) {
    const { updateCart, refresh, cart, index } = this.props;

    const { goods } = cart;
    goods[index].quantity = Number(e.target.value);

    await updateCart(cart.id, cart);
    refresh();
  }

  async deleteCartGood() {
    const { updateCart, cart, index, refresh } = this.props;
    const { goods } = cart;
    goods.splice(index, 1);

    await updateCart(cart.id, cart);
    refresh();
  }

  render() {
    const { classes, cartGood, price, divider, updateCart } = this.props;

    const { quantity } = this.state;

    const pricing = cartGood.pricing;
    console.log(cartGood);

    return (
      <div>
        <Grid
          container
          className={classes.container}
          alignItems="center"
          justify="space-between"
          spacing={2}
        >
          <Grid item>
            <Grid container>
              <Grid item style={{ width: 60 }}>
                <div className={classes.image}>
                  <img
                    style={{ width: 45, height: 45 }}
                    alt="assessment"
                    src={
                      cartGood.pictures && cartGood.pictures.length
                        ? cartGood.pictures[0].url
                        : PLACEHOLDER
                    }
                  />
                </div>
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Typography
                  display="block"
                  variant="h6"
                  style={{ fontSize: 18, lineHeight: 1 }}
                >
                  {cartGood.title}
                  <br />
                </Typography>
                {updateCart ? (
                  <React.Fragment>
                    <Link
                      variant="caption"
                      color="textSecondary"
                      style={{ cursor: "pointer", color: "#f44336" }}
                      onClick={this.deleteCartGood.bind(this)}
                    >
                      Delete from Cart
                    </Link>
                    -
                  </React.Fragment>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {price ? (
                <Grid item style={{ flexGrow: 1 }}>
                  <Chip label={pricing.title} />
                  <Typography display="block" variant="subtitle1">
                    {formattedPrice(pricing.currencies[0].price)}
                  </Typography>
                </Grid>
              ) : (
                []
              )}
              <Grid item>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="qty">Quantity</InputLabel>
                  <Select
                    inputProps={{
                      name: "qty",
                      id: "qty",
                    }}
                    disabled={updateCart === undefined}
                    value={quantity}
                    onChange={this.changeQty.bind(this)}
                  >
                    {this.getQuantity()}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {divider ? <Divider /> : []}
      </div>
    );
  }
}

export default withStyles(styles)(CartGood);
