import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// custom
import Table from "components/TablePagination";

// styles
import styles from "./styles";

class CategoriesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    categories: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedCategorie) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedCategorie);
    }
  }

  render() {
    const { categories } = this.props;

    return (
      <Table
        data={categories}
        meta={[
          {
            path: "icon",
            numeric: false,
            width: 30,
            render: (t) => (
              <img src={t} alt="category" style={{ height: 25 }} />
            ),
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
        ]}
        title={"Categories"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(CategoriesList);
