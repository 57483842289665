import { STRIPE_IMAGES } from "config";

export function getLogo(card, dardMode) {
  switch (card.brand) {
    case "American Express":
      return `${STRIPE_IMAGES}/american-express${dardMode ? "-white" : ""}.png`;
    case "Visa":
    case "Visa (debit)":
      return `${STRIPE_IMAGES}/visa${dardMode ? "-white" : ""}.png`;
    case "MasterCard":
    case "MasterCard (debit)":
    case "MasterCard (prepaid)":
      return `${STRIPE_IMAGES}/master-card${dardMode ? "-white" : ""}.png`;
    case "Discover":
      return `${STRIPE_IMAGES}/discover${dardMode ? "-white" : ""}.png`;
    case "JCB":
      return `${STRIPE_IMAGES}/jcb${dardMode ? "-white" : ""}.png`;
    case "Diners Club":
      return `${STRIPE_IMAGES}/diners-club${dardMode ? "-white" : ""}.png`;
    default:
      return undefined;
  }
}

export function formattedPrice(val) {
  let x = Number(val);

  if (val === 0) {
    return "0.00";
  }
  let neg = false;
  if (x < 0) {
    neg = true;
  }

  const price = Math.abs(x / 100)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (neg) {
    return `-${price}`;
  }

  return `${price}`;
}
