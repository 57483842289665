import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperEventPage from "../component/WrapperEventPage";

const mapStateToProps = (state) => ({
  pricings: state.pricings,
  ticketTemplates: state.ticketTemplates,
});

const mapDispatchToProps = (dispatch) => ({
  getEventById: (...args) => dispatch(Actions.getEventById(...args)),
  updateEvent: (...args) => dispatch(Actions.updateEvent(...args)),
  deleteEvent: (...args) => dispatch(Actions.deleteEvent(...args)),
  createEventMedia: (...args) => dispatch(Actions.createEventMedia(...args)),

  getTicketTemplates: (...args) =>
    dispatch(Actions.getTicketTemplates(...args)),
  getTicketTemplateById: (...args) =>
    dispatch(Actions.getTicketTemplateById(...args)),
  createTicketTemplate: (...args) =>
    dispatch(Actions.createTicketTemplate(...args)),
  updateTicketTemplate: (...args) =>
    dispatch(Actions.updateTicketTemplate(...args)),
  deleteTicketTemplate: (...args) =>
    dispatch(Actions.deleteTicketTemplate(...args)),

  getPricings: (...args) => dispatch(Actions.getPricings(...args)),
  createEventPricing: (...args) =>
    dispatch(Actions.createEventPricing(...args)),
  updateEventPricing: (...args) =>
    dispatch(Actions.updateEventPricing(...args)),
  deleteEventPricing: (...args) =>
    dispatch(Actions.deleteEventPricing(...args)),

  uploadMedia: (...args) => dispatch(Actions.uploadMedia(...args)),
  deleteMedium: (...args) => dispatch(Actions.deleteMedium(...args)),
  updateMedium: (...args) => dispatch(Actions.updateMedium(...args)),
});
class EventPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    getEventById: PropTypes.func,
    updateEvent: PropTypes.func,
    deleteEvent: PropTypes.func,
    createEventMedia: PropTypes.func,

    ticketTemplates: PropTypes.array,
    getTicketTemplates: PropTypes.func,
    getTicketTemplateById: PropTypes.func,
    createTicketTemplate: PropTypes.func,
    updateTicketTemplate: PropTypes.func,
    deleteTicketTemplate: PropTypes.func,

    pricings: PropTypes.array,
    getPricings: PropTypes.func,
    createEventPricing: PropTypes.func,
    updateEventPricing: PropTypes.func,
    deleteEventPricing: PropTypes.func,

    uploadMedia: PropTypes.func,
    updateMedium: PropTypes.func,
    deleteMedium: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, getPricings, getTicketTemplates } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      eventID: urlParams.id,
      templateID: urlParams.templateId,
      loading: true,
    };

    getPricings();
    getTicketTemplates();
  }

  componentDidMount() {
    this.refresh();
  }

  componentWillReceiveProps(nextProps) {
    const nextParams = getJsonFromUrl(nextProps.location);
    const params = getJsonFromUrl(this.props.location);

    if (nextParams.templateId === undefined) {
      this.setState({
        ticketTemplate: undefined,
      });
    }

    if (nextParams.templateId !== params.templateId) {
      this.setState(
        {
          templateID: nextParams.templateId,
        },
        this.refresh.bind(this)
      );
    }
  }

  async refresh() {
    const { eventID, templateID } = this.state;
    const { getEventById, getTicketTemplateById } = this.props;
    const proms = [];
    proms.push(getEventById(eventID));

    if (templateID) {
      proms.push(getTicketTemplateById(templateID));
    }

    const resps = await Promise.all(proms);
    this.setState({
      event: resps[0].payload,
      ticketTemplate: resps.length === 2 ? resps[1].payload : undefined,
      loading: false,
      refreshKey: new Date().getTime(),
    });
  }

  render() {
    const {
      location,
      history,
      pricings,

      getEventById,
      updateEvent,
      deleteEvent,
      createEventMedia,

      ticketTemplates,
      getTicketTemplates,
      getTicketTemplateById,
      createTicketTemplate,
      updateTicketTemplate,
      deleteTicketTemplate,

      createEventPricing,
      updateEventPricing,
      deleteEventPricing,

      uploadMedia,
      updateMedium,
      deleteMedium,
    } = this.props;

    const api = {
      getEventById,
      updateEvent,
      deleteEvent,
      createEventMedia,

      getTicketTemplates,
      getTicketTemplateById,
      createTicketTemplate,
      updateTicketTemplate,
      deleteTicketTemplate,

      createEventPricing,
      updateEventPricing,
      deleteEventPricing,

      uploadMedia,
      updateMedium,
      deleteMedium,
    };

    const {
      urlParams,
      loading,
      refreshKey,
      event,
      ticketTemplate,
    } = this.state;

    return (
      <WrapperEventPage
        history={history}
        location={location}
        pricings={pricings}
        ticketTemplates={ticketTemplates}
        ticketTemplate={ticketTemplate}
        api={api}
        urlParams={urlParams}
        refreshKey={refreshKey}
        loading={loading}
        event={event}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
