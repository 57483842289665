import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
// import * as Actions from 'actions';

// component
import WrapperCreditcardPage from "../component/WrapperCreditcardPage";

const mapStateToProps = (state) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  // signin: (...args) => dispatch(Actions.signin(...args)),
});
class CreditcardPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
    };
  }

  render() {
    const { location, history, app } = this.props;

    const { urlParams } = this.state;

    return (
      <WrapperCreditcardPage
        history={history}
        app={app}
        location={location}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CreditcardPage);
