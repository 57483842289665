import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
// custom
import AutoComplete from "components/AutoComplete";
// reactor
import Good from "components/Good";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class GoodBundles extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    good: PropTypes.object,
    createBundle: PropTypes.func,
    deleteCouponGood: PropTypes.func,
    goods: PropTypes.array,
  };

  getQuantity() {
    const JSX = [];
    for (let index = 1; index <= 10; index++) {
      JSX.push(
        <MenuItem key={index} value={index}>
          {index}
        </MenuItem>
      );
    }

    return JSX;
  }

  async addCouponGood(selectedGood) {
    const { createBundle, good, refresh } = this.props;

    await createBundle({
      bundleID: good.id,
      goodID: selectedGood.value.id,
      quantity: 1,
    });
    refresh();
  }

  async deleteBundle(bundle) {
    const { deleteBundle, refresh } = this.props;
    await deleteBundle(bundle.id);
    refresh();
  }

  async changeQty(bundle, quantity) {
    const { updateBundle, refresh } = this.props;
    await updateBundle(bundle.id, { quantity });
    refresh();
  }

  render() {
    const { good, goods } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutoComplete
            placeHolder={`Add Good coming with ${good.title}`}
            onSelect={this.addCouponGood.bind(this)}
            suggestions={
              goods &&
              goods.map((g) => ({
                value: g,
                label: `${g.title}`,
              }))
            }
          />
        </Grid>
        {good &&
          good.bundles &&
          good.bundles.map((b) => (
            <Grid item key={b.id} xs={12} md={3}>
              <Good
                image
                info
                price
                paper
                good={b.good}
                footer={
                  <div style={{ width: "100%" }}>
                    <br />
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      style={{ width: "100%" }}
                    >
                      <Grid item>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => this.deleteBundle(b)}
                        >
                          Delete
                        </Button>
                      </Grid>
                      <Grid item>
                        <FormControl>
                          <InputLabel htmlFor="qty">Qty</InputLabel>
                          <Select
                            inputProps={{
                              name: "qty",
                              id: "qty",
                            }}
                            value={b.quantity}
                            onChange={(e) => this.changeQty(b, e.target.value)}
                          >
                            {this.getQuantity()}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                }
              />
            </Grid>
          ))}
      </Grid>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(GoodBundles));
