import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import Toys from "@material-ui/icons/ToysTwoTone";
import GoodBundles from "components/GoodBundles";
import GoodInfo from "components/GoodInfo";
import GoodSales from "components/GoodSales";
// custom
import Page from "components/Page";
import { GOODS } from "constants/routes";
import { goBack, updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperGoodPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    sales: PropTypes.object,
    refresh: PropTypes.func,
    good: PropTypes.object,
    updateGood: PropTypes.func,
    uploadGoodMedium: PropTypes.func,
    deleteMedium: PropTypes.func,
    updateMedium: PropTypes.func,
    createPricingCurrency: PropTypes.func,
    updatePricingCurrency: PropTypes.func,
    deletePricingCurrency: PropTypes.func,
    createGoodCategory: PropTypes.func,
    deleteGoodCategory: PropTypes.func,
    createBundle: PropTypes.func,
    deleteBundle: PropTypes.func,
    updateBundle: PropTypes.func,
    deleteGood: PropTypes.func,
    createPricing: PropTypes.func,
    loading: PropTypes.bool,
    pricings: PropTypes.array,
    goods: PropTypes.array,
    categories: PropTypes.array,
    activities: PropTypes.array,
    projects: PropTypes.array,
    refreshKey: PropTypes.number,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const {
      good,
      updateGood,
      refreshKey,
      refresh,
      createPricingCurrency,
      updatePricingCurrency,
      deletePricingCurrency,
      createGoodCategory,
      deleteGoodCategory,
      createBundle,
      deleteBundle,
      updateBundle,
      uploadGoodMedium,
      updateMedium,
      deleteMedium,
      deleteGood,
      createPricing,
      pricings,
      sales,
      goods,
      categories,
      history,
      projects,
      activities,
    } = this.props;

    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <GoodInfo
            key={refreshKey}
            activities={activities}
            projects={projects}
            updateGood={updateGood}
            refresh={refresh}
            good={good}
            pricings={pricings}
            categories={categories}
            deleteMedium={deleteMedium}
            updateMedium={updateMedium}
            deleteGood={deleteGood}
            uploadGoodMedium={uploadGoodMedium}
            createGoodCategory={createGoodCategory}
            deleteGoodCategory={deleteGoodCategory}
            createPricingCurrency={createPricingCurrency}
            updatePricingCurrency={updatePricingCurrency}
            deletePricingCurrency={deletePricingCurrency}
            createPricing={createPricing}
            history={history}
          />
        );
      case 1:
        return (
          <GoodBundles
            good={good}
            goods={goods}
            createBundle={createBundle}
            deleteBundle={deleteBundle}
            updateBundle={updateBundle}
            refresh={refresh}
          />
        );
      case 2:
        return <GoodSales sales={sales} />;
      default:
        return (
          <Typography display="block" variant="title">
            404 Not Found
          </Typography>
        );
    }
  }

  render() {
    const { classes, history, loading, good } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="Good"
        loading={loading}
        header={
          <Grid container style={{ padding: "10px 0px 0px 10px" }}>
            <Grid item>
              <Breadcrumbs arial-label="Breadcrumb" separator={"-"}>
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => goBack(history, GOODS)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                <Chip
                  label={`${good ? good.title : "..."}`}
                  className={classes.backChip}
                  variant="contained"
                  color="primary"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Toys className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
            </Grid>
          </Grid>
        }
        loadingMessage={"Loading Good"}
        tabs={
          <React.Fragment>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push({
                  search: updateURLParameter("index", v),
                });
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
            >
              <Tab
                label="Good"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="Also Come With"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="Sales"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </React.Fragment>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperGoodPage);
