import { networkAction } from "helpers/network/networkAction";

import {
  GET_INVOICE_ITEMS,
  GET_INVOICE_ITEM_BY_ID,
  DELETE_INVOICE_ITEM,
  UPDATE_INVOICE_ITEM,
  CREATE_INVOICE_ITEM,
} from "constants/invoiceItems";

import * as Api from "api";

export const getInvoiceItems = () => async (dispatch) =>
  networkAction(dispatch, GET_INVOICE_ITEMS, Api.getInvoiceItems, []);

export const getInvoiceItemById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_INVOICE_ITEM_BY_ID, Api.getInvoiceItemById, [id]);

export const deleteInvoiceItem = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_INVOICE_ITEM, Api.deleteInvoiceItem, [id]);

export const createInvoiceItem = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_INVOICE_ITEM, Api.createInvoiceItem, [body]);

export const updateInvoiceItem = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_INVOICE_ITEM, Api.updateInvoiceItem, [
    id,
    body,
  ]);
