import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardElement, injectStripe } from "react-stripe-elements";

// mui
import Portal from "@material-ui/core/Portal";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";

// styles
import styles from "./styles";

class CheckoutForm extends Component {
  static propTypes = {
    classes: PropTypes.object,
    submitContainer: PropTypes.object,
    stripe: PropTypes.object,
    onComplete: PropTypes.func,
  };

  state = {
    name: "",
    address_line1: "",
    address_line2: "",
    address_city: "",
    address_state: "",
    address_zip: "",
    address_country: "US",
    currency: "usd",
    card: "",
    complete: false,
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCardElementChange = (change) => {
    this.setState({ complete: change.complete });
  };

  async submit() {
    const { onComplete } = this.props;
    const {
      name,
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_country,
      currency,
    } = this.state;

    const resp = await this.props.stripe.createToken({
      name,
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_country,
      currency,
    });

    onComplete(resp);
  }

  render() {
    const { submitContainer, classes } = this.props;
    const {
      name,
      address_line1,
      address_line2,
      address_city,
      address_state,
      address_country,
      complete,
    } = this.state;

    return (
      <div>
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Cardholder name *"
              className={classes.textField}
              value={name}
              onChange={this.handleChange("name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="line1"
              label="Address Line 1"
              className={classes.textField}
              value={address_line1}
              onChange={this.handleChange("address_line1")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="line2"
              label="Address Line 2"
              className={classes.textField}
              value={address_line2}
              onChange={this.handleChange("address_line2")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="city"
              label="City"
              className={classes.textField}
              value={address_city}
              onChange={this.handleChange("address_city")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="state"
              label="State"
              className={classes.textField}
              value={address_state}
              onChange={this.handleChange("address_state")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="country"
              label="Country"
              className={classes.textField}
              value={address_country}
              onChange={this.handleChange("address_country")}
            />
          </Grid>
        </Grid>
        <CardElement onChange={this.handleCardElementChange.bind(this)} />
        <Portal container={submitContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.submit}
            style={{
              color: "white",
            }}
            disabled={!complete || name.length === 0}
          >
            Submit
          </Button>
        </Portal>
      </div>
    );
  }
}

export default injectStripe(withStyles(styles)(CheckoutForm));
