import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const statements = `/${service}/statements`;

export function getStatements() {
  const url = `${statements}`;
  return {
    method: GET,
    url,
  };
}

export function createMonthlyStatements() {
  const url = `${statements}/monthly`;
  return {
    method: GET,
    url,
  };
}

export function getStatementById(id) {
  const url = `${statements}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateStatement(id, body) {
  const url = `${statements}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteStatement(id) {
  const url = `${statements}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createStatement(body) {
  const url = `${statements}`;
  return {
    method: POST,
    url,
    body,
  };
}
