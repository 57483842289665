import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import MoneyAttach from "@material-ui/icons/AttachMoneyTwoTone";
import AutoRenew from "@material-ui/icons/AutorenewTwoTone";
import CreditCard from "@material-ui/icons/CreditCardTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import Message from "@material-ui/icons/MessageTwoTone";
import Notes from "@material-ui/icons/NotesTwoTone";
import People from "@material-ui/icons/PeopleTwoTone";
import ShoppingCart from "@material-ui/icons/ShoppingCartTwoTone";
import DateTime from "components/DateTime";
// reactor
import Page from "components/Page";
// constants
import { CART, CUSTOMER, CUSTOMERS, INVOICE } from "constants/routes";
import { goBack } from "helpers";
// helper
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const refundReasons = [
  {
    name: "Duplicate",
    code: "duplicate",
  },
  {
    name: "Fraudulent",
    code: "fraudulent",
  },
  {
    name: "Requested by Customer",
    code: "requested_by_customer",
  },
];

class WrapperPaymentPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    cart: PropTypes.object,
    payment: PropTypes.object,
    updatePayment: PropTypes.func,
    refundPayment: PropTypes.func,
    loading: PropTypes.bool,
    projects: PropTypes.array,
    callback: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams, payment } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      refundCollapsed: false,
      ...payment,
      total: payment.total / 100,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && this.props.loading) {
      this.setState({
        ...nextProps.payment,
        total: nextProps.payment.total / 100,
      });
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { updatePayment, refresh, payment } = this.props;

    if (payment[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    if (name === "total") {
      await updatePayment(payment.id, {
        total: Number(this.state.total) * 100,
      });
    } else {
      await updatePayment(payment.id, {
        [name]: numeric ? Number(this.state[name]) : this.state[name],
      });
    }

    this.setState({
      loading: false,
    });

    refresh();
  }

  async refund() {
    const { refundPayment, payment, refresh } = this.props;
    const { refundReason } = this.state;
    await refundPayment(payment.id, {
      refundReason,
    });
    refresh();
  }

  async processCallbacks() {
    const { projects, payment, callback } = this.props;
    const services = {};

    for (const key in projects) {
      if (projects.hasOwnProperty(key)) {
        const project = projects[key];
        const service = project.resourceURL.split("/")[1];
        services[`/${service}`] = 1;
      }
    }

    const proms = [];
    for (const key in services) {
      if (services.hasOwnProperty(key)) {
        proms.push(callback(key, { paymentID: payment.id }));
      }
    }

    const resp = await Promise.all(proms);
  }

  render() {
    const { classes, history, loading, payment } = this.props;

    const {
      notes,
      message,
      refundReason,
      transactionID,
      paymentDate,
      total,
    } = this.state;

    return (
      <Page
        helmet="Payment"
        loadingMessage={"Loading Payment"}
        loading={loading}
        header={
          <div className={classes.header}>
            <Grid container>
              <Grid item xs={12}>
                <Breadcrumbs separator="-" arial-label="Breadcrumb">
                  <Chip
                    onClick={() => goBack(history, CUSTOMERS)}
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Back />
                      </Avatar>
                    }
                    variant="outlined"
                    className={classes.chip}
                    label="Back"
                  />
                  {payment && payment.customerID ? (
                    <Chip
                      label={`Customer #${payment.customerID}`}
                      className={classes.chip}
                      onClick={() =>
                        history.push(`${CUSTOMER}?id=${payment.customerID}`)
                      }
                      variant="outlined"
                      avatar={
                        <Avatar className={classes.avatar}>
                          <People className={classes.icon} />
                        </Avatar>
                      }
                    />
                  ) : (
                    []
                  )}
                  <Chip
                    label={`Payment #${payment.id}`}
                    className={classes.chip}
                    variant="contained"
                    color="primary"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <InsertDriveFile className={classes.icon} />
                      </Avatar>
                    }
                  />
                </Breadcrumbs>
              </Grid>
            </Grid>
          </div>
        }
      >
        <div
          style={{
            maxWidth: 1000,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          <Grid
            container
            style={{ marginLeft: -8 }}
            spacing={2}
            className={classes.container}
            alignItems="stretch"
          >
            <Grid item xs={12} md={4}>
              <Paper
                className="card"
                style={{ color: "#e91e63", height: "calc(100% - 90px)" }}
              >
                <Paper className="cardHeader" style={{ background: "#e91e63" }}>
                  <CreditCard style={{ color: "white" }} />
                </Paper>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Typography display="block" variant="h6" color="inherit">
                      Status
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography display="block" variant="h3">
                      {payment.paid ? "Paid" : "Rejected"}
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      {moment(payment.paymentDate).format("lll")} (
                      {moment(payment.paymentDate).fromNow()})
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper
                className="card"
                style={{ color: "#9c27b0", height: "calc(100% - 90px)" }}
              >
                <Paper className="cardHeader" style={{ background: "#9c27b0" }}>
                  <MoneyAttach style={{ color: "white" }} />
                </Paper>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Typography display="block" variant="h6" color="inherit">
                      Total (USD)
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography display="block" variant="h3">
                      USD {payment.total ? formattedPrice(payment.total) : "-"}
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      {payment.paymentMethod}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {payment.refunded ? (
              <Grid item xs={12} md={4}>
                <Paper
                  className="card"
                  style={{ color: "#2196f3", height: "calc(100% - 90px)" }}
                >
                  <Paper
                    className="cardHeader"
                    style={{ background: "#2196f3" }}
                  >
                    <AutoRenew style={{ color: "white" }} />
                  </Paper>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Typography display="block" variant="h6" color="inherit">
                        Refund
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item>
                      <br />
                      <Chip label={payment.refundReason} />
                      <Typography display="block" color="textSecondary">
                        Refund #{payment.refundID}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ) : (
              []
            )}
            <Grid item xs={12}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography display="block" variant="h6">
                      Payment Reference
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      TransactionID and Transaction Date.
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  <div style={{ width: "100%" }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <Typography display="block" variant="body2">
                          <b>TransactionID</b>
                        </Typography>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("transactionID");
                          }}
                          multiline
                          fullWidth
                          rowsMin={2}
                          rows={2}
                          onBlur={() => this.save("transactionID")}
                          className={classes.inputBase}
                          defaultValue={transactionID}
                          onChange={this.handleChange("transactionID")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12}>
                        <Typography display="block" variant="body2">
                          <b>Total</b>
                        </Typography>
                        <InputBase
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("total");
                          }}
                          multiline
                          fullWidth
                          rowsMin={2}
                          rows={2}
                          onBlur={() => this.save("total", true)}
                          className={classes.inputBase}
                          defaultValue={total}
                          onChange={this.handleChange("total")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography display="block" variant="body2">
                          <b>Date</b>
                        </Typography>
                        <DateTime
                          onKeyPress={(e) => {
                            if (e.key === "Enter") this.save("paymentDate");
                          }}
                          disabled={loading}
                          onBlur={() => this.save("paymentDate")}
                          noTime
                          className={classes.inputBase}
                          date={paymentDate}
                          onChange={this.handleChange("paymentDate")}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </ExpansionPanelDetails>
                <Divider />
              </ExpansionPanel>
            </Grid>
            {payment.refunded ? (
              <Grid item xs={12}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                      <Typography display="block" variant="h6">
                        Refund Info
                      </Typography>
                      <Typography display="block" color="textSecondary">
                        Reference
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.details}>
                    <div>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                          <Typography display="block" variant="body2">
                            RefundID
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Chip label={payment.refundID} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                          <Typography display="block" variant="body2">
                            Reason
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Chip label={payment.refundReason} />
                        </Grid>
                      </Grid>
                    </div>
                  </ExpansionPanelDetails>
                  <Divider />
                </ExpansionPanel>
              </Grid>
            ) : (
              []
            )}
            <Grid item xs={12}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography display="block" variant="h6">
                      Message, Notes
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      Add explanations the user can read.
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  <div style={{ width: "100%" }}>
                    <div className={classes.paper}>
                      <Typography display="block" variant="h6">
                        <Message /> Message
                      </Typography>
                      <Typography display="block" variant="caption">
                        Visible by the user
                      </Typography>
                      <InputBase
                        onKeyPress={(e) => {
                          if (e.key === "Enter") this.save("message");
                        }}
                        multiline
                        fullWidth
                        rowsMin={2}
                        rows={2}
                        onBlur={() => this.save("message")}
                        className={classes.inputBase}
                        style={{ background: "rgba(253,216,53,0.2)" }}
                        defaultValue={message}
                        onChange={this.handleChange("message")}
                      />
                    </div>
                    <div className={classes.paper} style={{ marginTop: 20 }}>
                      <Typography display="block" variant="h6">
                        <Notes /> Notes
                      </Typography>
                      <Typography display="block" variant="caption">
                        Not visible by the user. For internal use only.
                      </Typography>
                      <InputBase
                        onKeyPress={(e) => {
                          if (e.key === "Enter") this.save("notes");
                        }}
                        multiline
                        fullWidth
                        style={{ background: "rgba(253,216,53,0.2)" }}
                        rows={2}
                        rowsMin={2}
                        onBlur={() => this.save("notes")}
                        className={classes.inputBase}
                        defaultValue={notes}
                        onChange={this.handleChange("notes")}
                      />
                    </div>
                  </div>
                </ExpansionPanelDetails>
                <Divider />
              </ExpansionPanel>
            </Grid>
            {/* <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography display="block" variant="h6">
                      Callbacks
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      Process and check what has been bought for this payment
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  <Button
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    onClick={this.processCallbacks.bind(this)}
                  >
                    Process Callbacks
                  </Button>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid> */}
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography display="block" variant="h6">
                      {!payment.refunded ? "Refund User" : "Refund Info"}
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      {!payment.refunded
                        ? "Get user's money back for this payment."
                        : "Reference"}
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  {payment.refunded ? (
                    <div>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                          <Typography display="block" variant="body2">
                            RefundID
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Chip label={payment.refundID} />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                          <Typography display="block" variant="body2">
                            Reason
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Chip label={payment.refundReason} />
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <div>
                      <Typography display="block" color="secondaryText">
                        Select a reason
                      </Typography>
                      <Grid container spacing={1}>
                        {refundReasons.map((rr) => (
                          <Grid key={rr.code} item>
                            <Chip
                              label={rr.name}
                              color={
                                refundReason === rr.code ? "primary" : undefined
                              }
                              onClick={() =>
                                this.setState({ refundReason: rr.code })
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                      {refundReason ? (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={this.refund.bind(this)}
                          style={{ marginTop: 20 }}
                        >
                          Refund
                        </Button>
                      ) : (
                        []
                      )}
                    </div>
                  )}
                </ExpansionPanelDetails>
                <Divider />
              </ExpansionPanel>
            </Grid>
            <Grid item>
              {payment && payment.cartID ? (
                <Chip
                  label={`Go to Cart #${payment.cartID}`}
                  className={classes.chip}
                  onClick={() => history.push(`${CART}?id=${payment.cartID}`)}
                  variant="outlined"
                  style={{
                    marginRight: 8,
                  }}
                  avatar={
                    <Avatar className={classes.avatar}>
                      <ShoppingCart className={classes.icon} />
                    </Avatar>
                  }
                />
              ) : (
                []
              )}
              {payment && payment.invoiceID ? (
                <Chip
                  label={`Go to Invoice #${payment.invoiceID}`}
                  className={classes.chip}
                  onClick={() =>
                    history.push(`${INVOICE}?id=${payment.invoiceID}`)
                  }
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <InsertDriveFile className={classes.icon} />
                    </Avatar>
                  }
                />
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperPaymentPage);
