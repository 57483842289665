import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const invoices = `/${service}/invoices`;

export function getInvoices() {
  const url = `${invoices}`;
  return {
    method: GET,
    url,
  };
}

export function getInvoiceById(id) {
  const url = `${invoices}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getInvoicePreview(id) {
  const url = `${invoices}/${id}/preview`;
  return {
    method: GET,
    url,
  };
}

export function updateInvoice(id, body) {
  const url = `${invoices}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInvoice(id) {
  const url = `${invoices}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInvoice(body) {
  const url = `${invoices}`;
  return {
    method: POST,
    url,
    body,
  };
}
