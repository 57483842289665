export default (theme) => ({
  paper: {
    padding: "5px 5px 5px 15px",
    borderRadius: 5,
    background: theme.palette.background.default,
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px #cfd8dc",
    borderRadius: 4,
  },
});
