import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormGood from "components/FormGood";
import GoodsList from "components/GoodsList";
// components
import Page from "components/Page";
// constants
import { GOOD } from "constants/routes";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperGoodPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    projects: PropTypes.array,
    goods: PropTypes.array,
    createGood: PropTypes.func,
    deleteGood: PropTypes.func,
    updateGood: PropTypes.func,
    fetchGood: PropTypes.func,
    getGoods: PropTypes.func,
    loading: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedGood: undefined,
      formGoodOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { goods, history, projects, getGoods } = this.props;

    let data = goods;
    const project = projects[index - 2];

    if (index === 1) {
      data = goods.filter((g) => g.projectID === null);
    } else if (index !== 0) {
      data = goods.filter((g) => g.projectID === project.id);
    }

    return (
      <GoodsList
        goods={data}
        getGoods={getGoods}
        history={history}
        projects={projects}
        onSelect={(selectedGood) =>
          history.push(`${GOOD}?id=${selectedGood.id}&prevIndex=${index}`)
        }
      />
    );
  }

  render() {
    const {
      classes,
      history,
      createGood,
      deleteGood,
      updateGood,
      fetchGood,
      loading,
      projects,
      refresh,
      categories,
    } = this.props;

    const { index, selectedGood, formGoodOpen } = this.state;

    return (
      <Page
        helmet="Goods"
        loading={loading}
        loadingMessage={"Fetching all Goods"}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formGoodOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <React.Fragment>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push({
                  search: updateURLParameter("index", v),
                });
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="All"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="Standalone"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              {projects.map((c) => (
                <Tab
                  key={c.name}
                  label={c.name}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
              ))}
            </Tabs>
          </React.Fragment>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
        <FormGood
          history={history}
          open={formGoodOpen}
          good={selectedGood}
          close={() => {
            this.setState({
              formGoodOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedGood: undefined,
                }),
              200
            );
          }}
          createGood={createGood}
          deleteGood={deleteGood}
          updateGood={updateGood}
          fetchGood={fetchGood}
          projects={projects}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperGoodPage);
