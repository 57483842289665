import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormPricing extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    pricing: PropTypes.object,

    createPricing: PropTypes.func,
    updatePricing: PropTypes.func,
    deletePricing: PropTypes.func,
    createPricingCurrency: PropTypes.func,
    updatePricingCurrency: PropTypes.func,
    deletePricingCurrency: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      description: "",
      title: "",
      renewalDiscount: "",
      subscription: "",
      monthPeriod: "",
      price: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.pricing);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  init(pricing) {
    this.setState({
      loading: false,
      title: pricing ? pricing.title : "",
      renewalDiscount: pricing ? pricing.renewalDiscount / 100 : "",
      subscription: pricing ? pricing.subscription : false,
      monthPeriod: pricing ? pricing.monthPeriod : "",
      description: pricing ? pricing.description : "",
      price:
        pricing && pricing.currencies ? pricing.currencies[0].price / 100 : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this pricing, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deletePricing, close, refresh, pricing } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deletePricing(pricing.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Pricing has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updatePricing,
      createPricing,
      updatePricingCurrency,
      createPricingCurrency,
      pricing,
      refresh,
      close,
    } = this.props;

    const {
      title,
      renewalDiscount,
      subscription,
      monthPeriod,
      description,
      price,
    } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (pricing) {
      resp = await updatePricing(pricing.id, {
        title,
        renewalDiscount: Math.round(Number(renewalDiscount) * 100),
        subscription,
        monthPeriod: Number(monthPeriod),
        description,
        price: Math.round(Number(price) * 100),
      });
    } else {
      resp = await createPricing({
        title,
        renewalDiscount: Math.round(Number(renewalDiscount) * 100),
        subscription,
        monthPeriod: Number(monthPeriod),
        description,
        price: Math.round(Number(price) * 100),
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: pricing
          ? "Pricing has been updated."
          : "Pricing has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const { classes, fullScreen, open, pricing, close } = this.props;

    const {
      title,
      renewalDiscount,
      subscription,
      monthPeriod,
      description,
      price,
      loading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {pricing
                ? `${pricing.description}`
                : "Let's Create a New Pricing"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {pricing
                  ? "To update the pricing, fill the form below :"
                  : "To create a new pricing, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="title"
                    label="Title"
                    className={classes.textField}
                    value={title}
                    onChange={this.handleChange("title")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Description"
                    className={classes.textField}
                    value={description}
                    onChange={this.handleChange("description")}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={subscription}
                        onChange={this.handleCheckboxChange("subscription")}
                        value="active"
                        color="primary"
                      />
                    }
                    label="Subscription"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="monthPeriod"
                    label="Month Period"
                    className={classes.textField}
                    value={monthPeriod}
                    onChange={this.handleChange("monthPeriod")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="renewalDiscount"
                    label="Renewal Discount (USD)"
                    className={classes.textField}
                    value={renewalDiscount}
                    onChange={this.handleChange("renewalDiscount")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="price"
                    label="Price (USD)"
                    className={classes.textField}
                    value={price}
                    onChange={this.handleChange("price")}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {pricing && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {pricing ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormPricing));
