import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AttachMoney from "@material-ui/icons/AttachMoneyTwoTone";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import People from "@material-ui/icons/PeopleTwoTone";
import Eye from "@material-ui/icons/RemoveRedEyeTwoTone";
import ShoppingCart from "@material-ui/icons/ShoppingCartTwoTone";
// custom
import Table from "components/TablePagination";
import TableStatus from "components/TableStatus";
// constants
import { IMPERSONATE_REDIRECTION } from "config";
import { CART, CUSTOMER, INVOICE, PAYMENT } from "constants/routes";
import { setCookie } from "helpers/cookie";
// helpers
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withCookies } from "react-cookie";
// styles
import styles from "./styles";

class PaymentsList extends Component {
  static propTypes = {
    history: PropTypes.object,
    cookies: PropTypes.object,
    impersonate: PropTypes.func,
    payments: PropTypes.array,
    onSelect: PropTypes.func,
    simple: PropTypes.bool,
    noPaper: PropTypes.bool,
    noTitle: PropTypes.bool,
  };

  onSelect(selectedPayment) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedPayment);
    }
  }

  getMeta() {
    const { history, cookies, impersonate } = this.props;

    return [
      {
        path: "total",
        title: "Total",
        numeric: false,
        transform: (t) => formattedPrice(t),
      },
      {
        path: "paymentDate",
        title: "date",
        numeric: false,
        render: (t) => (
          <React.Fragment>
            <Typography display="block" variant="body2">
              {moment(t).parseZone().fromNow()}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              {moment(t).parseZone().format("MM/DD/YYYY HH:mm:ss")}
            </Typography>
          </React.Fragment>
        ),
      },
      {
        path: "id",
        title: "Actions",
        numeric: true,
        width: 120,
        render: (t, d) => (
          <React.Fragment>
            <Tooltip title="Go to Invoice">
              <IconButton
                component="span"
                style={{ padding: 4 }}
                onClick={() => history.push(`${INVOICE}?id=${d.invoiceID}`)}
              >
                <InsertDriveFile style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Go to Customer">
              <IconButton
                component="span"
                style={{ padding: 4 }}
                onClick={() => history.push(`${CUSTOMER}?id=${d.customer.id}`)}
              >
                <People style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Go to Cart">
              <IconButton
                component="span"
                style={{ padding: 4 }}
                onClick={() => history.push(`${CART}?id=${d.cart.id}`)}
              >
                <ShoppingCart style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Impersonate">
              <IconButton
                component="span"
                style={{ padding: 4 }}
                onClick={async () => {
                  setCookie(
                    cookies,
                    "redirectAfterStopImpersonate",
                    window.location.href
                  );
                  await impersonate(d.customer.userUUID);
                  window.location.replace(IMPERSONATE_REDIRECTION);
                }}
              >
                <Eye style={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        ),
      },
    ];
  }

  getSimpleMeta() {
    return [
      {
        path: "paid",
        title: "Status",
        numeric: false,
        component: TableStatus,
      },
      {
        path: "transactionID",
        title: "Transaction ID",
        numeric: false,
      },
    ];
  }

  render() {
    const { payments, history, simple, noPaper, noTitle } = this.props;

    if (payments.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "calc(100vh - 140px)", textAlign: "center" }}
        >
          <Grid item>
            <AttachMoney style={{ fontSize: 70 }} />
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              No payments
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Customer does not have any payments.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={payments}
        meta={simple ? this.getSimpleMeta() : this.getMeta()}
        title={noTitle ? "" : "Payments"}
        onRowSelect={(p) => history.push(`${PAYMENT}?id=${p.id}`)}
        noPaper={noPaper}
      />
    );
  }
}

export default withCookies(withStyles(styles)(PaymentsList));
