import { networkAction } from "helpers/network/networkAction";

import {
  GET_SEARCH_PRESETS,
  GET_SEARCH_PRESET_BY_ID,
  DELETE_SEARCH_PRESET,
  UPDATE_SEARCH_PRESET,
  CREATE_SEARCH_PRESET,
} from "constants/searchPresets";

import * as Api from "api";

export const getSearchPresets = () => async (dispatch) =>
  networkAction(dispatch, GET_SEARCH_PRESETS, Api.getSearchPresets, []);

export const getSearchPresetById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SEARCH_PRESET_BY_ID, Api.getSearchPresetById, [
    id,
  ]);

export const deleteSearchPreset = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SEARCH_PRESET, Api.deleteSearchPreset, [id]);

export const createSearchPreset = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SEARCH_PRESET, Api.createSearchPreset, [body]);

export const updateSearchPreset = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SEARCH_PRESET, Api.updateSearchPreset, [
    id,
    body,
  ]);
