export const serviceRoles = {
  account: {
    name: "account",
    roles: {
      any: "any",
      anon: "anon",
      user: "user",
      admin: "admin",
      superadmin: "superadmin",
    },
  },
  bazaar: {
    name: "bazaar",
    roles: {
      any: "any",
      anon: "anon",
      user: "user",
      admin: "admin",
      superadmin: "superadmin",
    },
  },
};
