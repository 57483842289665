import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// custom
import Table from "components/TablePagination";

// styles
import styles from "./styles";

class TicketsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    tickets: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedTicket) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedTicket);
    }
  }

  render() {
    const { tickets } = this.props;

    return (
      <Table
        data={tickets}
        meta={[
          {
            path: "serialNumber",
            title: "Serial Number",
          },
          {
            path: "description",
            title: "Description",
          },
          {
            path: "paymentID",
            title: "Payment",
            transform: (t, d) => (d.paymentID ? `Paid (${d.paymentID})` : "-"),
          },
          {
            path: "expired",
            title: "expired",
            transform: (t, d) => (d.expired ? "Expired" : "Valid"),
          },
        ]}
        title={"Tickets"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(TicketsList);
