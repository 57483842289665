// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperCustomerPage from "../component/WrapperCustomerPage";

const mapStateToProps = (state) => ({
  contacts: state.contacts,
  subscriptions: state.subscriptions,
  goods: state.goods,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerFull: (...args) => dispatch(Actions.getCustomerFull(...args)),
  getContacts: (...args) => dispatch(Actions.getContacts(...args)),
  updateCustomer: (...args) => dispatch(Actions.updateCustomer(...args)),
  deleteCustomer: (...args) => dispatch(Actions.deleteCustomer(...args)),
  createPayment: (...args) => dispatch(Actions.createPayment(...args)),
  createInvoice: (...args) => dispatch(Actions.createInvoice(...args)),
  getGoods: (...args) => dispatch(Actions.getGoods(...args)),
  getSubscriptions: (...args) => dispatch(Actions.getSubscriptions(...args)),
  validateCart: (...args) => dispatch(Actions.validateCart(...args)),
});
class CustomerPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getCustomerFull: PropTypes.func,
    updateCustomer: PropTypes.func,
    deleteCustomer: PropTypes.func,
    createInvoice: PropTypes.func,
    createPayment: PropTypes.func,
    getSubscriptions: PropTypes.func,
    subscriptions: PropTypes.array,
    getGoods: PropTypes.func,

    getContacts: PropTypes.func,
    validateCart: PropTypes.func,
    contacts: PropTypes.any,
  };

  constructor(...args) {
    super(...args);
    const { location, getContacts, getSubscriptions, getGoods } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      customerID: urlParams.id,
      loading: true,
    };
    getContacts();
    getGoods();
    getSubscriptions();
    this.refresh();
  }

  async refresh() {
    const { getCustomerFull } = this.props;
    const { customerID } = this.state;
    const resps = await Promise.all([getCustomerFull(customerID)]);

    this.setState({
      customer: resps[0].payload,
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      updateCustomer,
      deleteCustomer,
      subscriptions,
      createPayment,
      contacts,
      validateCart,
      goods,
      createInvoice,
    } = this.props;

    const { urlParams, customer, loading } = this.state;

    return (
      <WrapperCustomerPage
        history={history}
        location={location}
        urlParams={urlParams}
        customer={customer}
        loading={loading}
        goods={goods}
        subscriptions={subscriptions}
        createInvoice={createInvoice}
        updateCustomer={updateCustomer}
        deleteCustomer={deleteCustomer}
        createPayment={createPayment}
        validateCart={validateCart}
        contacts={contacts}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
