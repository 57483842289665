import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DataUsage from "@material-ui/icons/DataUsageTwoTone";

// custom
import DatetimeGraph from "components/DatetimeGraph";

// styles
import styles from "./styles";

class GoodSales extends Component {
  static propTypes = {
    classes: PropTypes.object,
    sales: PropTypes.array,
  };

  render() {
    const { classes, sales } = this.props;

    return (
      <Grid
        container
        spacing={2}
        className={classes.container}
        alignItems="stretch"
      >
        <Grid item xs={12} md={4}>
          <Paper
            className="card"
            style={{ color: "#00bcd4", height: "calc(100% - 90px)" }}
          >
            <Paper className="cardHeader" style={{ background: "#00bcd4" }}>
              <DataUsage style={{ color: "white" }} />
            </Paper>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography display="block" variant="h6" color="inherit">
                  Units Sold
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item>
                <Typography display="block" variant="h3">
                  {sales.length}
                </Typography>
                <Typography display="block" color="textSecondary">
                  Units
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Paper className="card" style={{ color: '#3f51b5', height: 'calc(100% - 90px)' }}>
            <Paper className="cardHeader" style={{ background: '#3f51b5' }}>
              <Timelapse style={{ color: 'white' }} />
            </Paper>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography display="block" variant="h6" color="inherit">
                  Expiration
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography display="block" variant="h3"> 
                Hello
              </Typography>
              <Typography display="block" color="textSecondary">
                Hello
              </Typography>
            </Grid>
          </Paper>
        </Grid> */}
        {/* <Grid item xs={12} md={4}>
          <Paper className="card" style={{ color: '#673ab7', height: 'calc(100% - 90px)' }}>
            <Paper className="cardHeader" style={{ background: '#673ab7' }}>
              <AttachMoney style={{ color: 'white' }} />
            </Paper>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography display="block" variant="h6" color="inherit">
                  Savings
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography display="block" variant="h3"> 
                Hello
              </Typography>
              <Typography display="block" color="textSecondary">
                Hello
              </Typography>
            </Grid>
          </Paper>
        </Grid> */}
        {/* <Grid item xs={12}>
          <Paper>
            <DatetimeGraph
              data={sales}
              height={200}
              dateKey={'paymentDate'}
              dataKey={'quantity'}
              color="#00bcd4"
              price
            />
          </Paper>
        </Grid> */}
      </Grid>
    );
  }
}

export default withStyles(styles)(GoodSales);
