import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import ActivitiesList from "components/ActivitiesList";
import FormActivitie from "components/FormActivitie";
// components
import Page from "components/Page";
// constants
import { ACTIVITY } from "constants/routes";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperActivitiePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    activities: PropTypes.array,
    createActivity: PropTypes.func,
    deleteActivity: PropTypes.func,
    updateActivity: PropTypes.func,
    roles: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedActivitie: undefined,
      formActivitieOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { activities, history } = this.props;

    switch (index) {
      case 0:
        return (
          <ActivitiesList
            activities={activities}
            onSelect={(customer) =>
              history.push(`${ACTIVITY}?id=${customer.id}`)
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createActivity,
      deleteActivity,
      updateActivity,
      refresh,
    } = this.props;

    const { index, selectedActivitie, formActivitieOpen } = this.state;

    return (
      <Page
        helmet="Activities"
        loading={loading}
        loadingMessage={"Fetching all Activities"}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formActivitieOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <React.Fragment>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push({
                  search: updateURLParameter("index", v),
                });
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="All"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </React.Fragment>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
        <FormActivitie
          open={formActivitieOpen}
          activitie={selectedActivitie}
          close={() => {
            this.setState({
              formActivitieOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedActivitie: undefined,
                }),
              200
            );
          }}
          createActivity={createActivity}
          deleteActivity={deleteActivity}
          updateActivity={updateActivity}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperActivitiePage);
