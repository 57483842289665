import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Close";

// custom
import DateTime from "components/DateTime";
import EventMedia from "components/EventMedia";

// helpers
import { formattedPrice } from "helpers/stripe";

// constants
import { BILLING, PRICING, GOODS, CATEGORIES } from "constants/routes";

// styles
import styles from "./styles";

class EventInfo extends Component {
  static propTypes = {
    classes: PropTypes.object,
    event: PropTypes.object,
    refresh: PropTypes.func,
    api: PropTypes.object,
    pricings: PropTypes.array,
    history: PropTypes.object,
    ticketTemplates: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { event } = this.props;
    this.state = {
      ...event,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async save(name, numeric) {
    const { api, refresh, event } = this.props;

    if (event[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await api.updateEvent(event.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async addCategory(e) {
    const { event, api, refresh } = this.props;
    await api.createEventCategory({
      eventID: event.id,
      categoryID: Number(e.target.value),
    });
    refresh();
  }

  async addPricingPlan(e) {
    const { event, api, refresh } = this.props;
    await api.createEventPricing({
      eventID: event.id,
      pricingID: Number(e.target.value),
    });
    refresh();
  }

  deleteEvent() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { api, event, history } = this.props;
    await api.deleteEvent(event.id);
    history.push(GOODS);
  }

  render() {
    const {
      classes,
      pricings,
      history,
      event,
      api,
      refresh,
      ticketTemplates,
    } = this.props;
    const {
      title,
      description,
      pricingID,
      loading,
      salesPage,
      dateTimeTo,
      dateTimeFrom,
      notes,
    } = this.state;

    return (
      <Grid
        style={{ marginLeft: -8 }}
        container
        spacing={2}
        className={classes.container}
      >
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <EventMedia
              event={event}
              deleteMedium={api.deleteMedium}
              updateMedium={api.updateMedium}
              createEventMedia={api.createEventMedia}
              refresh={refresh}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <Typography display="block" variant="h6">
              General Info
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Title:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("title");
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("title")}
                  className={classes.inputBase}
                  defaultValue={title}
                  onChange={this.handleChange("title")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Sales Page:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("salesPage");
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("salesPage")}
                  className={classes.inputBase}
                  defaultValue={salesPage}
                  onChange={this.handleChange("salesPage")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Description:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("description");
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("description")}
                  className={classes.inputBase}
                  defaultValue={description}
                  multiline
                  onChange={this.handleChange("description")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Notes:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("notes");
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("notes")}
                  className={classes.inputBase}
                  defaultValue={notes}
                  multiline
                  onChange={this.handleChange("notes")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  From:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <DateTime
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.save("dateTimeFrom");
                    }
                  }}
                  disabled={loading}
                  onBlur={() => {
                    this.save("dateTimeFrom");
                  }}
                  className={classes.inputBase}
                  date={dateTimeFrom}
                  onChange={this.handleChange("dateTimeFrom")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  To:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <DateTime
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.save("dateTimeTo");
                    }
                  }}
                  disabled={loading}
                  onBlur={() => {
                    this.save("dateTimeTo");
                  }}
                  className={classes.inputBase}
                  date={dateTimeTo}
                  onChange={this.handleChange("dateTimeTo")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Public:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  checked={this.state.public}
                  onChange={(e) => {
                    this.setState({ public: e.target.checked }, () =>
                      this.save("public")
                    );
                  }}
                  color="primary"
                />
              </Grid>
            </Grid>
            {/* <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">Categories:</Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="project-simple">Add a Category</InputLabel>
                  <Select
                    inputProps={{
                      name: 'Select the Pricings',
                      id: 'Pricings-simple',
                    }}
                    value={pricingID}
                    onChange={this.addCategory.bind(this)}
                  >
                    {
                      categories.filter(c => event.categories.find(ca => ca.id === c.id) === undefined).map(p => (
                        <MenuItem key={`cat_${p.id}`} value={p.id}>
                          <ListItemIcon className={classes.icon}>
                            <img src={p.icon} alt="category" style={{ height: 20 }} />
                          </ListItemIcon>
                          <ListItemText classes={{ primary: classes.primary }} inset primary={p.name} />
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <Typography display="block"
                    className="link"
                    variant="caption"
                    color="primary"
                    onClick={() => history.push(`${CATEGORIES}`)}
                  >
                    View All Categories
                  </Typography>
                  <Grid container spacing={2}>
                    {
                      event.categories && event.categories.map(p => (
                        <Grid item>
                          <Chip
                            onDelete={async () => {
                              await deleteEventCategory(p.relationID);
                              refresh();
                            }}
                            avatar={(
                              <img src={p.icon} style={{ height: 20, width: 20, marginLeft: 6 }} alt="cat" />
                            )}
                            label={p.name}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </FormControl>
              </Grid>
            </Grid> */}
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: 20 }}>
            <Typography display="block" variant="h6">
              Pricings Plans
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Pricing:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="project-simple">
                    Select a Pricing
                  </InputLabel>
                  <Select
                    inputProps={{
                      name: "Select the Pricings",
                      id: "Pricings-simple",
                    }}
                    value={pricingID}
                    onChange={this.addPricingPlan.bind(this)}
                  >
                    {pricings
                      .filter(
                        (p) =>
                          event.pricings.find((pr) => pr.id === p.id) ===
                          undefined
                      )
                      .map((p) => (
                        <MenuItem key={`pricings_${p.id}`} value={p.id}>
                          {p.title}{" "}
                          {p.currencies && p.currencies.length
                            ? `(${p.currencies[0].currency} ${formattedPrice(
                                p.currencies[0].price
                              )})`
                            : ""}
                        </MenuItem>
                      ))}
                  </Select>
                  <Typography
                    display="block"
                    className="link"
                    variant="caption"
                    color="primary"
                    onClick={() => history.push(`${BILLING}`)}
                  >
                    View All Pricings
                  </Typography>
                  <Grid container spacing={2}>
                    {event.pricings &&
                      event.pricings.map((p) => (
                        <Grid item key={p.id}>
                          <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                            style={{
                              background: "rgba(155,155,155,0.1)",
                              border: "solid 1px rgba(155,155,155,0.3)",
                              borderRadius: 4,
                              padding: 4,
                            }}
                          >
                            <Grid item>
                              <Typography display="block" variant="body2">
                                {`${p.title} ${
                                  p.currencies && p.currencies.length
                                    ? `(${
                                        p.currencies[0].currency
                                      } ${formattedPrice(
                                        p.currencies[0].price
                                      )})`
                                    : ""
                                }`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={async () => {
                                  await api.deleteEventPricing(p.relationID);
                                  refresh();
                                }}
                                style={{ padding: 4 }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl
                                className={classes.formControl}
                                fullWidth
                              >
                                <InputLabel htmlFor="project-simple">
                                  Ticket Template
                                </InputLabel>
                                <Select
                                  inputProps={{
                                    name: "Select the Pricings",
                                    id: "Pricings-simple",
                                  }}
                                  value={p.ticketTemplateID}
                                  onChange={async (e) => {
                                    await api.updateEventPricing(p.relationID, {
                                      ticketTemplateID: e.target.value,
                                    });
                                    refresh();
                                  }}
                                >
                                  {ticketTemplates &&
                                    ticketTemplates.map((tt) => (
                                      <MenuItem
                                        key={`tt_${tt.id}`}
                                        value={tt.id}
                                      >
                                        {`Template #${tt.id}`}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          {/* <Chip
                            onClick={() => history.push(`${PRICING}?id=${p.id}`)}
                            onDelete={async () => {
                              await api.deleteEventPricing(p.relationID);
                              refresh();
                            }}
                            label={`${p.title} ${p.currencies && p.currencies.length ? `(${p.currencies[0].currency} ${formattedPrice(p.currencies[0].price)})` : ''}`}
                          /> */}
                        </Grid>
                      ))}
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <div style={{ marginTop: 20 }}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div>
                  <Typography
                    display="block"
                    variant="h6"
                    style={{ color: "#f44336" }}
                  >
                    Danger Zone
                  </Typography>
                  <Typography display="block" color="textSecondary">
                    Delete the Event.
                  </Typography>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.details}>
                <Button
                  style={{
                    color: "white",
                    background: "#f44336",
                  }}
                  onClick={this.deleteEvent.bind(this)}
                >
                  Delete Event
                </Button>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(EventInfo);
