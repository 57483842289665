import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormSetting from "components/FormSetting";
// reactor
import Page from "components/Page";
// custom
import SettingsList from "components/SettingsList";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperSettingPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    settings: PropTypes.array,
    createSetting: PropTypes.func,
    deleteSetting: PropTypes.func,
    updateSetting: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedSetting: undefined,
      formSettingOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { settings } = this.props;

    switch (index) {
      case 0:
        return (
          <SettingsList
            settings={settings}
            onSelect={(selectedSetting) =>
              this.setState({
                selectedSetting,
                formSettingOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createSetting,
      deleteSetting,
      updateSetting,
      refresh,
    } = this.props;

    const { index, selectedSetting, formSettingOpen } = this.state;

    return (
      <Page
        helmet="Settings"
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formSettingOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Settings"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
        <FormSetting
          open={formSettingOpen}
          setting={selectedSetting}
          close={() => {
            this.setState({
              formSettingOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedSetting: undefined,
                }),
              200
            );
          }}
          createSetting={createSetting}
          deleteSetting={deleteSetting}
          updateSetting={updateSetting}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSettingPage);
