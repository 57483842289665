import React, { PureComponent } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AppsIcon from "@material-ui/icons/Apps";
import Home from "@material-ui/icons/Home";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// custom
import App from "components/App";

// styles
const styles = (theme) => ({
  container: {
    padding: 5,
  },
});

class Apps extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    darkMode: PropTypes.bool,
    userApps: PropTypes.array,
    history: PropTypes.object,
    baseHostname: PropTypes.string,
  };

  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { darkMode, userApps, baseHostname, history } = this.props;
    const { open } = this.state;

    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <IconButton onClick={this.handleOpen.bind(this)}>
          {darkMode ? <AppsIcon /> : <AppsIcon style={{ color: "white" }} />}
        </IconButton>
        {open && (
          <ClickAwayListener onClickAway={this.handleClose.bind(this)}>
            <Paper
              style={{
                position: "absolute",
                zIndex: 99999,
                width: 300,
                left: -100,
                padding: 15,
              }}
            >
              <Grid container>
                {userApps.map((a) => (
                  <Grid item xs={3}>
                    <App {...a} key={a.id} size={50} withName />
                  </Grid>
                ))}
              </Grid>
              {history && baseHostname ? (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ paddingTop: 10 }}
                >
                  <Grid item>
                    <Button
                      onClick={() => window.location.replace(baseHostname)}
                      variant="outlined"
                    >
                      <Home /> Home
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <div />
              )}
            </Paper>
          </ClickAwayListener>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Apps);
