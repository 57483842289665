import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import HistoryIcon from "@material-ui/icons/History";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
// custom
import Table from "components/TablePagination";
// constants
import { INVOICE } from "constants/routes";
import { buildForURL } from "helpers";
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const refundReasons = [
  {
    name: "Duplicate",
    code: "duplicate",
  },
  {
    name: "Fraudulent",
    code: "fraudulent",
  },
  {
    name: "Requested by Customer",
    code: "requested_by_customer",
  },
];

class InvoicesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    invoices: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
  };

  onSelect(selectedInvoice) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedInvoice);
    }
  }

  render() {
    const { invoices, history } = this.props;

    if (invoices.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "calc(100vh - 140px)", textAlign: "center" }}
        >
          <Grid item>
            <InsertDriveFile style={{ fontSize: 70 }} />
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              No invoices
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              No invoices have been found.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={invoices}
        meta={[
          {
            path: "id",
            numeric: false,
            render: () => <InsertDriveFile />,
            width: 20,
          },
          {
            path: "paid",
            title: "Paid",
            numeric: false,
            render: (t, d) => {
              let paidWidget = {
                label: "Unpaid",
                color: "#ff9800",
                icon: <MoneyOffIcon style={{ color: "white" }} />,
              };

              if (d.paid) {
                paidWidget = {
                  label: "Paid",
                  color: "#4caf50",
                  icon: <CheckIcon style={{ color: "white" }} />,
                };
              }

              if (d && d.lastPaymentID) {
                const lp = d.lastPayment;
                if (lp) {
                  if (!lp.paid) {
                    paidWidget = {
                      label: "Rejected",
                      color: "#f44336",
                      subtitle: lp.message,
                      icon: <ErrorIcon style={{ color: "white" }} />,
                    };
                  } else if (lp.refunded) {
                    paidWidget = {
                      label: "Refunded",
                      color: "#2196f3",
                      subtitle: refundReasons.find(
                        (f) => f.code === lp.refundReason
                      ).name,
                      icon: <HistoryIcon style={{ color: "white" }} />,
                    };
                  } else {
                    paidWidget = {
                      label: "Paid",
                      color: "#4caf50",
                      icon: <CheckIcon style={{ color: "white" }} />,
                    };
                  }
                }
              }

              return (
                <Chip
                  avatar={
                    <Avatar style={{ background: paidWidget.color }}>
                      {paidWidget.icon}
                    </Avatar>
                  }
                  style={{
                    background: paidWidget.color,
                    color: "white",
                  }}
                  label={paidWidget.label}
                />
              );
            },
          },
          {
            path: "status",
            title: "Status",
            numeric: false,
            render: (t) => <Chip label={t} />,
            width: 20,
          },
          {
            path: "creationDate",
            title: "Creation",
            numeric: false,
            transform: (t) => moment(t).parseZone().fromNow(),
            render: (t, d) => (
              <React.Fragment>
                <Typography display="block" variant="body2">
                  {t}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  {moment(d.creationDate).calendar()}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "dueDate",
            title: "Due",
            numeric: false,
            transform: (t) => moment(t).parseZone().fromNow(),
            render: (t, d) => (
              <React.Fragment>
                <Typography display="block" variant="body2">
                  {t}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  {moment(d.dueDate).calendar()}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "subTotal",
            title: "Sub Total",
            numeric: false,
            transform: (t) => `USD ${formattedPrice(t)}`,
            render: (t, d) => (
              <React.Fragment>
                <Typography display="block" variant="body2">
                  {t}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Taxes: {d.taxPercent}%
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "total",
            title: "Total",
            numeric: false,
            transform: (t) => `USD ${formattedPrice(t)}`,
          },
        ]}
        title={"Invoices"}
        onRowSelect={(p) =>
          history.push({
            pathname: INVOICE,
            search: buildForURL({
              id: p.id,
              back: `${window.location.pathname}${window.location.search}`,
            }),
          })
        }
      />
    );
  }
}

export default withStyles(styles)(InvoicesList);
