import * as the from "themes/theme";
import { REACT_APP_ROOT_BACKGROUND } from "config/env";

const drawerWidth = 240;
export default (theme) => ({
  loading: {
    flexGrow: 1,
    height: "100vh",
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    background: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${REACT_APP_ROOT_BACKGROUND}) no-repeat`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },
  selected: {
    color: `${the.default.primary[500]} !important`,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  background:
    theme.palette.type === "light"
      ? {
          backgroundColor: theme.palette.primary.light,
        }
      : {
          backgroundColor: theme.palette.background.default,
        },
  list: {
    paddingTop: 0,
  },
  drawerContainer: {
    height: "100%",
    background: theme.palette.background.paper,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    background: theme.palette.background.paper,
    height: "100%",
    flexGrow: 1,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9,
    },
  },
});
