import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperSettingPage from "../component/WrapperSettingPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  getSettings: () => dispatch(Actions.getSettings()),
  getSettingById: (...args) => dispatch(Actions.getSettingById(...args)),
  createSetting: (...args) => dispatch(Actions.createSetting(...args)),
  deleteSetting: (...args) => dispatch(Actions.deleteSetting(...args)),
  updateSetting: (...args) => dispatch(Actions.updateSetting(...args)),
});
class SettingPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    settings: PropTypes.array,
    getSettings: PropTypes.func,
    getSettingById: PropTypes.func,
    createSetting: PropTypes.func,
    deleteSetting: PropTypes.func,
    updateSetting: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, settings } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: settings.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getSettings } = this.props;
    await Promise.all([getSettings()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      settings,
      getSettingById,
      createSetting,
      deleteSetting,
      updateSetting,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperSettingPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        settings={settings}
        getSettingById={getSettingById}
        createSetting={createSetting}
        deleteSetting={deleteSetting}
        updateSetting={updateSetting}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingPage);
