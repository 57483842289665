import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormMarket from "components/FormMarket";
// custom
import MarketsList from "components/MarketsList";
// reactor
import Page from "components/Page";
// routes
import { MARKET } from "constants/routes";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperMarketPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    markets: PropTypes.array,
    createMarket: PropTypes.func,
    deleteMarket: PropTypes.func,
    updateMarket: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedMarket: undefined,
      formMarketOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { markets, history } = this.props;

    switch (index) {
      case 0:
        return (
          <MarketsList
            markets={markets}
            onSelect={(selectedMarket) =>
              history.push(`${MARKET}?id=${selectedMarket.id}`)
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createMarket,
      deleteMarket,
      updateMarket,
      refresh,
    } = this.props;

    const { index, selectedMarket, formMarketOpen } = this.state;

    return (
      <Page
        helmet="Markets"
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formMarketOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Markets"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormMarket
          open={formMarketOpen}
          market={selectedMarket}
          close={() => {
            this.setState({
              formMarketOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedMarket: undefined,
                }),
              200
            );
          }}
          createMarket={createMarket}
          deleteMarket={deleteMarket}
          updateMarket={updateMarket}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperMarketPage);
