import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
// custom
import CategoriesList from "components/CategoriesList";
import FormCategory from "components/FormCategory";
// reactor
import Page from "components/Page";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperCategoriePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    categories: PropTypes.array,
    createCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    updateCategory: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedCategorie: undefined,
      formCategorieOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { categories } = this.props;

    switch (index) {
      case 0:
        return (
          <CategoriesList
            categories={categories}
            onSelect={(selectedCategorie) =>
              this.setState({
                selectedCategorie,
                formCategorieOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createCategory,
      deleteCategory,
      updateCategory,
      refresh,
    } = this.props;

    const { index, selectedCategorie, formCategorieOpen } = this.state;

    return (
      <Page
        helmet="Categories"
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formCategorieOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Categories"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
        <FormCategory
          open={formCategorieOpen}
          categorie={selectedCategorie}
          close={() => {
            this.setState({
              formCategorieOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedCategorie: undefined,
                }),
              200
            );
          }}
          createCategory={createCategory}
          deleteCategory={deleteCategory}
          updateCategory={updateCategory}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCategoriePage);
