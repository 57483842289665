// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperCouponPage from "../component/WrapperCouponPage";

const mapStateToProps = (state) => ({
  coupons: state.coupons,
  goods: state.goods,
});

const mapDispatchToProps = (dispatch) => ({
  getCoupons: () => dispatch(Actions.getCoupons()),
  getGoods: () => dispatch(Actions.getGoods()),
  getCouponById: (...args) => dispatch(Actions.getCouponById(...args)),
  createCoupon: (...args) => dispatch(Actions.createCoupon(...args)),
  deleteCoupon: (...args) => dispatch(Actions.deleteCoupon(...args)),
  updateCoupon: (...args) => dispatch(Actions.updateCoupon(...args)),
  addCouponGood: (...args) => dispatch(Actions.addCouponGood(...args)),
  deleteCouponGood: (...args) => dispatch(Actions.deleteCouponGood(...args)),
});
class CouponPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    coupons: PropTypes.array,
    getCoupons: PropTypes.func,
    goods: PropTypes.array,
    getGoods: PropTypes.func,
    getCouponById: PropTypes.func,
    createCoupon: PropTypes.func,
    deleteCoupon: PropTypes.func,
    updateCoupon: PropTypes.func,
    addCouponGood: PropTypes.func,
    deleteCouponGood: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, coupons } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: coupons.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getCoupons, getGoods } = this.props;
    await Promise.all([getCoupons(), getGoods()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      coupons,
      getCouponById,
      createCoupon,
      deleteCoupon,
      updateCoupon,
      addCouponGood,
      deleteCouponGood,
      history,
      goods,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCouponPage
        refresh={this.refresh.bind(this)}
        goods={goods}
        loading={loading}
        history={history}
        coupons={coupons}
        getCouponById={getCouponById}
        createCoupon={createCoupon}
        deleteCoupon={deleteCoupon}
        updateCoupon={updateCoupon}
        addCouponGood={addCouponGood}
        deleteCouponGood={deleteCouponGood}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CouponPage);
