import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
// custom
import CustomersList from "components/CustomersList";
import FormCustomer from "components/FormCustomer";
// reactor
import Page from "components/Page";
// constants
import { CUSTOMER } from "constants/routes";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperCustomerPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    customers: PropTypes.array,
    subscriptions: PropTypes.array,
    createCustomer: PropTypes.func,
    subscriptions: PropTypes.array,
    deleteCustomer: PropTypes.func,
    updateCustomer: PropTypes.func,
    getCustomers: PropTypes.func,
    getSubscriptions: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedCustomer: undefined,
      formCustomerOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      customers,
      history,
      getCustomers,
      getSubscriptions,
      subscriptions,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <CustomersList
            customers={customers}
            history={history}
            subscriptions={subscriptions}
            getCustomers={getCustomers}
            getSubscriptions={getSubscriptions}
            onSelect={(customer) =>
              history.push(`${CUSTOMER}?id=${customer.id}`)
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createCustomer,
      deleteCustomer,
      updateCustomer,
      refresh,
    } = this.props;

    const { index, selectedCustomer, formCustomerOpen } = this.state;

    return (
      <Page
        helmet="Customers"
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formCustomerOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Customers"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
        <FormCustomer
          open={formCustomerOpen}
          customer={selectedCustomer}
          close={() => {
            this.setState({
              formCustomerOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedCustomer: undefined,
                }),
              200
            );
          }}
          createCustomer={createCustomer}
          deleteCustomer={deleteCustomer}
          updateCustomer={updateCustomer}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCustomerPage);
