export const ROOT = "/";
export const SIGNIN = "/signin";
export const DEMO = "/demo";
export const DEFAULT = "/default";
export const MY_ACCOUNT = "/my-account";
export const GOODS = "/goods";
export const ACTIVITIES = "/activities";
export const COUPONS = "/coupons";
export const PAYMENTS = "/payments";
export const PAYMENT = "/payment";
export const GOOD = "/good";
export const PROJECTS = "/projects";
export const CREDITCARD = "/creditcard";
export const CUSTOMERS = "/customers";
export const BILLING = "/billing";
export const CUSTOMER = "/customer";
export const CURRENCIE = "/currencies";
export const COUPON = "/coupon";
export const CART = "/cart";
export const STATEMENT = "/statement";
export const INVOICE = "/invoice";
export const ACTIVITY = "/activity";
export const SETTINGS = "/settings";
export const CONTACTS = "/contacts";
export const PRICING = "/pricing";
export const CATEGORIES = "/categories";
export const MARKETS = "/markets";
export const MARKET = "/market";
export const TICKETS = "/tickets";
export const EVENTS = "/events";
export const EVENT = "/event";
export const SUBSCRIPTIONS = "/subscriptions";
