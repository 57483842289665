export default (theme) => ({
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  tabRoot: {
    textTransform: "initial",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover": {
      color: theme.palette.primary.color[700],
      opacity: 1,
    },
    "&$tabSelected": {
      color: theme.palette.primary.color[700],
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.primary.color[700],
    },
  },
  reducedPadding: {
    padding: "6px 0px 6px 0px !important",
    background: "red",
  },
  tabSelected: {},
});
