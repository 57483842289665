// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class GoodsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    goods: PropTypes.array,
    getGoods: PropTypes.func,
    history: PropTypes.object,
    projects: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedGood) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedGood);
    }
  }

  render() {
    const { goods, classes, projects, history, getGoods } = this.props;

    const formatter = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return (
      <AdvancedSearch
        history={history}
        location={window.location}
        rowCount={goods.length}
        refresh={getGoods}
        keyword="goods"
        metaDataEndpoint="/bazaar/goods/searchMetadata"
        metaDataOveride={{
          projectID: {
            type: "autocomplete",
            data: projects,
            valueKey: "id",
            labelKey: "name",
          },
        }}
      >
        <Table
          data={goods}
          rowStyle={(d) => ({
            opacity: d.public ? 1 : 0.5,
          })}
          meta={[
            {
              path: "pictures",
              numeric: false,
              render: (t, d) => (
                <div
                  className={classes.image}
                  style={{
                    backgroundImage: `url('${
                      d.pictures && d.pictures.length
                        ? d.pictures[0].url
                        : "https://storage.googleapis.com/college101-images/placeholder.png"
                    }')`,
                  }}
                />
              ),
              width: 50,
            },
            {
              path: "title",
              title: "Title",
              numeric: false,
              render: (t, d) => (
                <React.Fragment>
                  <Typography display="block" variant="body2">
                    {t}
                  </Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {d.public ? "Public" : "Not Public"}
                  </Typography>
                </React.Fragment>
              ),
            },
            {
              path: "projectID",
              title: "Project",
              numeric: false,
              transform: (t) =>
                t
                  ? projects.find((p) => p.id === t) &&
                    projects.find((p) => p.id === t).name
                  : "Standalone",
            },
            {
              path: "pricing",
              title: "Price",
              numeric: true,
              transform: (t, d) =>
                d.pricing ? `${formatter.format(d.pricing.price / 100)}` : "",
            },
          ]}
          title={"Goods"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(GoodsList);
