import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import CloudDownload from "@material-ui/icons/CloudDownload";
import InsertChart from "@material-ui/icons/InsertChartTwoTone";
import People from "@material-ui/icons/PeopleTwoTone";
import Eye from "@material-ui/icons/RemoveRedEyeTwoTone";
import ActivityInfo from "components/ActivityInfo";
import FormCredit from "components/FormCredit";
// custom
import Page from "components/Page";
import Table from "components/TablePagination";
// constants
import { IMPERSONATE_REDIRECTION } from "config";
import { ACTIVITIES, CUSTOMER } from "constants/routes";
import { ExportToCsv } from "export-to-csv";
import { goBack, updateURLParameter } from "helpers";
import { setCookie } from "helpers/cookie";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withCookies } from "react-cookie";
// styles
import styles from "./styles";

class WrapperActivityPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    cookies: PropTypes.object,
    loading: PropTypes.bool,
    refresh: PropTypes.func,
    impersonate: PropTypes.func,

    // Activity
    updateActivity: PropTypes.func,
    deleteActivity: PropTypes.func,
    pricings: PropTypes.array,
    activity: PropTypes.object,
    refreshKey: PropTypes.number,

    // Credits
    credits: PropTypes.array,
    updateCredit: PropTypes.func,
    deleteCredit: PropTypes.func,
    createCredit: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const {
      updateActivity,
      deleteActivity,
      refresh,
      activity,
      pricings,
      history,
      credits,
      cookies,
      impersonate,
    } = this.props;

    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <ActivityInfo
            updateActivity={updateActivity}
            deleteActivity={deleteActivity}
            refresh={refresh}
            activity={activity}
            pricings={pricings}
            history={history}
          />
        );
      case 1:
        return (
          <div>
            <Typography
              display="block"
              variant="h6"
              style={{ marginBottom: 18 }}
            >
              <Chip
                label="Export"
                avatar={
                  <Avatar>
                    <CloudDownload />
                  </Avatar>
                }
                onClick={this.export.bind(this)}
              />
            </Typography>
            <Table
              data={credits}
              meta={[
                {
                  title: "Customer",
                  path: "customer",
                  numeric: false,
                  render: (c, d) => (
                    <React.Fragment>
                      <Typography display="block" variant="body2">
                        {d.customer && d.customer.fullName}
                      </Typography>
                      <Typography
                        display="block"
                        variant="caption"
                        color="textSecondary"
                      >
                        {d.customer && d.customer.email}
                      </Typography>
                    </React.Fragment>
                  ),
                },
                {
                  title: "Credit Type",
                  path: "good",
                  numeric: false,
                  render: (c, d) => (
                    <React.Fragment>
                      <Typography display="block" variant="body2">
                        {d.good && d.good.title}
                      </Typography>
                    </React.Fragment>
                  ),
                },
                {
                  title: "Credit Left",
                  path: "quantity",
                  numeric: false,
                  render: (c, d) => (
                    <React.Fragment>
                      <Typography display="block" variant="body2">
                        {d.quantity} Left
                      </Typography>
                    </React.Fragment>
                  ),
                },
                {
                  path: "id",
                  title: "Actions",
                  numeric: true,
                  width: 120,
                  render: (t, d) => (
                    <React.Fragment>
                      <Tooltip title="Go to Customer">
                        <IconButton
                          component="span"
                          style={{ padding: 4 }}
                          onClick={() =>
                            history.push(`${CUSTOMER}?id=${d.customer.id}`)
                          }
                        >
                          <People style={{ fontSize: 18 }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Impersonate">
                        <IconButton
                          component="span"
                          style={{ padding: 4 }}
                          onClick={async () => {
                            setCookie(
                              cookies,
                              "redirectAfterStopImpersonate",
                              window.location.href
                            );
                            await impersonate(d.customer.userUUID);
                            window.location.replace(IMPERSONATE_REDIRECTION);
                          }}
                        >
                          <Eye style={{ fontSize: 18 }} />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  ),
                },
              ]}
              onRowSelect={(e) =>
                this.setState({ selectedCredit: e, formCreditOpen: true })
              }
              title={"Credits"}
            />
          </div>
        );
      default:
        return (
          <Typography display="block" variant="title">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  export() {
    const { credits } = this.props;

    const data = [];
    for (const key in credits) {
      if (credits.hasOwnProperty(key)) {
        const e = credits[key];
        data.push({
          fullName: e.customer.fullName,
          email: e.customer.email,
          creditType: e.good && e.good.title,
          quantityLeft: e.quantity,
        });
      }
    }

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "My Awesome CSV",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  render() {
    const {
      classes,
      history,
      loading,
      activity,
      updateCredit,
      deleteCredit,
      refresh,
    } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="Activity"
        loadingMessage={"Loading Activity"}
        loading={loading}
        header={
          <Grid container style={{ padding: "10px 0px 0px 10px" }}>
            <Grid item>
              <Breadcrumbs arial-label="Breadcrumb" separator={"-"}>
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => goBack(history, ACTIVITIES)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                <Chip
                  label={`${activity ? activity.title : "..."}`}
                  className={classes.backChip}
                  variant="contained"
                  color="primary"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <InsertChart className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
            </Grid>
          </Grid>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
          >
            <Tab
              label="Activity"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Credits"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
        <FormCredit
          credit={this.state.selectedCredit}
          open={this.state.formCreditOpen}
          updateCredit={updateCredit}
          deleteCredit={deleteCredit}
          close={() => this.setState({ formCreditOpen: false })}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withCookies(withStyles(styles)(WrapperActivityPage));
