import { emphasize } from "@material-ui/core/styles/colorManipulator";

export default (theme) => ({
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  chip: {
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
  avatar: {
    background: "none",
    marginRight: -theme.spacing.unit * 1.5,
  },
  header: {
    padding: 10,
  },
  paper: {
    padding: 10,
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px #cfd8dc",
    borderRadius: 4,
    width: "calc(100% - 4px)",
  },
});
