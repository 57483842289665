import { Fab } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CouponsList from "components/CouponsList";
import FormCoupon from "components/FormCoupon";
import FormPricing from "components/FormPricing";
// reactor
import Page from "components/Page";
import PricingsList from "components/PricingsList";
import StatementsList from "components/StatementsList";
// constants
import { PRICING } from "constants/routes";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperBillingPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    pricings: PropTypes.array,
    statements: PropTypes.array,
    createPricing: PropTypes.func,
    deletePricing: PropTypes.func,
    updatePricing: PropTypes.func,
    createPricingCurrency: PropTypes.func,
    updatePricingCurrency: PropTypes.func,
    deletePricingCurrency: PropTypes.func,
    refresh: PropTypes.func,
    goods: PropTypes.array,
    coupons: PropTypes.array,
    createCoupon: PropTypes.func,
    getCouponById: PropTypes.func,
    deleteCoupon: PropTypes.func,
    updateCoupon: PropTypes.func,
    addCouponGood: PropTypes.func,
    deleteCouponGood: PropTypes.func,
    createMonthlyStatements: PropTypes.func,
    loading: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      formPricingOpen: false,
      formCouponOpen: false,
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const {
      pricings,
      createPricing,
      deletePricing,
      updatePricing,
      createPricingCurrency,
      updatePricingCurrency,
      deletePricingCurrency,
      createCoupon,
      deleteCoupon,
      updateCoupon,
      refresh,
      goods,
      coupons,
      getCouponById,
      addCouponGood,
      deleteCouponGood,
      history,
      statements,
      createMonthlyStatements,
    } = this.props;

    const {
      index,
      selectedCoupon,
      formCouponOpen,
      formPricingOpen,
      selectedPricing,
    } = this.state;

    switch (index) {
      case 0:
        return (
          <div>
            <PricingsList
              pricings={pricings}
              onSelect={(sp) => history.push(`${PRICING}?id=${sp.id}`)}
            />
            <FormPricing
              open={formPricingOpen}
              pricing={selectedPricing}
              close={() => {
                this.setState({
                  formPricingOpen: false,
                });
                setTimeout(
                  () =>
                    this.setState({
                      selectedPricing: undefined,
                    }),
                  200
                );
              }}
              createPricing={createPricing}
              deletePricing={deletePricing}
              updatePricing={updatePricing}
              createPricingCurrency={createPricingCurrency}
              updatePricingCurrency={updatePricingCurrency}
              deletePricingCurrency={deletePricingCurrency}
              refresh={refresh}
            />
          </div>
        );
      case 1:
        return (
          <div>
            <CouponsList coupons={coupons} history={history} />
            <FormCoupon
              open={formCouponOpen}
              coupon={selectedCoupon}
              goods={goods}
              close={() => {
                this.setState({
                  formCouponOpen: false,
                });
                setTimeout(
                  () =>
                    this.setState({
                      selectedCoupon: undefined,
                    }),
                  200
                );
              }}
              createCoupon={createCoupon}
              deleteCoupon={deleteCoupon}
              updateCoupon={updateCoupon}
              addCouponGood={addCouponGood}
              deleteCouponGood={deleteCouponGood}
              getCouponById={getCouponById}
              refresh={refresh}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => createMonthlyStatements().then(() => refresh())}
            >
              Create Monthly Statements
            </Button>
            <br />
            <br />
            <StatementsList
              statements={
                statements && statements.filter((s) => s.invoiceID === null)
              }
              history={history}
            />
          </div>
        );
      default:
        return (
          <Typography display="block" variant="title">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  getFab() {
    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formPricingOpen: true })}
          >
            <AddIcon />
          </Fab>
        );
      case 1:
        return (
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formCouponOpen: true })}
          >
            <AddIcon />
          </Fab>
        );
      case 3:
        return [];
      default:
        return [];
    }
  }

  render() {
    const { classes, history, loading } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="Billing"
        loading={loading}
        loadingMessage={"Loading Billing"}
        fab={this.getFab()}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Pricing"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Coupons"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            <Tab
              label="Statements"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperBillingPage);
