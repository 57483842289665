export default (theme) => ({
  container: {
    padding: 5,
  },
  root: {
    width: 30,
    textAlign: "center",
    color: "white",
    fontSize: 20,
    marginLeft: -1,
    marginTop: -2,
  },
  avatar: {
    background: theme.palette.primary.color[700],
    padding: 5,
    height: 25,
    width: 25,
    borderRadius: 25,
  },
  image: {
    width: 45,
    height: 45,
    borderRadius: 4,
    background: "white",
    border: "solid 1px rgba(100, 100, 100, 0.3)",
  },
});
