import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Chip from "@material-ui/core/Chip";

// styles
import styles from "./styles";

// helpers
import { copyTextToClipboard } from "helpers";

class TableCoupon extends Component {
  static propTypes = {
    classes: PropTypes.object,
    datum: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  render() {
    const { datum } = this.props;
    const { NotificationCenter } = this.context;

    return (
      <Chip
        label={datum.token}
        style={{
          color: "white",
          background: "#2196f3",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          copyTextToClipboard(datum.token);
          NotificationCenter.stack(
            {
              title: "Coupon copied to clipboard",
              timestamp: new Date().getTime(),
              info: true,
            },
            {
              cancel: {
                label: "Ok",
                level: "info",
              },
            }
          );
        }}
      />
    );
  }
}

export default withStyles(styles)(TableCoupon);
