export default (theme) => ({
  container: {
    height: "100%",
    width: "100%",
  },
  leftRuler: {
    position: "absolute",
    width: "2.78508568781843%",
    borderRight: "solid 1px #5AFFFE",
    height: "100%",
    zIndex: 999,
  },
  rightRuler: {
    position: "absolute",
    width: "2.78508568781843%",
    borderLeft: "solid 1px #5AFFFE",
    right: 0,
    height: "100%",
    zIndex: 999,
  },
  topRuler: {
    position: "absolute",
    height: "2.51395848246242%",
    borderBottom: "solid 1px #5AFFFE",
    width: "100%",
    zIndex: 999,
  },
  bottomRuler: {
    position: "absolute",
    height: "5.44058697208304%",
    borderTop: "solid 1px #5AFFFE",
    width: "100%",
    bottom: 0,
    zIndex: 999,
  },
  placeHolder: {
    background: "#DDDDDC",
    position: "absolute !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(0,0,0,0.3)",
  },
  draggingPlaceHolder: {
    background: "#bbdefb",
    position: "absolute !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(0,0,0,0.3)",
  },
  topLeftImage: {
    height: "10.0572655690766%",
    width: "45.5905511811024%",
    top: "2.51395848246242%",
    left: "2.78508568781843%",
  },
  topRightImage: {
    height: "10.0572655690766%",
    width: "45.5905511811024%",
    top: "2.51395848246242%",
    right: "2.78508568781843%",
  },
  leftImage: {
    height: "66.8754473872584%",
    width: "23.5294117647059%",
    top: "15.0139584824624%",
    left: "2.78508568781843%",
  },
  rightImage: {
    height: "66.8754473872584%",
    width: "23.5294117647059%",
    top: "15.0139584824624%",
    right: "2.78508568781843%",
  },
  ticketContainer: {
    position: "absolute",
    overflow: "hidden",
    borderRadius: "8px",
    height: "66.8754473872584%",
    width: "38.2352941176471%",
    top: "15.0139584824624%",
    right: "30.7262621584067%",
    background: "#ECEFF1",
  },
  coverImage: {
    height: "13.4645669291339%",
    width: "38.2352941176471%",
    top: "15.0139584824624%",
    right: "30.7262621584067%",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  centralImage: {
    height: "22.7272727272727%",
    width: "32.3529411764706%",
    top: "40.0139584824624%",
    right: "33.6674386289949%",
  },
  admitOne: {
    height: "10.2272727272727%",
    position: "absolute",
    width: "32.3529411764706%",
    top: "29.7866857551897%",
    right: "33.6674386289949%",
    textAlign: "left",
  },
  instructions: {
    height: "10.4685039370079%",
    position: "absolute",
    width: "94.1176470588235%",
    bottom: "5.44058697208304%",
    left: "2.78508568781843%",
    textAlign: "center",
  },
  qrCode: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "14.3163922691482%",
    width: "18.5270958777212%",
    top: "65.0139584824624%",
    left: "41.0203798054655%",
    background: "black",
    color: "white",
  },
});
