import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperActivityPage from "../component/WrapperActivityPage";

const mapStateToProps = (state) => ({
  pricings: state.pricings,
});

const mapDispatchToProps = (dispatch) => ({
  getActivityById: (...args) => dispatch(Actions.getActivityById(...args)),
  updateActivity: (...args) => dispatch(Actions.updateActivity(...args)),
  deleteActivity: (...args) => dispatch(Actions.deleteActivity(...args)),
  getPricingById: (...args) => dispatch(Actions.getPricingById(...args)),
  getPricings: (...args) => dispatch(Actions.getPricings(...args)),
  getCredits: (...args) => dispatch(Actions.getCredits(...args)),
  updateCredit: (...args) => dispatch(Actions.updateCredit(...args)),
  deleteCredit: (...args) => dispatch(Actions.deleteCredit(...args)),
  createCredit: (...args) => dispatch(Actions.createCredit(...args)),
  impersonate: (...args) => dispatch(Actions.impersonate(...args)),
});
class ActivityPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    impersonate: PropTypes.func,

    // Activity
    getActivityById: PropTypes.func,
    updateActivity: PropTypes.func,
    deleteActivity: PropTypes.func,
    getPricings: PropTypes.func,
    pricings: PropTypes.array,

    // Credits
    getCredits: PropTypes.func,
    updateCredit: PropTypes.func,
    deleteCredit: PropTypes.func,
    createCredit: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      activityID: urlParams.id,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const {
      getActivityById,
      getPricingById,
      getPricings,
      getCredits,
    } = this.props;
    const { activityID } = this.state;

    const resps = await Promise.all([
      getActivityById(activityID),
      getPricings(),
      getCredits(),
    ]);

    const activity = resps[0].payload;
    if (activity.pricingID) {
      const pricing = await getPricingById(activity.pricingID);
      activity.pricing = pricing.payload;
    }

    this.setState({
      activity,
      credits: resps[2].payload.filter((c) => (c.activityID = activity.id)),
      loading: false,
      refreshKey: Date.now(),
    });
  }

  render() {
    const {
      location,
      history,
      deleteActivity,
      updateActivity,
      pricings,
      updateCredit,
      deleteCredit,
      createCredit,
      impersonate,
    } = this.props;

    const { urlParams, loading, activity, refreshKey, credits } = this.state;

    return (
      <WrapperActivityPage
        credits={credits}
        history={history}
        impersonate={impersonate}
        location={location}
        urlParams={urlParams}
        activity={activity}
        refresh={this.refresh.bind(this)}
        loading={loading}
        updateActivity={updateActivity}
        deleteActivity={deleteActivity}
        updateCredit={updateCredit}
        deleteCredit={deleteCredit}
        createCredit={createCredit}
        pricings={pricings}
        refreshKey={refreshKey}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivityPage);
