// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperPaymentPage from "../component/WrapperPaymentPage";

const mapStateToProps = (state) => ({
  payments: state.payments,
  paymentsRecent: state.paymentsRecent,
  coupons: state.coupons,
  projects: state.projects,
  customers: state.customers,
  searchPresets: state.searchPresets,
  goods: state.goods,
});

const mapDispatchToProps = (dispatch) => ({
  getPayments: (...args) => dispatch(Actions.getPayments(...args)),
  getCustomers: (...args) => dispatch(Actions.getCustomers(...args)),
  getPaymentsRecent: () => dispatch(Actions.getPaymentsRecent()),
  getPaymentById: (...args) => dispatch(Actions.getPaymentById(...args)),
  createPayment: (...args) => dispatch(Actions.createPayment(...args)),
  deletePayment: (...args) => dispatch(Actions.deletePayment(...args)),
  updatePayment: (...args) => dispatch(Actions.updatePayment(...args)),
  impersonate: (...args) => dispatch(Actions.impersonate(...args)),
  getGoods: (...args) => dispatch(Actions.getGoods(...args)),
  getProjects: (...args) => dispatch(Actions.getProjects(...args)),
  getCoupons: (...args) => dispatch(Actions.getCoupons(...args)),
  getPaymentDashboard: (...args) =>
    dispatch(Actions.getPaymentDashboard(...args)),
  createSearchPreset: (...args) =>
    dispatch(Actions.createSearchPreset(...args)),
  deleteSearchPreset: (...args) =>
    dispatch(Actions.deleteSearchPreset(...args)),
  getSearchPresets: (...args) => dispatch(Actions.getSearchPresets(...args)),
});
class PaymentPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    coupons: PropTypes.array,
    payments: PropTypes.array,
    paymentsRecent: PropTypes.array,
    getPayments: PropTypes.func,
    getPaymentsRecent: PropTypes.func,
    getCustomers: PropTypes.func,
    getPaymentById: PropTypes.func,
    createPayment: PropTypes.func,
    deletePayment: PropTypes.func,
    updatePayment: PropTypes.func,
    impersonate: PropTypes.func,
    getGoods: PropTypes.func,
    getCoupons: PropTypes.func,
    getProjects: PropTypes.func,
    createSearchPreset: PropTypes.func,
    deleteSearchPreset: PropTypes.func,
    getSearchPresets: PropTypes.func,
    getPaymentDashboard: PropTypes.func,
    getCustomers: PropTypes.func,
    searchPresets: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const {
      location,
      payments,
      getGoods,
      getCoupons,
      getCustomers,
      getProjects,
    } = this.props;
    const urlParams = getJsonFromUrl(location);
    getGoods();
    getCustomers();
    getCoupons();
    getProjects();
    this.state = {
      urlParams,
      filters: urlParams.filters ? JSON.parse(urlParams.filters) : [],
      loading: payments.length === 0,
      dataLoading: false,
      tableLoading: false,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.location.search !== this.props.location.search) {
  //     const urlParams = getJsonFromUrl(nextProps.location);
  //     const filters = urlParams.filters ? JSON.parse(urlParams.filters) : [];
  //     this.setState({
  //       filters,
  //       tableLoading: true,
  //     }, () => nextProps.getPayments(filters).then(() => this.setState({ tableLoading: false })));
  //   }
  // }

  async refresh() {
    const { getPayments, getPaymentsRecent, getPaymentDashboard } = this.props;
    const { filters } = this.state;
    getPayments(filters);
    await getPaymentsRecent();

    this.setState({
      loading: false,
      dataLoading: false,
    });

    getPaymentDashboard().then((r) => {
      this.setState({
        dashboard: r.payload,
      });
    });
  }

  render() {
    const {
      payments,
      projects,
      getPaymentById,
      createPayment,
      deletePayment,
      updatePayment,
      paymentsRecent,
      getCustomers,
      impersonate,
      history,
      location,
      getGoods,
      getProjects,
      getCoupons,
      createSearchPreset,
      deleteSearchPreset,
      getSearchPresets,
      searchPresets,
      getPayments,
      coupons,
      goods,
      customers,
    } = this.props;

    const {
      urlParams,
      loading,
      dataLoading,
      tableLoading,
      filters,
      dashboard,
    } = this.state;

    return (
      <WrapperPaymentPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        payments={payments}
        getPaymentById={getPaymentById}
        createPayment={createPayment}
        getPayments={getPayments}
        coupons={coupons}
        goods={goods}
        projects={projects}
        customers={customers}
        deletePayment={deletePayment}
        updatePayment={updatePayment}
        dashboard={dashboard}
        paymentsRecent={paymentsRecent}
        getCustomers={getCustomers}
        getGoods={getGoods}
        searchPresets={searchPresets}
        getProjects={getProjects}
        impersonate={impersonate}
        urlParams={urlParams}
        history={history}
        tableLoading={tableLoading}
        getCoupons={getCoupons}
        createSearchPreset={createSearchPreset}
        deleteSearchPreset={deleteSearchPreset}
        getSearchPresets={getSearchPresets}
        location={location}
        filters={filters}
        dataLoading={dataLoading}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
