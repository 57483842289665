export default (theme) => ({
  container: {
    background: theme.palette.type === "dark" ? "#212121" : "#cfd8dc",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "calc(100% - 320px)",
  },
  drawer: {
    // height: 'calc(100vh - 47px)',
    // marginTop: 47,
    width: 320,
    flex: 1,
    position: "absolute",
    zIndex: 0,
    backgroundColor: theme.palette.background.paper,
    overflow: "scroll",
  },
  template: {
    background: "white",
    boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.3)",
    position: "relative",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px #cfd8dc",
    borderRadius: 4,
  },
});
