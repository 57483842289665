import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Dropzone from "react-dropzone";

// styles
import styles from "./styles";

class TicketTemplate extends Component {
  static propTypes = {
    classes: PropTypes.object,
    ticketTemplate: PropTypes.object,
    height: PropTypes.number,
    topLeftImage: PropTypes.bool,
    topRightImage: PropTypes.bool,
    rightImage: PropTypes.bool,
    leftImage: PropTypes.bool,
    coverImage: PropTypes.bool,
    centralImage: PropTypes.bool,
    instructions: PropTypes.string,
  };

  state = {};

  async onDrop(files, name) {
    const { api, onChange } = this.props;
    if (files.length > 1) {
      return;
    }
    const file = files[0];
    const resp = await api.uploadMedia(file);
    onChange(name, resp.payload);
  }

  render() {
    const {
      classes,
      height,
      topLeftImage,
      topRightImage,
      rightImage,
      leftImage,
      coverImage,
      centralImage,
      instructions,
      ticketTemplate,
    } = this.props;

    const {
      topLeftImageDragging,
      topRightImageDragging,
      leftImageDragging,
      rightImageDragging,
      centralImageDragging,
      coverImageDragging,
    } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.topRuler} />
        <div className={classes.leftRuler} />
        <div className={classes.rightRuler} />
        <div className={classes.bottomRuler} />
        {topLeftImage ? (
          <Dropzone
            onDrop={(files) => this.onDrop(files, "topLeftImage")}
            className={`${classes.topLeftImage} ${
              topLeftImageDragging
                ? classes.draggingPlaceHolder
                : classes.placeHolder
            }`}
            style={{
              backgroundImage: ticketTemplate
                ? `url(${ticketTemplate.topLeftImage})`
                : undefined,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
            onDragEnter={() => {
              this.setState({ topLeftImageDragging: true });
            }}
            onDragLeave={() => {
              this.setState({ topLeftImageDragging: false });
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography display="block" color="inherit">
                TopLeftImage
              </Typography>
              <Typography display="block" color="inherit" variant="caption">
                279x80
              </Typography>
            </div>
          </Dropzone>
        ) : (
          []
        )}
        {topRightImage ? (
          <Dropzone
            onDrop={(files) => this.onDrop(files, "topRightImage")}
            className={`${classes.topRightImage} ${
              topRightImageDragging
                ? classes.draggingPlaceHolder
                : classes.placeHolder
            }`}
            style={{
              backgroundImage: ticketTemplate
                ? `url(${ticketTemplate.topRightImage})`
                : undefined,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
            onDragEnter={() => {
              this.setState({ topRightImageDragging: true });
            }}
            onDragLeave={() => {
              this.setState({ topRightImageDragging: false });
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography display="block" color="inherit">
                TopRightImage
              </Typography>
              <Typography display="block" color="inherit" variant="caption">
                279x80
              </Typography>
            </div>
          </Dropzone>
        ) : (
          []
        )}
        {leftImage ? (
          <Dropzone
            onDrop={(files) => this.onDrop(files, "leftImage")}
            className={`${classes.leftImage} ${
              leftImageDragging
                ? classes.draggingPlaceHolder
                : classes.placeHolder
            }`}
            style={{
              backgroundImage: ticketTemplate
                ? `url(${ticketTemplate.leftImage})`
                : undefined,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
            onDragEnter={() => {
              this.setState({ leftImageDragging: true });
            }}
            onDragLeave={() => {
              this.setState({ leftImageDragging: false });
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography display="block" color="inherit">
                LeftImage
              </Typography>
              <Typography display="block" color="inherit" variant="caption">
                145x531
              </Typography>
            </div>
          </Dropzone>
        ) : (
          []
        )}
        {rightImage ? (
          <Dropzone
            onDrop={(files) => this.onDrop(files, "rightImage")}
            className={`${classes.rightImage} ${
              rightImageDragging
                ? classes.draggingPlaceHolder
                : classes.placeHolder
            }`}
            style={{
              backgroundImage: ticketTemplate
                ? `url(${ticketTemplate.rightImage})`
                : undefined,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
            onDragEnter={() => {
              this.setState({ rightImageDragging: true });
            }}
            onDragLeave={() => {
              this.setState({ rightImageDragging: false });
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography display="block" color="inherit">
                RightImage
              </Typography>
              <Typography display="block" color="inherit" variant="caption">
                145x531
              </Typography>
            </div>
          </Dropzone>
        ) : (
          []
        )}
        <div className={`${classes.ticketContainer}`} />
        {coverImage ? (
          <Dropzone
            onDrop={(files) => this.onDrop(files, "coverImage")}
            className={`${classes.coverImage} ${
              coverImageDragging
                ? classes.draggingPlaceHolder
                : classes.placeHolder
            }`}
            style={{
              backgroundImage: ticketTemplate
                ? `url(${ticketTemplate.coverImage})`
                : undefined,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
            onDragEnter={() => {
              this.setState({ coverImageDragging: true });
            }}
            onDragLeave={() => {
              this.setState({ coverImageDragging: false });
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography display="block" color="inherit">
                CoverImage
              </Typography>
              <Typography display="block" color="inherit" variant="caption">
                235x108
              </Typography>
            </div>
          </Dropzone>
        ) : (
          []
        )}

        <div className={`${classes.admitOne}`}>
          <div>
            <div
              style={{ fontSize: height * 0.0227272727272727, fontWeight: 600 }}
            >
              Admit One
            </div>
            <div style={{ fontSize: height * 0.018, fontWeight: 600 }}>
              Event Name
            </div>
            <div style={{ fontSize: height * 0.016 }}>Date & Time</div>
            <div style={{ fontSize: height * 0.016 }}>Address</div>
          </div>
        </div>
        {centralImage ? (
          <Dropzone
            onDrop={(files) => this.onDrop(files, "centralImage")}
            className={`${classes.centralImage} ${
              centralImageDragging
                ? classes.draggingPlaceHolder
                : classes.placeHolder
            }`}
            style={{
              backgroundImage: ticketTemplate
                ? `url(${ticketTemplate.centralImage})`
                : undefined,
              backgroundSize: "central",
              backgroundPosition: "center center",
            }}
            onDragEnter={() => {
              this.setState({ centralImageDragging: true });
            }}
            onDragLeave={() => {
              this.setState({ centralImageDragging: false });
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography display="block" color="inherit">
                CentralImage
              </Typography>
              <Typography display="block" color="inherit" variant="caption">
                199x181
              </Typography>
            </div>
          </Dropzone>
        ) : (
          []
        )}

        <div className={`${classes.qrCode}`}>
          <div style={{ textAlign: "center" }}>
            <Typography display="block" color="inherit">
              QR Code
            </Typography>
            <Typography display="block" color="inherit" variant="caption">
              Serial Number
            </Typography>
          </div>
        </div>
        <div className={`${classes.instructions}`}>
          <div style={{ fontSize: height * 0.012, opacity: 0.6 }}>
            {instructions}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TicketTemplate);
