export default (theme) => ({
  background: {
    background: theme.palette.background.default,
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  stripe: {
    padding: 15,
    border: "solid 1px #cfd8dc",
    borderRadius: 6,
    marginTop: 10,
  },
  paper: {
    maxWidth: 500,
    padding: 20,
    boxShadow: "0px 0px 80px 1px rgba(0,0,0,0.2)",
    margin: "auto",
    marginBottom: 40,
  },
});
