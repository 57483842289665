export default (theme) => ({
  container: {
    padding: 5,
  },
  h6: {
    fontSize: "1.3125rem",
    fontWeight: 500,
  },
  subheading: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  input: {
    padding: 0,
    opacity: 1,
  },
  disabled: {
    opacity: 1,
    color: theme.palette.type === "dark" ? "white" : "black",
  },
});
