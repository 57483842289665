import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperGoodPage from "../component/WrapperGoodPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  goods: state.goods,
  projects: state.projects,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: () => dispatch(Actions.getProjects()),
  getGoods: (...args) => dispatch(Actions.getGoods(...args)),
  getGoodById: (...args) => dispatch(Actions.getGoodById(...args)),
  createGood: (...args) => dispatch(Actions.createGood(...args)),
  deleteGood: (...args) => dispatch(Actions.deleteGood(...args)),
  updateGood: (...args) => dispatch(Actions.updateGood(...args)),
  fetchGood: (...args) => dispatch(Actions.fetchGood(...args)),
});
class GoodPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    projects: PropTypes.array,
    getProjects: PropTypes.func,
    getCategories: PropTypes.func,

    goods: PropTypes.array,
    categories: PropTypes.array,
    getGoods: PropTypes.func,
    getGoodById: PropTypes.func,
    createGood: PropTypes.func,
    deleteGood: PropTypes.func,
    updateGood: PropTypes.func,
    fetchGood: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, goods } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: goods.length === 0,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    const { getGoods, getProjects } = this.props;

    await Promise.all([getGoods(filters), getProjects()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      goods,
      projects,
      getGoodById,
      createGood,
      deleteGood,
      updateGood,
      fetchGood,
      history,
      categories,
      getGoods,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperGoodPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        goods={goods}
        projects={projects}
        getGoods={getGoods}
        categories={categories}
        getGoodById={getGoodById}
        createGood={createGood}
        deleteGood={deleteGood}
        updateGood={updateGood}
        fetchGood={fetchGood}
        history={history}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GoodPage);
