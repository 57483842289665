import React, { Component } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import OpenInbrowser from "@material-ui/icons/OpenInBrowser";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import GridListTileBar from "@material-ui/core/GridListTileBar";

// styles
import styles from "./styles";

const grid = 20;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid / 2,
  height: 250,
  width: "calc(100% - 20px)",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  width: "100%",
});

class EventMedia extends Component {
  static propTypes = {
    classes: PropTypes.object,
    event: PropTypes.object,
    createEventMedia: PropTypes.func,
    updateMedium: PropTypes.func,
    deleteMedium: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    UploadCenter: PropTypes.object,
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { event } = this.props;
    this.state = {
      pictures: event.pictures,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillMount() {
    const { UploadCenter } = this.context;
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      this.callback.bind(this),
      "image/jpeg, image/png"
    );
  }

  componentWillUnmount() {
    const { UploadCenter } = this.context;
    if (UploadCenter) {
      UploadCenter.disregard();
    }
  }

  async onDragEnd(result) {
    // dropped outside the list
    const { updateMedium, refresh } = this.props;
    const { pictures } = this.state;
    if (!result.destination) {
      return;
    }

    const m = reorder(
      this.state.pictures,
      result.source.index,
      result.destination.index
    );

    this.setState({
      pictures: m,
    });
    await updateMedium(pictures[result.source.index].id, {
      order: result.destination.index + 1,
    });
    refresh();
  }

  userDidDrop(files) {
    this.setState({
      files,
    });
  }

  callback() {
    const { NotificationCenter } = this.context;
    const { files } = this.state;
    NotificationCenter.sweetAlert(
      {
        title: "Success",
        success: true,
        body: `Your file${files.length > 1 ? "s" : ""} ${
          files.length > 1 ? "have" : "has"
        } been uploaded`,
        timestamp: new Date().getTime(),
      },
      {
        confirm: {
          label: "Ok",
          level: "success",
        },
      }
    );

    setTimeout(NotificationCenter.hide, 2000);
  }

  async upload() {
    const { createEventMedia, event, refresh } = this.props;
    const { files } = this.state;

    await new Promise(async (r) => {
      const funcs = [];
      for (const k in files) {
        if (files.hasOwnProperty(k)) {
          const file = files[k];
          funcs.push(createEventMedia(event.id, file));
        }
      }

      await Promise.all(funcs);
      r();
    });
    refresh();
  }

  deleteMedium(medium) {
    const { NotificationCenter } = this.context;
    this.setState({ medium });
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteMedium, refresh } = this.props;
    const { medium } = this.state;
    await deleteMedium(medium.id);
    refresh();
  }

  render() {
    const { classes } = this.props;
    const { UploadCenter } = this.context;
    const { pictures } = this.state;

    return (
      <div>
        <Typography display="block" variant="h6">
          Photos
        </Typography>
        <Typography display="block" variant="caption" color="textSecondary">
          Drop pictures to upload. Drag and drop to reorder.
        </Typography>
        <Chip
          label="Browse Files..."
          avatar={
            <Avatar style={{ background: "none" }}>
              <OpenInbrowser />
            </Avatar>
          }
          onClick={UploadCenter.openFileDialog}
        />
        <br />
        <br />
        <GridList cellHeight={180} className={classes.gridList}>
          <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {pictures.map((tile, index) => (
                    <Draggable
                      key={tile.id}
                      draggableId={tile.id}
                      index={index}
                    >
                      {(p, s) => (
                        <div
                          ref={p.innerRef}
                          {...p.draggableProps}
                          {...p.dragHandleProps}
                          style={getItemStyle(
                            s.isDragging,
                            p.draggableProps.style
                          )}
                        >
                          <GridListTile key={tile.img} style={{ height: 250 }}>
                            <img src={tile.url} alt={tile.name} />
                            <GridListTileBar
                              title={tile.name}
                              style={{
                                background:
                                  index === 0
                                    ? "rgba(33, 150, 243, 0.6)"
                                    : undefined,
                              }}
                              subtitle={index === 0 ? "Featured Image" : ""}
                              actionIcon={
                                <IconButton className={classes.icon}>
                                  <DeleteIcon
                                    style={{ color: "white" }}
                                    onClick={() => this.deleteMedium(tile)}
                                  />
                                </IconButton>
                              }
                            />
                          </GridListTile>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </GridList>
      </div>
    );
  }
}

export default withStyles(styles)(EventMedia);
