import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperMarketPage from "../component/WrapperMarketPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  markets: state.markets,
});

const mapDispatchToProps = (dispatch) => ({
  getMarkets: () => dispatch(Actions.getMarkets()),
  getMarketById: (...args) => dispatch(Actions.getMarketById(...args)),
  createMarket: (...args) => dispatch(Actions.createMarket(...args)),
  deleteMarket: (...args) => dispatch(Actions.deleteMarket(...args)),
  updateMarket: (...args) => dispatch(Actions.updateMarket(...args)),
});
class MarketPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    markets: PropTypes.array,
    getMarkets: PropTypes.func,
    getMarketById: PropTypes.func,
    createMarket: PropTypes.func,
    deleteMarket: PropTypes.func,
    updateMarket: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, markets } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: markets.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getMarkets } = this.props;
    await Promise.all([getMarkets()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      markets,
      getMarketById,
      createMarket,
      deleteMarket,
      updateMarket,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperMarketPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        markets={markets}
        getMarketById={getMarketById}
        createMarket={createMarket}
        deleteMarket={deleteMarket}
        updateMarket={updateMarket}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarketPage);
