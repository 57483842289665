import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperBillingPage from "../component/WrapperBillingPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  pricings: state.pricings,
  coupons: state.coupons,
  goods: state.goods,
  statements: state.statements,
});

const mapDispatchToProps = (dispatch) => ({
  createPricingCurrency: (...args) =>
    dispatch(Actions.createPricingCurrency(...args)),
  updatePricingCurrency: (...args) =>
    dispatch(Actions.updatePricingCurrency(...args)),
  deletePricingCurrency: (...args) =>
    dispatch(Actions.deletePricingCurrency(...args)),
  getPricings: (...args) => dispatch(Actions.getPricings(...args)),
  getPricingById: (...args) => dispatch(Actions.getPricingById(...args)),
  createPricing: (...args) => dispatch(Actions.createPricing(...args)),
  deletePricing: (...args) => dispatch(Actions.deletePricing(...args)),
  updatePricing: (...args) => dispatch(Actions.updatePricing(...args)),
  getCoupons: () => dispatch(Actions.getCoupons()),
  getGoods: () => dispatch(Actions.getGoods()),
  getStatements: () => dispatch(Actions.getStatements()),
  getCouponById: (...args) => dispatch(Actions.getCouponById(...args)),
  createCoupon: (...args) => dispatch(Actions.createCoupon(...args)),
  deleteCoupon: (...args) => dispatch(Actions.deleteCoupon(...args)),
  updateCoupon: (...args) => dispatch(Actions.updateCoupon(...args)),
  addCouponGood: (...args) => dispatch(Actions.addCouponGood(...args)),
  deleteCouponGood: (...args) => dispatch(Actions.deleteCouponGood(...args)),
  createMonthlyStatements: (...args) =>
    dispatch(Actions.createMonthlyStatements(...args)),
});
class BillingPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    pricings: PropTypes.array,
    getPricings: PropTypes.func,
    getPricingById: PropTypes.func,
    createPricing: PropTypes.func,
    deletePricing: PropTypes.func,
    updatePricing: PropTypes.func,
    createPricingCurrency: PropTypes.func,
    updatePricingCurrency: PropTypes.func,
    deletePricingCurrency: PropTypes.func,

    coupons: PropTypes.array,
    getCoupons: PropTypes.func,
    goods: PropTypes.array,
    getGoods: PropTypes.func,
    getCouponById: PropTypes.func,
    createCoupon: PropTypes.func,
    deleteCoupon: PropTypes.func,
    updateCoupon: PropTypes.func,
    addCouponGood: PropTypes.func,
    deleteCouponGood: PropTypes.func,

    createMonthlyStatements: PropTypes.func,
    getStatements: PropTypes.func,
    statements: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: true,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getPricings, getCoupons, getGoods, getStatements } = this.props;
    await Promise.all([
      getPricings(),
      getCoupons(),
      getGoods(),
      getStatements(),
    ]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      pricings,
      getPricingById,
      createPricing,
      deletePricing,
      updatePricing,
      createPricingCurrency,
      updatePricingCurrency,
      deletePricingCurrency,
      coupons,
      getCouponById,
      createCoupon,
      deleteCoupon,
      updateCoupon,
      addCouponGood,
      deleteCouponGood,
      createMonthlyStatements,
      statements,
      goods,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperBillingPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        pricings={pricings}
        getPricingById={getPricingById}
        createPricing={createPricing}
        deletePricing={deletePricing}
        updatePricing={updatePricing}
        createPricingCurrency={createPricingCurrency}
        updatePricingCurrency={updatePricingCurrency}
        deletePricingCurrency={deletePricingCurrency}
        urlParams={urlParams}
        coupons={coupons}
        statements={statements}
        goods={goods}
        getCouponById={getCouponById}
        createCoupon={createCoupon}
        deleteCoupon={deleteCoupon}
        updateCoupon={updateCoupon}
        addCouponGood={addCouponGood}
        deleteCouponGood={deleteCouponGood}
        createMonthlyStatements={createMonthlyStatements}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingPage);
