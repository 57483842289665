export default (theme) => ({
  item: {
    background: "rgba(155, 155, 155, 0.1)",
    border: "solid 1px rgba(155, 155, 155, 0.3)",
    padding: 5,
    borderRadius: 6,
    minHeight: 38,
    display: "flex",
    alignItems: "center",
  },
});
