import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import DateTime from "components/DateTime";
// helpers
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormPayment extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    goods: PropTypes.array,

    close: PropTypes.func,
    open: PropTypes.bool,

    payment: PropTypes.object,
    invoice: PropTypes.object,

    validateCart: PropTypes.func,
    createPayment: PropTypes.func,
    updatePayment: PropTypes.func,
    deletePayment: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      total: 0,
      invoiceID: "",
      goodID: "",
      transactionID: "",
      paymentDate: new Date().toISOString(),
      paymentMethod: "",
      message: "",
      notes: "",
      invoice: undefined,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.payment);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(payment) {
    this.setState({
      loading: false,
      name: payment ? payment.name : "",
      description: payment ? payment.description : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this payment, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async validateCart(goodID) {
    const { validateCart } = this.props;
    const r = await validateCart({
      goods: [{ id: goodID, quantity: 1 }],
    });
    if (r.success) {
      this.setState({ cart: r.payload });
    }
  }

  async confirmDelete() {
    const { deletePayment, close, refresh, payment } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deletePayment(payment.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Payment has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      createPayment,
      payment,
      customer,
      refresh,
      close,
      invoice,
    } = this.props;

    const {
      paymentDate,
      paymentMethod,
      transactionID,
      message,
      goodID,
      notes,
    } = this.state;

    const { NotificationCenter } = this.context;

    this.setState({ loading: true });
    const resp = await createPayment({
      paymentDate: moment(paymentDate).utc(),
      customerID: customer.id,
      paymentMethod,
      transactionID,
      cart: {
        goods: [{ id: goodID, quantity: 1 }],
      },
      message,
      notes,
      invoiceID: invoice?.id,
      paid: true,
    });

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: payment
          ? "Payment has been updated."
          : "Payment has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      payment,
      close,
      invoice,
      goods,
    } = this.props;

    const {
      paymentDate,
      paymentMethod,
      transactionID,
      goodID,
      cart,
      message,
      notes,
      loading,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {payment
                ? `${payment.name}`
                : `Create USD ${
                    invoice ? formattedPrice(invoice.total) : []
                  } Payment`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {payment
                  ? "To update the payment, fill the form below :"
                  : "To create a new payment, fill the form below :"}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateTime
                    noTime
                    className={classes.inputBase}
                    date={paymentDate}
                    onChange={(e) => {
                      this.handleChange("paymentDate")(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="payment-method">Good</InputLabel>
                    <Select
                      inputProps={{
                        name: "Payment Method",
                        id: "payment-method",
                      }}
                      value={goodID}
                      onChange={(e) => {
                        this.setState({ goodID: e.target.value });
                        this.validateCart(e.target.value);
                      }}
                    >
                      {goods &&
                        goods.map((g) => (
                          <MenuItem value={g.id}>{g.title}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="payment-method">
                      Payment Method
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "Payment Method",
                        id: "payment-method",
                      }}
                      value={paymentMethod}
                      onChange={this.handleChange("paymentMethod")}
                    >
                      <MenuItem value={"cash"}>Cash</MenuItem>
                      <MenuItem value={"check"}>Check</MenuItem>
                      <MenuItem value={"bank_transfer"}>Bank Transfer</MenuItem>
                      <MenuItem value={"stripe"}>Stripe</MenuItem>
                      <MenuItem value={"square"}>Square</MenuItem>
                      <MenuItem value={"wire"}>Wire</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="transactionID"
                    label="Transaction ID (check number, or other relevant info)"
                    className={classes.textField}
                    onChange={this.handleChange("transactionID")}
                    value={transactionID}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                      <TextField
                        id="message"
                        label="Message (Visible by Customer)"
                        className={classes.textField}
                        onChange={this.handleChange('message')}
                        multiline
                        value={message}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="notes"
                        label="Notes (Only visible by Admins)"
                        className={classes.textField}
                        onChange={this.handleChange('notes')}
                        multiline
                        value={notes}
                      />
                    </Grid> */}
                {cart ? (
                  <Grow in>
                    <Grid
                      item
                      xs={12}
                      style={{
                        background: "#2196f3",
                        color: "white",
                        padding: 12,
                        borderRadius: 8,
                      }}
                    >
                      <Grid container justify="space-between">
                        <Grid item xs={6}>
                          <Typography display="block" color="inherit">
                            SUBTOTAL
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            display="block"
                            color="inherit"
                            style={{ textAlign: "right" }}
                          >
                            {formattedPrice(cart.subTotal)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography display="block" color="inherit">
                            DISCOUNT
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Typography display="block" color="inherit">
                            {formattedPrice(cart.discount)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            display="block"
                            color="inherit"
                            variant="h6"
                          >
                            TOTAL
                          </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          <Typography
                            display="block"
                            color="inherit"
                            variant="h6"
                          >
                            {formattedPrice(cart.total)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grow>
                ) : (
                  []
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {payment && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {payment ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormPayment));
