import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const coupons = `/${service}/coupons`;
const couponGoods = `/${service}/couponGoods`;

export function getCoupons(filters) {
  const url = `${coupons}${filters ? `?filters=${filters}` : ""}`;
  return {
    method: GET,
    url,
  };
}

export function getCouponById(id) {
  const url = `${coupons}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateCoupon(id, body) {
  const url = `${coupons}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCoupon(id) {
  const url = `${coupons}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCoupon(body) {
  const url = `${coupons}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function addCouponGood(body) {
  const url = `${couponGoods}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function deleteCouponGood(id) {
  const url = `${couponGoods}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function updateCouponGood(id, body) {
  const url = `${couponGoods}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}
