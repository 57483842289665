// routes
import {
  GOODS,
  ACTIVITIES,
  CUSTOMERS,
  BILLING,
  PROJECTS,
  PAYMENTS,
  SETTINGS,
  CONTACTS,
  MARKETS,
  TICKETS,
  SUBSCRIPTIONS,
  EVENTS,
} from "constants/routes";

import Toys from "@material-ui/icons/ToysTwoTone";
import InsertChart from "@material-ui/icons/InsertChartTwoTone";
import SubscriptionsIcon from "@material-ui/icons/SubscriptionsTwoTone";
import AttachMoney from "@material-ui/icons/AttachMoneyTwoTone";
import Extension from "@material-ui/icons/ExtensionTwoTone";
import People from "@material-ui/icons/PeopleTwoTone";
import Contacts from "@material-ui/icons/ContactsTwoTone";
import Settings from "@material-ui/icons/SettingsTwoTone";
import Store from "@material-ui/icons/StoreTwoTone";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWalletTwoTone";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumberTwoTone";
import EventIcon from "@material-ui/icons/EventTwoTone";

export const menu = [
  {
    icon: AccountBalanceWallet,
    label: "Payments",
    path: PAYMENTS,
    divider: false,
  },
  {
    icon: People,
    label: "Customers",
    path: CUSTOMERS,
    divider: true,
  },
  {
    icon: Store,
    label: "Markets",
    path: MARKETS,
    divider: false,
  },
  {
    icon: ConfirmationNumberIcon,
    label: "Tickets",
    path: TICKETS,
    divider: false,
  },
  {
    icon: EventIcon,
    label: "Events",
    path: EVENTS,
    divider: false,
  },
  {
    icon: Toys,
    label: "Goods",
    path: GOODS,
    divider: false,
  },
  {
    icon: InsertChart,
    label: "Activities",
    path: ACTIVITIES,
    divider: false,
  },
  {
    icon: SubscriptionsIcon,
    label: "Subscriptions",
    path: SUBSCRIPTIONS,
    divider: true,
  },
  {
    icon: AttachMoney,
    label: "Billing",
    path: BILLING,
    divider: true,
  },
  {
    icon: Extension,
    label: "Projects",
    path: PROJECTS,
    divider: false,
  },
  {
    icon: Contacts,
    label: "Contacts",
    path: CONTACTS,
    divider: false,
  },
  {
    icon: Settings,
    label: "Settings",
    path: SETTINGS,
    divider: false,
  },
];
