import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const couponActivities = `/${service}/couponActivities`;

export function getCouponActivities() {
  const url = `${couponActivities}`;
  return {
    method: GET,
    url,
  };
}

export function getCouponActivityById(id) {
  const url = `${couponActivities}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCouponActivity(id, body) {
  const url = `${couponActivities}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCouponActivity(id) {
  const url = `${couponActivities}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCouponActivity(body) {
  const url = `${couponActivities}`;
  return {
    method: POST,
    url,
    body,
  };
}
