import React from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";

// open-source components
import Dropzone from "react-dropzone";

// styles
import styles from "./styles";

class Upload extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  static childContextTypes = {
    UploadCenter: PropTypes.object,
  };

  state = {
    dropzoneActive: false,
    upload: undefined,
    accept: "",
    uploadFinished: undefined,
    userDidDrop: undefined,
  };

  getChildContext() {
    return {
      UploadCenter: {
        register: this.register.bind(this),
        disregard: this.disregard.bind(this),
        openFileDialog: this.open.bind(this),
      },
    };
  }

  async onDrop(acceptedFiles) {
    const { NotificationCenter } = this.context;
    const { upload, uploadFinished, userDidDrop } = this.state;

    if (uploadFinished === undefined) {
      return;
    }

    this.setState({
      dropzoneActive: false,
    });

    if (userDidDrop) {
      await userDidDrop(acceptedFiles);
    }

    NotificationCenter.stack(
      {
        title: `Uploading file${acceptedFiles.length > 1 ? "s" : ""}...`,
        body: <LinearProgress />,
        timestamp: new Date().getTime(),
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
      }
    );

    await upload();
    NotificationCenter.hide();
    uploadFinished();
  }

  onDragEnter() {
    this.setState({
      dropzoneActive: true,
    });
  }

  onDragLeave() {
    this.setState({
      dropzoneActive: false,
    });
  }

  open() {
    if (this.Dropzone) {
      this.Dropzone.open();
    }
  }

  register(upload, userDidDrop, uploadFinished, accept) {
    this.setState({
      upload,
      uploadFinished,
      accept,
      userDidDrop,
    });
  }

  disregard() {
    this.setState({
      upload: undefined,
      uploadFinished: undefined,
      userDidDrop: undefined,
      accept: undefined,
    });
  }

  render() {
    const { classes, children } = this.props;
    const { dropzoneActive, upload, accept } = this.state;

    return (
      <div className={classes.container}>
        <Dropzone
          ref={(c) => {
            this.Dropzone = c;
          }}
          disableClick
          accept={accept}
          disabled={upload === undefined}
          // accept="image/jpeg, image/png"
          className={classes.dropZone}
          onDrop={this.onDrop.bind(this)}
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
        >
          {children}
          {dropzoneActive && (
            <div className={classes.activeOverlay}>
              <Zoom in>
                <CloudUpload
                  key="cloudUpload"
                  className={classes.cloudUpload}
                />
              </Zoom>
              <Zoom in>
                <Paper key="uploadMessage" className={classes.uploadMessage}>
                  Drop files to upload them instantly.
                </Paper>
              </Zoom>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default withStyles(styles)(Upload);
