import { GET_ORGANIZATIONS } from "constants/organizations";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_ORGANIZATIONS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
