import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Warning from "@material-ui/icons/Warning";
// custom
import AutoComplete from "components/AutoComplete";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormCoupon extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    goods: PropTypes.array,

    close: PropTypes.func,
    open: PropTypes.bool,

    coupon: PropTypes.object,
    getCouponById: PropTypes.func,
    createCoupon: PropTypes.func,
    updateCoupon: PropTypes.func,
    deleteCoupon: PropTypes.func,
    addCouponGood: PropTypes.func,
    deleteCouponGood: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      token: "",
      active: false,
      expirationDate: new Date(),
      maxCount: "",
      percent: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.coupon);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  init(coupon) {
    this.setState({
      loading: false,
      token: coupon ? coupon.token : "",
      active: coupon ? coupon.active : false,
      expirationDate: coupon
        ? moment(coupon.expirationDate).format("YYYY-MM-DD HH:mm:ss")
        : "",
      maxCount: coupon ? coupon.maxCount : "",
      percent: coupon ? coupon.percent : "",
      couponGoods: [],
    });

    if (coupon) {
      this.refreshGoods(coupon);
    }
  }

  async refreshGoods(coupon) {
    const { getCouponById } = this.props;
    this.setState({ goodsLoading: true });
    const resp = await getCouponById(coupon.id);
    this.setState({
      couponGoods: resp.payload.goods || [],
      goodsLoading: false,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this coupon, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteCoupon, close, refresh, coupon } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteCoupon(coupon.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Coupon has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const { updateCoupon, createCoupon, coupon, refresh, close } = this.props;

    const { token, active, percent } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (coupon) {
      resp = await updateCoupon(coupon.id, {
        token,
        active,
        percent: Number(percent),
      });
    } else {
      resp = await createCoupon({
        token,
        active,
        percent: Number(percent),
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: coupon ? "Coupon has been updated." : "Coupon has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async addCouponGood(good) {
    const { addCouponGood, coupon } = this.props;
    await addCouponGood({
      couponID: coupon.id,
      goodID: good.value.id,
      quantity: 1,
    });
    this.refreshGoods(coupon);
  }

  async deleteCouponGood(couponGood) {
    const { deleteCouponGood, coupon } = this.props;
    await deleteCouponGood(couponGood.relationID);
    this.refreshGoods(coupon);
  }

  render() {
    const { classes, fullScreen, open, coupon, close, goods } = this.props;

    const { token, active, percent, loading, couponGoods } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {coupon ? `${coupon.token}` : "Let's Create a New Coupon"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {coupon
                  ? "To update the coupon, fill the form below :"
                  : "To create a new coupon, fill the form below :"}
              </DialogContentText>
              {coupon ? (
                <Grid item xs={12} className={classes.default}>
                  {couponGoods.length === 0 ? (
                    <Grid container>
                      <Grid item xs={1}>
                        <Warning />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography display="block" variant="body2">
                          The coupon can be applied to all items in the store.
                          If you want to restrict it to specific items, specify
                          the list below:
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={1}>
                        <Warning />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography display="block" variant="body2">
                          Coupon applies to the following goods:
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <AutoComplete
                      placeHolder={"Add Good to Coupon"}
                      onSelect={this.addCouponGood.bind(this)}
                      suggestions={
                        goods &&
                        goods
                          .filter(
                            (g) =>
                              couponGoods.find((cg) => cg.id === g.id) ===
                              undefined
                          )
                          .map((good) => ({
                            value: good,
                            label: `${good.title}`,
                          }))
                      }
                    />
                  </Grid>
                  <Grid container spacing={2} style={{ marginTop: 10 }}>
                    {couponGoods.map((cg) => (
                      <Grid item>
                        <Chip
                          key={`couponGood_${cg.id}`}
                          label={cg.title}
                          onDelete={() => this.deleteCouponGood(cg)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ) : (
                <div />
              )}
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    id="token"
                    label="Token"
                    className={classes.textField}
                    value={token}
                    onChange={this.handleChange("token")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={active}
                        onChange={this.handleCheckboxChange("active")}
                        value="active"
                        color="primary"
                      />
                    }
                    label="Public"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="percent"
                    label="Discount (%)"
                    className={classes.textField}
                    value={percent}
                    onChange={this.handleChange("percent")}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {coupon && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {coupon ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormCoupon));
