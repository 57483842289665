import { PAYMENTS } from "constants/routes";

export function redirectAccordingToRole(accessLevel) {
  try {
    let redirectRoute = PAYMENTS;

    return redirectRoute;
  } catch (e) {
    console.log("redirectAccordingToRole error");
  }

  return PAYMENTS;
}
