import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const credits = `/${service}/credits`;

export function getCredits() {
  const url = `${credits}`;
  return {
    method: GET,
    url,
  };
}

export function getCreditById(id) {
  const url = `${credits}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateCredit(id, body) {
  const url = `${credits}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCredit(id) {
  const url = `${credits}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCredit(body) {
  const url = `${credits}`;
  return {
    method: POST,
    url,
    body,
  };
}
