import { networkAction } from "helpers/network/networkAction";

import {
  GET_SHELVE_GOODS,
  GET_SHELVE_GOOD_BY_ID,
  DELETE_SHELVE_GOOD,
  UPDATE_SHELVE_GOOD,
  CREATE_SHELVE_GOOD,
} from "constants/shelveGoods";

import * as Api from "api";

export const getShelveGoods = () => async (dispatch) =>
  networkAction(dispatch, GET_SHELVE_GOODS, Api.getShelveGoods, []);

export const getShelveGoodById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SHELVE_GOOD_BY_ID, Api.getShelveGoodById, [id]);

export const deleteShelveGood = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SHELVE_GOOD, Api.deleteShelveGood, [id]);

export const createShelveGood = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SHELVE_GOOD, Api.createShelveGood, [body]);

export const updateShelveGood = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SHELVE_GOOD, Api.updateShelveGood, [id, body]);
