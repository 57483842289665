import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import AttachMoney from "@material-ui/icons/AttachMoneyTwoTone";
import DataUsage from "@material-ui/icons/DataUsageTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Timelapse from "@material-ui/icons/TimelapseTwoTone";
// custom
import CouponRestriction from "components/CouponRestriction";
import PaymentsList from "components/PaymentsList";
// constants
import { COUPONS } from "constants/routes";
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CouponInfo extends Component {
  static propTypes = {
    classes: PropTypes.object,
    coupon: PropTypes.object,
    history: PropTypes.object,
    updateCoupon: PropTypes.func,
    customers: PropTypes.array,
    refresh: PropTypes.func,
    deleteCoupon: PropTypes.func,
    addCouponGood: PropTypes.func,
    deleteCouponGood: PropTypes.func,
    createCouponActivity: PropTypes.func,
    deleteCouponActivity: PropTypes.func,
    updateCouponActivity: PropTypes.func,
    updateCouponGood: PropTypes.func,
    activities: PropTypes.array,
    markets: PropTypes.array,
    goods: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { coupon } = this.props;
    this.state = {
      ...coupon,
      loading: false,
      savings: 30,
    };
  }

  getValue(range, data, dateKey, dataKey) {
    let value = 0;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        const now = moment(new Date()); // todays date
        const end = moment(element[dateKey]); // another date
        const duration = moment.duration(now.diff(end));
        const days = Math.round(duration.asDays());
        if (days <= range) {
          value += Number(element[dataKey]);
        }
      }
    }

    return value;
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async save(name, numeric) {
    const { updateCoupon, refresh, coupon } = this.props;

    if (coupon[name] === this.state[name]) return;

    if (name === "expirationDate") {
      this.state.expirationDate = moment(this.state.expirationDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    this.setState({
      loading: true,
    });

    await updateCoupon(coupon.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  deleteCoupon() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteCoupon, coupon, history } = this.props;
    await deleteCoupon(coupon.id);
    history.push(COUPONS);
  }

  render() {
    const {
      classes,
      customers,
      coupon,
      refresh,
      goods,
      addCouponGood,
      deleteCouponGood,
      history,
      createCouponActivity,
      deleteCouponActivity,
      updateCouponActivity,
      updateCouponGood,
      markets,
      activities,
    } = this.props;

    const {
      loading,
      token,
      active,
      percent,
      maxCount,
      expirationDate,
      useCount,
      marketID,
      valid,
      customerID,
      savings,
      autoApply,
      redeem,
    } = this.state;

    return (
      <Grid
        style={{ marginLeft: -8 }}
        container
        spacing={2}
        className={classes.container}
        alignItems="stretch"
      >
        <Grid item xs={12} md={4}>
          <Paper
            className="card"
            style={{ color: "#00bcd4", height: "calc(100% - 90px)" }}
          >
            <Paper className="cardHeader" style={{ background: "#00bcd4" }}>
              <DataUsage style={{ color: "white" }} />
            </Paper>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography display="block" variant="h6" color="inherit">
                  Usage
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item>
                <Typography display="block" variant="h3">
                  {useCount} Usage{useCount > 1 ? "s" : ""}
                </Typography>
                <Typography display="block" color="textSecondary">
                  {maxCount - useCount} uses remaining. Coupon will be unactive
                  when limit is reached.
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            className="card"
            style={{ color: "#3f51b5", height: "calc(100% - 90px)" }}
          >
            <Paper className="cardHeader" style={{ background: "#3f51b5" }}>
              <Timelapse style={{ color: "white" }} />
            </Paper>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography display="block" variant="h6" color="inherit">
                  Expiration
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Typography display="block" variant="h3">
                {moment(expirationDate).parseZone().fromNow()}
              </Typography>
              <Typography display="block" color="textSecondary">
                Coupon will be unactive after the expiration date.
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            className="card"
            style={{ color: "#673ab7", height: "calc(100% - 90px)" }}
          >
            <Paper className="cardHeader" style={{ background: "#673ab7" }}>
              <AttachMoney style={{ color: "white" }} />
            </Paper>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography display="block" variant="h6" color="inherit">
                  Savings
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Typography display="block" variant="h3">
                {coupon && coupon.payments
                  ? `USD ${formattedPrice(
                      this.getValue(
                        savings,
                        coupon.payments,
                        "paymentDate",
                        "total"
                      )
                    )}`
                  : "USD 0.00"}
              </Typography>
              <Typography display="block" color="textSecondary">
                Amount users have saved using this coupon.
              </Typography>
            </Grid>
            {/* <Grid container spacing={1} style={{ color: '#673ab7' }}>
              <Grid item>
                <Typography display="block" color={savings === 7 ? 'inherit' : 'textSecondary'} className="link" onClick={() => this.setState({ savings: 7 })}>
                  Last Week
                </Typography>
              </Grid>
              <Grid item>
                <Typography display="block" color={savings === 30 ? 'inherit' : 'textSecondary'} className="link" onClick={() => this.setState({ savings: 30 })}>
                  Last Month
                </Typography>
              </Grid>
              <Grid item>
                <Typography display="block" color={savings === 183 ? 'inherit' : 'textSecondary'} className="link" onClick={() => this.setState({ savings: 183 })}>
                  Last 6 Months
                </Typography>
              </Grid>
              <Grid item>
                <Typography display="block" color={savings === 365 ? 'inherit' : 'textSecondary'} className="link" onClick={() => this.setState({ savings: 365 })}>
                  Last Year
                </Typography>
              </Grid>
            </Grid> */}
          </Paper>
        </Grid>
        {/* <Grid item xs={12}>
          <Paper>
            <DatetimeGraph
              data={coupon && coupon.payments ? coupon.payments : []}
              height={200}
              dateKey={'paymentDate'}
              dataKey={'total'}
              color="#3f51b5"
              price
            />
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography display="block" variant="h6">
                  Token
                </Typography>
                <Typography display="block" color="textSecondary">
                  Customize the coupon's code to have something simple to
                  memorize.
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Token:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("token");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("token")}
                      className={classes.inputBase}
                      defaultValue={token}
                      onChange={this.handleChange("token")}
                    />
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography display="block" variant="h6">
                  Market, Active, Auto-Apply, Redeem & Expiration
                </Typography>
                <Typography display="block" color="textSecondary">
                  Enable / Disable the coupon. Select an Expiration Date.
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Market:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        inputProps={{
                          name: "Select the Market",
                          id: "market-simple",
                        }}
                        value={marketID}
                        onChange={(e) => {
                          this.state.marketID = e.target.value; //eslint-disable-line
                          this.save("marketID");
                        }}
                      >
                        {markets.map((m) => (
                          <MenuItem key={`markets_${m.id}`} value={m.id}>
                            {m.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Active:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Checkbox
                      checked={active}
                      onChange={(e) => {
                        this.setState({ active: e.target.checked }, () =>
                          this.save("active")
                        );
                      }}
                      color="primary"
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Auto Apply:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Checkbox
                      checked={autoApply}
                      onChange={(e) => {
                        this.setState({ autoApply: e.target.checked }, () =>
                          this.save("autoApply")
                        );
                      }}
                      color="primary"
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Redeem
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Checkbox
                      checked={redeem}
                      onChange={(e) => {
                        this.setState({ redeem: e.target.checked }, () =>
                          this.save("redeem")
                        );
                      }}
                      color="primary"
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Expiration Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("expirationDate");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("expirationDate")}
                      className={classes.inputBase}
                      value={moment(expirationDate).format(
                        "YYYY-MM-DD[T]HH:mm:ss"
                      )}
                      type="datetime-local"
                      onChange={this.handleChange("expirationDate")}
                    />
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography display="block" variant="h6">
                  Discount Percentage & Max Usage
                </Typography>
                <Typography display="block" color="textSecondary">
                  Configure the discount and limit usage
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Percent:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("percent", true);
                      }}
                      onBlur={() => this.save("percent", true)}
                      disabled={loading}
                      fullWidth
                      className={classes.inputBase}
                      defaultValue={percent}
                      onChange={this.handleChange("percent")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Max Usage:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("maxCount", true);
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("maxCount", true)}
                      className={classes.inputBase}
                      defaultValue={maxCount}
                      onChange={this.handleChange("maxCount")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Use Count:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("useCount", true);
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("useCount", true)}
                      className={classes.inputBase}
                      defaultValue={useCount}
                      onChange={this.handleChange("useCount")}
                    />
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography display="block" variant="h6">
                  Restrict to a Customer
                </Typography>
                <Typography display="block" color="textSecondary">
                  Assign the coupon to a customer. Only him will be able to use
                  it.
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div style={{ width: "100%" }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Restrict to Customer:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        inputProps={{
                          name: "Select the Customer",
                          id: "project-simple",
                        }}
                        value={customerID}
                        onChange={(e) => {
                          this.state.customerID = e.target.value; //eslint-disable-line
                          this.save("customerID");
                        }}
                      >
                        {customers.map((c) => (
                          <MenuItem key={`customer_${c.id}`} value={c.id}>
                            {c.fullName} - {c.email}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography
                      display="block"
                      className="link"
                      variant="caption"
                      color="primary"
                      onClick={() => {
                        this.state.customerID = 0; //eslint-disable-line
                        this.save("customerID");
                      }}
                    >
                      Clear
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography display="block" variant="h6">
                  Restrictions
                </Typography>
                <Typography display="block" color="textSecondary">
                  Restrict the use of the coupon to specific good or activity.
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div style={{ width: "100%" }}>
                <CouponRestriction
                  coupon={coupon}
                  refresh={refresh}
                  goods={goods}
                  addCouponGood={addCouponGood}
                  updateCouponGood={updateCouponGood}
                  deleteCouponGood={deleteCouponGood}
                  createCouponActivity={createCouponActivity}
                  deleteCouponActivity={deleteCouponActivity}
                  updateCouponActivity={updateCouponActivity}
                  activities={activities}
                />
              </div>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography display="block" variant="h6">
                  Payments
                </Typography>
                <Typography display="block" color="textSecondary">
                  View the list of payment that used this coupon.
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              {coupon.payments ? (
                <Grid item xs={12}>
                  <PaymentsList
                    history={history}
                    simple
                    noPaper
                    noTitle
                    payments={coupon.payments}
                  />
                </Grid>
              ) : (
                []
              )}
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography
                  display="block"
                  variant="h6"
                  style={{ color: "#f44336" }}
                >
                  Danger Zone
                </Typography>
                <Typography display="block" color="textSecondary">
                  Delete the coupon
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <Button
                style={{
                  color: "white",
                  background: "#f44336",
                }}
                onClick={this.deleteCoupon.bind(this)}
              >
                Delete Coupon
              </Button>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CouponInfo);
