import { networkAction } from "helpers/network/networkAction";

import {
  GET_CREDITS,
  GET_CREDIT_BY_ID,
  DELETE_CREDIT,
  UPDATE_CREDIT,
  CREATE_CREDIT,
} from "constants/credits";

import * as Api from "api";

export const getCredits = () => async (dispatch) =>
  networkAction(dispatch, GET_CREDITS, Api.getCredits, []);

export const getCreditById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CREDIT_BY_ID, Api.getCreditById, [id]);

export const deleteCredit = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CREDIT, Api.deleteCredit, [id]);

export const createCredit = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CREDIT, Api.createCredit, [body]);

export const updateCredit = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CREDIT, Api.updateCredit, [id, body]);
