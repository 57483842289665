import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const contacts = `/${service}/contacts`;

export function getContacts() {
  const url = `${contacts}`;
  return {
    method: GET,
    url,
  };
}

export function getContactById(id) {
  const url = `${contacts}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateContact(id, body) {
  const url = `${contacts}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteContact(id) {
  const url = `${contacts}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createContact(body) {
  const url = `${contacts}`;
  return {
    method: POST,
    url,
    body,
  };
}
