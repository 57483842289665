import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const eventPricings = `/${service}/eventPricings`;

export function getEventPricings() {
  const url = `${eventPricings}`;
  return {
    method: GET,
    url,
  };
}

export function getEventPricingById(id) {
  const url = `${eventPricings}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateEventPricing(id, body) {
  const url = `${eventPricings}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEventPricing(id) {
  const url = `${eventPricings}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEventPricing(body) {
  const url = `${eventPricings}`;
  return {
    method: POST,
    url,
    body,
  };
}
