import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Switch from "@material-ui/core/Switch";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";

// custom
import TicketTemplate from "components/TicketTemplate";

// styles
import styles from "./styles";

class TicketBuilder extends Component {
  static propTypes = {
    classes: PropTypes.object,
    ticketTemplate: PropTypes.object,
    api: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.container = React.createRef();
    const { ticketTemplate } = this.props;
    this.state = {
      templateHeight: 0,
      templateWidth: 0,
      topLeftImage: ticketTemplate.topLeftImage,
      topRightImage: ticketTemplate.topRightImage,
      rightImage: ticketTemplate.rightImage,
      leftImage: ticketTemplate.leftImage,
      coverImage: ticketTemplate.coverImage,
      centralImage: ticketTemplate.centralImage,
      instructions: ticketTemplate.instructions || "",
    };
    window.addEventListener("resize", this.setRatio.bind(this));
  }

  componentDidMount() {
    this.setRatio();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setRatio.bind(this));
  }

  setRatio() {
    if (!this.container.current) return;
    const width = this.container.current.clientWidth - 40;
    const height = this.container.current.clientHeight - 40;
    this.setState({
      templateHeight: height > width ? width * 1.2941 : height,
      templateWidth: height > width ? width : height / 1.2941,
    });
  }

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState(
      {
        [name]: checked ? true : "",
      },
      () => {
        if (!checked) {
          this.save(name);
        }
      }
    );
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { api, refresh, ticketTemplate } = this.props;

    if (ticketTemplate[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await api.updateTicketTemplate(ticketTemplate.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  deleteTicketTemplate() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { api, ticketTemplate, history, event } = this.props;
    await api.deleteTicketTemplate(ticketTemplate.id);
    history.push(`?id=${event.id}&index=1`);
    api.getTicketTemplates();
  }

  render() {
    const { classes, api, ticketTemplate } = this.props;
    const {
      templateWidth,
      templateHeight,
      topLeftImage,
      topRightImage,
      rightImage,
      leftImage,
      coverImage,
      centralImage,
      instructions,
    } = this.state;

    return (
      <div className={classes.container} ref={this.container}>
        <div
          className={classes.template}
          style={{
            height: templateHeight,
            width: templateWidth,
          }}
        >
          <TicketTemplate
            height={templateHeight}
            width={templateWidth}
            ticketTemplate={ticketTemplate}
            topLeftImage={topLeftImage}
            topRightImage={topRightImage}
            rightImage={rightImage}
            leftImage={leftImage}
            coverImage={coverImage}
            centralImage={centralImage}
            instructions={instructions}
            api={api}
            onChange={(name, value) => {
              this.handleChange(name)({ target: { value } });
              setTimeout(() => this.save(name), 100);
            }}
          />
        </div>
        <Drawer
          open
          anchor="right"
          variant={"persistent"}
          classes={{
            paper: classes.drawer,
          }}
        >
          <div style={{ padding: 20 }}>
            <Typography display="block" variant="h6">
              Edit Template
            </Typography>
            <Typography display="block" variant="caption">
              Enable/Disable image spots. Drag and drop images on areas.
            </Typography>
            <Chip
              onClick={() =>
                window.open(
                  "https://storage.googleapis.com/rma-la-test/bazaar/ea311e4b-8159-4bf6-ad37-c04fe190b138/template.ai",
                  "_blank"
                )
              }
              label={"Download Template (.ai)"}
            />
            <br />
            <Grid container alignItems="center">
              <Grid item xs={8}>
                <Typography display="block" variant="body2">
                  Top Left Image:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  checked={topLeftImage}
                  onChange={this.handleCheckboxChange("topLeftImage")}
                  color="primary"
                />
              </Grid>
              <Grid item xs={8}>
                <Typography display="block" variant="body2">
                  Top Right Image:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  checked={topRightImage}
                  onChange={this.handleCheckboxChange("topRightImage")}
                  color="primary"
                />
              </Grid>
              <Grid item xs={8}>
                <Typography display="block" variant="body2">
                  Left Image:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  checked={leftImage}
                  onChange={this.handleCheckboxChange("leftImage")}
                  color="primary"
                />
              </Grid>
              <Grid item xs={8}>
                <Typography display="block" variant="body2">
                  Right Image:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  checked={rightImage}
                  onChange={this.handleCheckboxChange("rightImage")}
                  color="primary"
                />
              </Grid>
              <Grid item xs={8}>
                <Typography display="block" variant="body2">
                  Cover Image:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  checked={coverImage}
                  onChange={this.handleCheckboxChange("coverImage")}
                  color="primary"
                />
              </Grid>
              <Grid item xs={8}>
                <Typography display="block" variant="body2">
                  Central Image:
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  checked={centralImage}
                  onChange={this.handleCheckboxChange("centralImage")}
                  color="primary"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography display="block" variant="body2">
                  Conditions & Instructions
                </Typography>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("instructions");
                  }}
                  fullWidth
                  onBlur={() => this.save("instructions")}
                  className={classes.inputBase}
                  defaultValue={instructions}
                  multiline
                  onChange={this.handleChange("instructions")}
                />
              </Grid>
              <Grid item>
                <Button
                  style={{
                    color: "white",
                    background: "#f44336",
                    marginTop: 10,
                  }}
                  onClick={this.deleteTicketTemplate.bind(this)}
                >
                  Delete Template
                </Button>
              </Grid>
            </Grid>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(TicketBuilder);
