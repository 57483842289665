import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// styles
import { formattedPrice } from "helpers/stripe";

// styles
import styles from "./styles";

class Good extends Component {
  static propTypes = {
    classes: PropTypes.object,
    good: PropTypes.object,
    image: PropTypes.bool,
    info: PropTypes.bool,
    price: PropTypes.bool,
    paper: PropTypes.bool,
    footer: PropTypes.node,
  };

  render() {
    const { good, image, info, price, paper, footer, discount } = this.props;

    const goodPrice =
      good.pricing && good.pricing.currencies
        ? good.pricing.currencies[0].price
        : undefined;

    const imageJSX = (
      <div
        style={{
          margin: "auto",
          height: 236,
          backgroundImage:
            good.pictures && good.pictures.length
              ? `url(${good.pictures[0].url})`
              : "url(https://storage.googleapis.com/college101-images/placeholder.png)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
    );

    const infoJSX = (
      <React.Fragment>
        <Typography display="block" variant="body2">
          {good.title}
        </Typography>
        <Typography display="block" variant="caption" color="textSecondary">
          {good.description}
        </Typography>
      </React.Fragment>
    );

    const priceJSX = goodPrice ? (
      <div>
        {discount ? (
          <Typography display="block" variant="h6">
            USD {formattedPrice(goodPrice * ((100 - discount) / 100))}
            <span
              style={{
                textDecoration: "line-through",
                fontSize: 12,
                marginLeft: 8,
              }}
            >
              USD {formattedPrice(goodPrice)}
            </span>
            <br />
            <Typography display="block" variant="caption" color="primary">
              USD{" "}
              {formattedPrice(goodPrice - goodPrice * ((100 - discount) / 100))}{" "}
              Savings
            </Typography>
          </Typography>
        ) : (
          <Typography display="block" variant="h6">
            USD {formattedPrice(goodPrice)}
          </Typography>
        )}
      </div>
    ) : (
      []
    );

    const content = (
      <Grid container style={{ position: "relative" }}>
        {image ? (
          <Grid item xs={12}>
            {imageJSX}
          </Grid>
        ) : (
          []
        )}
        <div style={{ padding: paper ? 8 : 0, width: "100%" }}>
          {info ? (
            <Grid item xs={12}>
              {infoJSX}
            </Grid>
          ) : (
            []
          )}
          {price ? (
            <Grid item xs={12}>
              {priceJSX}
            </Grid>
          ) : (
            []
          )}
          <Grid item xs={12}>
            {footer}
          </Grid>
        </div>
      </Grid>
    );

    if (paper) {
      return <Paper>{content}</Paper>;
    }

    return content;
  }
}

export default withStyles(styles)(Good);
