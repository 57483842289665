export default (theme) => ({
  item: {
    background: theme.palette.background.default,
    padding: 15,
    borderLeft: "solid 3px #2196f3",
  },
  inputActive: {
    background: theme.palette.background.paper,
    border: "solid 1px rgba(33, 150, 243, 0.4)",
    padding: "1px 2px 1px 2px",
    borderRadius: 4,
  },
  inputUnactive: {
    background: theme.palette.background.default,
    border: `solid 1px ${theme.palette.background.default}`,
    padding: "1px 2px 1px 2px",
    borderRadius: 4,
  },
  disabled: {
    color: theme.palette.type === "dark" ? "white" : "black",
  },
});
