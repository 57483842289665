import React, { Component } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// custom
import ShelveGood from "components/ShelveGood";

// styles
import styles from "./styles";

class ShelveGoods extends Component {
  static propTypes = {
    classes: PropTypes.object,
    goods: PropTypes.array,
    createShelveGood: PropTypes.func,
    deleteShelveGood: PropTypes.func,
    updateShelveGood: PropTypes.func,
    refresh: PropTypes.func,
    alt: PropTypes.bool,
    edit: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { goods } = this.props;
    this.state = {
      goods,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { updateShelveGood, refresh } = this.props;
    const goodToUpdate = this.state.goods[oldIndex];
    updateShelveGood(goodToUpdate.relationID, { order: newIndex + 1 }).then(
      refresh
    );
    this.setState(({ goods }) => ({
      goods: arrayMove(goods, oldIndex, newIndex),
    }));
  };

  render() {
    const { alt, deleteShelveGood, refresh, edit } = this.props;
    const { goods } = this.state;

    const SortableItem = SortableElement((g) => (
      <Grid item xs={12} md={6} lg={4}>
        <ShelveGood
          good={g.value}
          alt={alt}
          deleteShelveGood={deleteShelveGood}
          refresh={refresh}
          edit={edit}
        />
      </Grid>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <Grid container spacing={2} style={{ marginTop: 20 }}>
          {items &&
            items.map((value, index) => (
              <SortableItem
                disabled={!(alt && edit)}
                key={value.id}
                index={index}
                value={value}
              />
            ))}
        </Grid>
      );
    });

    return <SortableList axis="xy" items={goods} onSortEnd={this.onSortEnd} />;
  }
}

export default withStyles(styles)(ShelveGoods);
