import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AttachMoney from "@material-ui/icons/AttachMoneyTwoTone";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import MoreVert from "@material-ui/icons/MoreVert";
import People from "@material-ui/icons/PeopleTwoTone";
import Eye from "@material-ui/icons/RemoveRedEyeTwoTone";
import ShoppingCart from "@material-ui/icons/ShoppingCartTwoTone";
// constants
import { IMPERSONATE_REDIRECTION } from "config";
import { CART, CUSTOMER, INVOICE, PAYMENT } from "constants/routes";
import { buildForURL } from "helpers";
import { setCookie } from "helpers/cookie";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class PaymentActions extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    cookies: PropTypes.object,
    impersonate: PropTypes.func,
    d: PropTypes.object,
  };

  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, history, d, impersonate, cookies } = this.props;
    const { anchorEl } = this.state;

    return (
      <div>
        <IconButton
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {d.id ? (
            <MenuItem
              className={classes.menuItem}
              onClick={() =>
                history.push({
                  pathname: PAYMENT,
                  search: buildForURL({
                    id: d.id,
                    back: `${window.location.pathname}${window.location.search}`,
                  }),
                })
              }
            >
              <ListItemIcon className={classes.icon}>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText inset primary="Payment" />
            </MenuItem>
          ) : (
            []
          )}
          {d.invoiceID ? (
            <MenuItem
              className={classes.menuItem}
              onClick={() =>
                history.push({
                  pathname: INVOICE,
                  search: buildForURL({
                    id: d.invoiceID,
                    back: `${window.location.pathname}${window.location.search}`,
                  }),
                })
              }
            >
              <ListItemIcon className={classes.icon}>
                <InsertDriveFile />
              </ListItemIcon>
              <ListItemText inset primary="Invoice" />
            </MenuItem>
          ) : (
            []
          )}
          {d.customer && d.customer.id ? (
            <MenuItem
              className={classes.menuItem}
              onClick={() =>
                history.push({
                  pathname: CUSTOMER,
                  search: buildForURL({
                    id: d.customer.id,
                    back: `${window.location.pathname}${window.location.search}`,
                  }),
                })
              }
            >
              <ListItemIcon className={classes.icon}>
                <People />
              </ListItemIcon>
              <ListItemText inset primary="Customer" />
            </MenuItem>
          ) : (
            []
          )}
          {d.cart && d.cart.id ? (
            <MenuItem
              className={classes.menuItem}
              onClick={() =>
                history.push({
                  pathname: CART,
                  search: buildForURL({
                    id: d.cart.id,
                    back: `${window.location.pathname}${window.location.search}`,
                  }),
                })
              }
            >
              <ListItemIcon className={classes.icon}>
                <ShoppingCart />
              </ListItemIcon>
              <ListItemText inset primary="Cart" />
            </MenuItem>
          ) : (
            []
          )}
          <MenuItem
            className={classes.menuItem}
            onClick={async () => {
              setCookie(
                cookies,
                "redirectAfterStopImpersonate",
                window.location.href
              );
              await impersonate(d.userUUID);
              window.location.replace(IMPERSONATE_REDIRECTION);
            }}
          >
            <ListItemIcon className={classes.icon}>
              <Eye />
            </ListItemIcon>
            <ListItemText inset primary="Impersonate" />
          </MenuItem>
          <div style={{ padding: 8 }}>
            <Typography display="block" variant="caption" color="textSecondary">
              User UUID: {d.userUUID}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Transaction ID: {d.transactionID}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Payment ID: {d.id}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Customer ID: {d.customerID}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Cart ID: {d.cartID}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Invoice ID: {d.invoiceID}
            </Typography>
          </div>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(PaymentActions);
