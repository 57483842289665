import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const payments = `/${service}/payments`;

export function getPayments(filters) {
  const url = `${payments}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getPaymentDashboard() {
  const url = `${payments}/dashboard`;
  return {
    method: GET,
    url,
  };
}

export function getPaymentsRecent() {
  const url = `${payments}/recent`;
  return {
    method: GET,
    url,
  };
}

export function getPaymentById(id) {
  const url = `${payments}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updatePayment(id, body) {
  const url = `${payments}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function refundPayment(id, body) {
  const url = `${payments}/${id}/refund`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePayment(id) {
  const url = `${payments}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPayment(body) {
  const url = `${payments}`;
  return {
    method: POST,
    url,
    body,
  };
}
