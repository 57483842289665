import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import People from "@material-ui/icons/PeopleTwoTone";
import ShoppingCart from "@material-ui/icons/ShoppingCartTwoTone";
import CartGood from "components/CartGood";
// reactor
import Page from "components/Page";
import { CUSTOMER, CUSTOMERS, INVOICE } from "constants/routes";
import { goBack } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperCartPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    cart: PropTypes.object,
    loading: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const { cart, classes } = this.props;
    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <div style={{ margin: "auto" }}>
            {cart ? (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography display="block" variant="h6">
                    <ShoppingCart /> Cart
                  </Typography>
                  {cart.goods &&
                    cart.goods.map((cartGood) => (
                      <CartGood key={cartGood.id} cartGood={cartGood} />
                    ))}
                </Paper>
              </Grid>
            ) : (
              []
            )}
          </div>
        );
      default:
        return (
          <Typography display="block" variant="title">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  render() {
    const { classes, history, loading, cart } = this.props;

    return (
      <Page
        helmet="Cart"
        loadingMessage={"Loading Cart"}
        loading={loading}
        header={
          <Grid container style={{ padding: 10 }}>
            <Grid item>
              <Breadcrumbs arial-label="Breadcrumb" separator={"-"}>
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => goBack(history, CUSTOMERS)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                {cart && cart.customerID ? (
                  <Chip
                    label={`Customer ${cart.customerID}`}
                    className={classes.backChip}
                    onClick={() =>
                      history.push(`${CUSTOMER}?id=${cart.customerID}`)
                    }
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <People className={classes.icon} />
                      </Avatar>
                    }
                  />
                ) : (
                  []
                )}
                {cart && cart.invoiceID ? (
                  <Chip
                    label={`Invoice ${cart.invoiceID}`}
                    className={classes.chip}
                    onClick={() =>
                      history.push(`${INVOICE}?id=${cart.invoiceID}`)
                    }
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <InsertDriveFile className={classes.icon} />
                      </Avatar>
                    }
                  />
                ) : (
                  []
                )}
              </Breadcrumbs>
            </Grid>
          </Grid>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCartPage);
