import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const ticketTemplates = `/${service}/ticketTemplates`;

export function getTicketTemplates() {
  const url = `${ticketTemplates}`;
  return {
    method: GET,
    url,
  };
}

export function getTicketTemplateById(id) {
  const url = `${ticketTemplates}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTicketTemplate(id, body) {
  const url = `${ticketTemplates}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTicketTemplate(id) {
  const url = `${ticketTemplates}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTicketTemplate(body) {
  const url = `${ticketTemplates}`;
  return {
    method: POST,
    url,
    body,
  };
}
