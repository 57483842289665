import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// custom
import GoodMedia from "components/GoodMedia";
// constants
import { GOODS } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";






class GoodInfo extends Component {
  static propTypes = {
    classes: PropTypes.object,
    activities: PropTypes.array,
    good: PropTypes.object,
    updateGood: PropTypes.func,
    refresh: PropTypes.func,
    deleteGood: PropTypes.func,
    uploadGoodMedium: PropTypes.func,
    createGoodCategory: PropTypes.func,
    deleteGoodCategory: PropTypes.func,
    categories: PropTypes.array,
    projects: PropTypes.array,
    updateMedium: PropTypes.func,
    deleteMedium: PropTypes.func,
    pricings: PropTypes.array,
    history: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { good } = this.props;
    this.state = {
      ...good,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async save(name, numeric) {
    const { updateGood, refresh, good } = this.props;

    if (good[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateGood(good.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  async addCategory(e) {
    const { good, createGoodCategory, refresh } = this.props;
    await createGoodCategory({
      goodID: good.id,
      categoryID: Number(e.target.value),
    });
    refresh();
  }

  deleteGood() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteGood, good, history } = this.props;
    await deleteGood(good.id);
    history.push(GOODS);
  }

  render() {
    const {
      classes,
      pricings,
      history,
      activities,
      good,
      uploadGoodMedium,
      updateMedium,
      deleteMedium,
      projects,
      refresh,
    } = this.props;
    const {
      title,
      description,
      itemID,
      projectID,
      pricingID,
      postPurchaseInstructions,
      creditedQuantity,
      loading,
      creditedActivityID,
      salesPage,
      buttonLabel,
      externalURL,
    } = this.state;

    const formatter = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return (
      <Grid
        style={{ marginLeft: -8 }}
        container
        spacing={2}
        className={classes.container}
      >
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <GoodMedia
              good={good}
              deleteMedium={deleteMedium}
              updateMedium={updateMedium}
              uploadGoodMedium={uploadGoodMedium}
              refresh={refresh}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <Typography display="block" variant="h6">
              General Info
            </Typography>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Title:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("title");
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("title")}
                  className={classes.inputBase}
                  defaultValue={title}
                  onChange={this.handleChange("title")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Sales Page:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("salesPage");
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("salesPage")}
                  className={classes.inputBase}
                  defaultValue={salesPage}
                  onChange={this.handleChange("salesPage")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  External URL:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("externalURL");
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("externalURL")}
                  className={classes.inputBase}
                  defaultValue={externalURL}
                  onChange={this.handleChange("externalURL")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Button Label:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("buttonLabel");
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("buttonLabel")}
                  className={classes.inputBase}
                  defaultValue={buttonLabel}
                  onChange={this.handleChange("buttonLabel")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Description:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("description");
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("description")}
                  className={classes.inputBase}
                  defaultValue={description}
                  multiline
                  onChange={this.handleChange("description")}
                />
              </Grid>
            </Grid>
            {/* <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">Post Purchase Instructions:</Typography>
              </Grid>
              <Grid item xs={8}>
                <MarkdownEditor
                  value={postPurchaseInstructions}
                  onKeyPress={(e) => { if (e.key === 'Enter') this.save('postPurchaseInstructions'); }}
                  onBlur={() => this.save('postPurchaseInstructions')}
                  disabled={loading}
                  onChange={this.handleChange('postPurchaseInstructions')}
                />
              </Grid>
            </Grid> */}
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Public:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  checked={this.state.public}
                  onChange={(e) => {
                    this.setState({ public: e.target.checked }, () =>
                      this.save("public")
                    );
                  }}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: 20 }}>
            <Typography display="block" variant="h6">
              Projects
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Project:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="project-simple">
                    Select a Project
                  </InputLabel>
                  <Select
                    inputProps={{
                      name: "Select the Project",
                      id: "Project-simple",
                    }}
                    value={projectID}
                    onChange={(e) => {
                      this.setState({ projectID: e.target.value }, () =>
                        this.save("projectID")
                      );
                    }}
                  >
                    {projects.map((p) => (
                      <MenuItem key={`projects_${p.id}`} value={p.id}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Item ID:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("itemID", true);
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("itemID", true)}
                  className={classes.inputBase}
                  defaultValue={itemID}
                  onChange={this.handleChange("itemID")}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Standalone:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Checkbox
                  checked={this.state.standalone}
                  onChange={(e) => {
                    this.setState({ standalone: e.target.checked }, () =>
                      this.save("standalone")
                    );
                  }}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper className={classes.paper} style={{ marginTop: 20 }}>
            <Typography display="block" variant="h6">
              Credits
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Activity:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="Activity-simple">
                    Select an Activity
                  </InputLabel>
                  <Select
                    inputProps={{
                      name: "Select the Activity",
                      id: "Activity-simple",
                    }}
                    value={creditedActivityID}
                    onChange={(e) => {
                      this.setState(
                        { creditedActivityID: e.target.value },
                        () => this.save("creditedActivityID")
                      );
                    }}
                  >
                    {activities.map((a) => (
                      <MenuItem key={`activity_${a.id}`} value={a.id}>
                        {a.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Quantity:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <InputBase
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.save("creditedQuantity", true);
                  }}
                  disabled={loading}
                  fullWidth
                  onBlur={() => this.save("creditedQuantity", true)}
                  className={classes.inputBase}
                  defaultValue={creditedQuantity}
                  onChange={this.handleChange("creditedQuantity")}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper} style={{ marginTop: 20 }}>
            <Typography display="block" variant="h6">
              Pricings Plans
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Typography display="block" variant="body2">
                  Pricing:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="project-simple">
                    Select a Pricing
                  </InputLabel>
                  <Select
                    inputProps={{
                      name: "Select the Pricings",
                      id: "Pricings-simple",
                    }}
                    value={pricingID}
                    onChange={(e) => {
                      this.setState({ pricingID: e.target.value }, () =>
                        this.save("pricingID")
                      );
                    }}
                  >
                    {pricings.map((p) => (
                      <MenuItem key={`pricings_${p.id}`} value={p.id}>
                        {`${p.title} (${formatter.format(p.price / 100)})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <div style={{ marginTop: 20 }}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div>
                  <Typography
                    display="block"
                    variant="h6"
                    style={{ color: "#f44336" }}
                  >
                    Danger Zone
                  </Typography>
                  <Typography display="block" color="textSecondary">
                    Delete the Good.
                  </Typography>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.details}>
                <Button
                  style={{
                    color: "white",
                    background: "#f44336",
                  }}
                  onClick={this.deleteGood.bind(this)}
                >
                  Delete Good
                </Button>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(GoodInfo);
