import { checkAccess } from "helpers/auth";
import { SIGNIN } from "constants/routes";
import SigninPage from "./container/SigninPage";

export default function getSigninRoute(userAuth) {
  const requireAuth = {};
  return {
    path: SIGNIN,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: false,
    withAppBar: false,
    component: SigninPage,
  };
}
