import { CREDITCARD } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import CreditcardPage from "./container/CreditcardPage";

export default function getSettingsRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: CREDITCARD,
    withSidebar: true,
    withAppBar: true,
    component: CreditcardPage,
  };
}
