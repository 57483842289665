import { networkAction } from "helpers/network/networkAction";

import {
  GET_STATEMENTS,
  GET_STATEMENT_BY_ID,
  DELETE_STATEMENT,
  UPDATE_STATEMENT,
  CREATE_STATEMENT,
  CREATE_MONTHLY_PAYMENTS,
} from "constants/statements";

import * as Api from "api";

export const getStatements = () => async (dispatch) =>
  networkAction(dispatch, GET_STATEMENTS, Api.getStatements, []);

export const createMonthlyStatements = () => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_MONTHLY_PAYMENTS,
    Api.createMonthlyStatements,
    []
  );

export const getStatementById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_STATEMENT_BY_ID, Api.getStatementById, [id]);

export const deleteStatement = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_STATEMENT, Api.deleteStatement, [id]);

export const createStatement = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_STATEMENT, Api.createStatement, [body]);

export const updateStatement = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_STATEMENT, Api.updateStatement, [id, body]);
