import { GET_BANNERS } from "constants/banners";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_BANNERS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
