import React, { Component } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Add from "@material-ui/icons/Add";

// custom
import Shelve from "components/Shelve";

// styles
import styles from "./styles";

const SortableItem = SortableElement((g) => (
  <Grid item xs={12}>
    <Shelve {...g.value} />
  </Grid>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <Grid container spacing={2}>
      {items &&
        items.map((value, index) => (
          <SortableItem
            disabled={!(value.alt && value.edit)}
            key={value.shelve.id}
            index={index}
            value={value}
          />
        ))}
    </Grid>
  );
});

class Shelves extends Component {
  static propTypes = {
    shelves: PropTypes.object,
    refresh: PropTypes.func,
    edit: PropTypes.bool,
    deleteShelve: PropTypes.func,
    createShelve: PropTypes.func,
    updateShelve: PropTypes.func,
    createShelveGood: PropTypes.func,
    deleteShelveGood: PropTypes.func,
    updateShelveGood: PropTypes.func,
    goods: PropTypes.array,
    aisleID: PropTypes.number,
    alt: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { shelves } = this.props;
    this.state = {
      shelves,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { updateShelve, refresh } = this.props;
    const shelfToUpdate = this.state.shelves[oldIndex];
    updateShelve(shelfToUpdate.id, { order: newIndex + 1 }).then(refresh);
    this.setState(({ shelves }) => ({
      shelves: arrayMove(shelves, oldIndex, newIndex),
    }));
  };

  render() {
    const {
      aisleID,
      createShelve,
      deleteShelve,
      updateShelve,
      createShelveGood,
      deleteShelveGood,
      updateShelveGood,
      refresh,
      edit,
      goods,
      alt,
    } = this.props;

    const { shelves } = this.state;

    const items = [];

    for (const key in shelves) {
      if (shelves.hasOwnProperty(key)) {
        const shelve = shelves[key];
        items.push({
          shelve,
          deleteShelve,
          updateShelve,
          createShelveGood,
          deleteShelveGood,
          updateShelveGood,
          refresh,
          edit,
          alt,
          goods,
        });
      }
    }

    return (
      <div>
        <Grid container spacing={2}>
          {edit ? (
            <Grid item>
              <Chip
                avatar={
                  <Avatar style={{ background: "none" }}>
                    <Add />
                  </Avatar>
                }
                label="Shelf"
                variant="outlined"
                onClick={async () => {
                  await createShelve({
                    aisleID,
                    order: 0,
                  }).then(refresh);
                }}
              />
            </Grid>
          ) : (
            []
          )}
        </Grid>
        <SortableList
          axis="y"
          items={items}
          onSortEnd={this.onSortEnd.bind(this)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Shelves);
