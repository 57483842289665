import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// custom
import Table from "components/TablePagination";

// styles
import styles from "./styles";

class SettingsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    settings: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedSetting) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedSetting);
    }
  }

  render() {
    const { settings } = this.props;

    return (
      <Table
        data={settings}
        meta={[
          {
            path: "logoLarge",
            numeric: false,
            width: 20,
            render: (t) => <img style={{ height: 20 }} src={t} alt="company" />,
          },
          {
            path: "companyName",
            title: "Company",
            numeric: false,
          },
          {
            path: "taxNumber",
            title: "Tax Number",
            numeric: false,
          },
          {
            path: "city",
            title: "city",
            numeric: false,
            transform: (t, d) =>
              `${d.address1} ${d.address2} ${d.city} ${d.state}`,
          },
        ]}
        title={"Settings"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(SettingsList);
