import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const shelves = `/${service}/shelves`;

export function getShelves() {
  const url = `${shelves}`;
  return {
    method: GET,
    url,
  };
}

export function getShelveById(id) {
  const url = `${shelves}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateShelve(id, body) {
  const url = `${shelves}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteShelve(id) {
  const url = `${shelves}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createShelve(body) {
  const url = `${shelves}`;
  return {
    method: POST,
    url,
    body,
  };
}
