import { GET_PAYMENTS_RECENT } from "constants/payments";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_PAYMENTS_RECENT}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
