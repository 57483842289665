import { networkAction } from "helpers/network/networkAction";

import {
  GET_SHELVES,
  GET_SHELVE_BY_ID,
  DELETE_SHELVE,
  UPDATE_SHELVE,
  CREATE_SHELVE,
} from "constants/shelves";

import * as Api from "api";

export const getShelves = () => async (dispatch) =>
  networkAction(dispatch, GET_SHELVES, Api.getShelves, []);

export const getShelveById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SHELVE_BY_ID, Api.getShelveById, [id]);

export const deleteShelve = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SHELVE, Api.deleteShelve, [id]);

export const createShelve = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SHELVE, Api.createShelve, [body]);

export const updateShelve = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SHELVE, Api.updateShelve, [id, body]);
