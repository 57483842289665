import { networkAction } from "helpers/network/networkAction";

import {
  GET_CONTACTS,
  GET_CONTACT_BY_ID,
  DELETE_CONTACT,
  UPDATE_CONTACT,
  CREATE_CONTACT,
} from "constants/contacts";

import * as Api from "api";

export const getContacts = () => async (dispatch) =>
  networkAction(dispatch, GET_CONTACTS, Api.getContacts, []);

export const getContactById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CONTACT_BY_ID, Api.getContactById, [id]);

export const deleteContact = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CONTACT, Api.deleteContact, [id]);

export const createContact = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CONTACT, Api.createContact, [body]);

export const updateContact = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CONTACT, Api.updateContact, [id, body]);
