import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
// custom
import EventInfo from "components/EventInfo";
// reactor
import Page from "components/Page";
import TicketBuilder from "components/TicketBuilder";
// constants
import { EVENTS } from "constants/routes";
import { goBack, updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperEventPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    ticketTemplate: PropTypes.object,
    event: PropTypes.object,
    api: PropTypes.object,
    refresh: PropTypes.func,
    pricings: PropTypes.array,
    refreshKey: PropTypes.number,
    ticketTemplates: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      anchorEl: null,
    };
  }

  getStepContent() {
    const {
      api,
      refresh,
      refreshKey,
      event,
      history,
      ticketTemplate,
      ticketTemplates,
      pricings,
    } = this.props;
    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <EventInfo
            api={api}
            ticketTemplates={ticketTemplates}
            key={refreshKey}
            refresh={refresh}
            event={event}
            pricings={pricings}
            history={history}
          />
        );
      case 1:
        if (ticketTemplate) {
          return (
            <TicketBuilder
              ticketTemplate={ticketTemplate}
              refresh={refresh}
              api={api}
              key={refreshKey}
              history={history}
              event={event}
            />
          );
        }
        return (
          <div
            style={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography display="block" variant="title">
                No Template Selected
              </Typography>
              <Typography display="block" variant="caption">
                Select a template to edit from the top right chip.
              </Typography>
            </div>
          </div>
        );
      default:
        return (
          <Typography display="block" variant="title">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      history,
      event,
      ticketTemplates,
      ticketTemplate,
      api,
      loading,
    } = this.props;

    const { index, anchorEl } = this.state;

    return (
      <Page
        helmet="Event"
        loadingMessage={"Loading Event"}
        loading={loading}
        noPadding={index === 1}
        header={
          <Grid
            container
            style={{ padding: "10px 10px 0px 10px", width: "100%" }}
            justify="space-between"
          >
            <Grid item>
              <Breadcrumbs
                arial-label="Breadcrumb"
                separator={"-"}
                style={{
                  color: "white",
                }}
              >
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => goBack(history, EVENTS)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
              <Typography display="block" variant="h6">
                {event ? event.title : "..."}
              </Typography>
            </Grid>
            {index === 1 ? (
              <Grid item>
                <Chip
                  label={
                    ticketTemplate
                      ? `Template #${ticketTemplate.id}`
                      : "Select Template"
                  }
                  deleteIcon={<ArrowDropDown />}
                  onDelete={this.handleClick.bind(this)}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem
                    onClick={async () => {
                      this.handleClose();
                      const resp = await api.createTicketTemplate({});
                      history.push(
                        `?id=${event.id}&templateId=${resp.payload.id}&index=${index}`
                      );
                    }}
                    divider
                  >
                    New Template
                  </MenuItem>
                  {ticketTemplates &&
                    ticketTemplates.map((tt) => (
                      <MenuItem
                        onClick={() => {
                          this.handleClose();
                          history.push(
                            `?id=${event.id}&templateId=${tt.id}&index=${index}`
                          );
                        }}
                        key={tt.id}
                      >
                        Template #{tt.id}
                      </MenuItem>
                    ))}
                </Menu>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
          >
            <Tab
              label="Event"
              classes={{
                root: "tabRoot",
                selected: "tabSelected",
              }}
            />
            <Tab
              label="E-Ticket Templates"
              classes={{
                root: "tabRoot",
                selected: "tabSelected",
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperEventPage);
