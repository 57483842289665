export default (theme) => ({
  container: {
    padding: 5,
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px #cfd8dc",
    borderRadius: 4,
  },
});
