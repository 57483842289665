import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";

import { Textfit } from "react-textfit";
import { PLACEHOLDER } from "config";

// styles
import styles from "./styles";

class Banner extends Component {
  static propTypes = {
    classes: PropTypes.object,
    align: PropTypes.string,
    content: PropTypes.string,
    name: PropTypes.string,
    imageURL: PropTypes.string,
  };

  constructor(...args) {
    super(...args);
    setTimeout(this.forceUpdate.bind(this), 100);
    this.state = {
      hover: false,
    };
  }

  render() {
    const { classes, imageURL, name, content, align, alt } = this.props;

    const { hover } = this.state;

    return (
      <Textfit>
        <Grid
          container
          className={classes.banner}
          style={{
            backgroundImage: `url(${imageURL || PLACEHOLDER})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            color: "white",
            height: 250,
            textShadow: "0px 0px 20px rgba(0,0,0,0.5)",
            textAlign: "center",
          }}
          justify="center"
          alignItems="center"
          spacing={2}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
        >
          <Grid item xs={6}>
            <Grow in>
              <div>
                {align === "left" ? name : []}
                {align === "left" ? (
                  <Typography display="block" color="inherit">
                    {content}
                  </Typography>
                ) : (
                  []
                )}
              </div>
            </Grow>
          </Grid>
          <Grid item xs={6}>
            <Grow in>
              <div>
                {align === "right" ? name : []}
                {align === "right" ? (
                  <Typography display="block" color="inherit">
                    {content}
                  </Typography>
                ) : (
                  []
                )}
              </div>
            </Grow>
          </Grid>
        </Grid>
      </Textfit>
    );
  }
}

export default withStyles(styles)(Banner);
