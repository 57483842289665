import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperContactPage from "../component/WrapperContactPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  contacts: state.contacts,
});

const mapDispatchToProps = (dispatch) => ({
  getContacts: () => dispatch(Actions.getContacts()),
  getContactById: (...args) => dispatch(Actions.getContactById(...args)),
  createContact: (...args) => dispatch(Actions.createContact(...args)),
  deleteContact: (...args) => dispatch(Actions.deleteContact(...args)),
  updateContact: (...args) => dispatch(Actions.updateContact(...args)),
});
class ContactPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    contacts: PropTypes.array,
    getContacts: PropTypes.func,
    getContactById: PropTypes.func,
    createContact: PropTypes.func,
    deleteContact: PropTypes.func,
    updateContact: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, contacts } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: contacts.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getContacts } = this.props;
    await Promise.all([getContacts()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      contacts,
      getContactById,
      createContact,
      deleteContact,
      updateContact,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperContactPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        contacts={contacts}
        getContactById={getContactById}
        createContact={createContact}
        deleteContact={deleteContact}
        updateContact={updateContact}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
