import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperCouponPage from "../component/WrapperCouponPage";

const mapStateToProps = (state) => ({
  goods: state.goods,
  customers: state.customers,
  activities: state.activities,
  markets: state.markets,
});

const mapDispatchToProps = (dispatch) => ({
  getCouponById: (...args) => dispatch(Actions.getCouponById(...args)),
  updateCoupon: (...args) => dispatch(Actions.updateCoupon(...args)),
  deleteCoupon: (...args) => dispatch(Actions.deleteCoupon(...args)),
  addCouponGood: (...args) => dispatch(Actions.addCouponGood(...args)),
  deleteCouponGood: (...args) => dispatch(Actions.deleteCouponGood(...args)),
  getGoods: (...args) => dispatch(Actions.getGoods(...args)),
  getGoodById: (...args) => dispatch(Actions.getGoodById(...args)),
  getCustomers: (...args) => dispatch(Actions.getCustomers(...args)),
  updateCouponGood: (...args) => dispatch(Actions.updateCouponGood(...args)),
  createCouponActivity: (...args) =>
    dispatch(Actions.createCouponActivity(...args)),
  deleteCouponActivity: (...args) =>
    dispatch(Actions.deleteCouponActivity(...args)),
  updateCouponActivity: (...args) =>
    dispatch(Actions.updateCouponActivity(...args)),
  getActivities: (...args) => dispatch(Actions.getActivities(...args)),
  getMarkets: (...args) => dispatch(Actions.getMarkets(...args)),
});

class CouponPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    updateCoupon: PropTypes.func,
    deleteCoupon: PropTypes.func,
    getCouponById: PropTypes.func,
    addCouponGood: PropTypes.func,
    deleteCouponGood: PropTypes.func,
    getGoods: PropTypes.func,
    getGoodById: PropTypes.func,
    getCustomers: PropTypes.func,
    createCouponActivity: PropTypes.func,
    updateCouponGood: PropTypes.func,
    deleteCouponActivity: PropTypes.func,
    updateCouponActivity: PropTypes.func,
    getMarkets: PropTypes.func,
    goods: PropTypes.array,
    customers: PropTypes.array,
    activities: PropTypes.array,
    markets: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      couponID: urlParams.id,
      loading: true,
      coupon: {},
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { couponID } = this.state;
    const {
      getCouponById,
      getGoods,
      getGoodById,
      getCustomers,
      getActivities,
      getMarkets,
    } = this.props;

    const resps = await Promise.all([
      getCouponById(couponID),
      getGoods(),
      getCustomers(),
      getActivities(),
      getMarkets(),
    ]);

    const goods = resps[0].payload.goods;
    goods.forEach((g) => {
      g.prom = getGoodById(g.id).then((r) => {
        g = r.payload;
        this.forceUpdate();
      });
    });

    this.setState({ coupon: resps[0].payload, loading: false });
  }

  render() {
    const {
      location,
      history,
      updateCoupon,
      addCouponGood,
      updateCouponGood,
      deleteCouponGood,
      createCouponActivity,
      deleteCouponActivity,
      updateCouponActivity,
      activities,
      deleteCoupon,
      goods,
      customers,
      markets,
    } = this.props;

    const { urlParams, coupon, loading } = this.state;

    return (
      <WrapperCouponPage
        history={history}
        location={location}
        urlParams={urlParams}
        addCouponGood={addCouponGood}
        updateCouponGood={updateCouponGood}
        deleteCouponGood={deleteCouponGood}
        createCouponActivity={createCouponActivity}
        deleteCouponActivity={deleteCouponActivity}
        updateCouponActivity={updateCouponActivity}
        activities={activities}
        goods={goods}
        markets={markets}
        deleteCoupon={deleteCoupon}
        coupon={coupon}
        customers={customers}
        loading={loading}
        refresh={this.refresh.bind(this)}
        updateCoupon={updateCoupon}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CouponPage);
