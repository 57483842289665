import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Deletable extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
    onDelete: PropTypes.func,
  };

  render() {
    const { classes, children, onDelete } = this.props;

    return (
      <div className={classes.item}>
        <Grid container alignItems="center">
          <Grid item>{children}</Grid>
          <Grid item>
            <IconButton onClick={onDelete} style={{ padding: 5 }}>
              <Close style={{ fontSize: 20 }} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Deletable);
