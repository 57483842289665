import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const tickets = `/${service}/tickets`;

export function getTickets() {
  const url = `${tickets}`;
  return {
    method: GET,
    url,
  };
}

export function getTicketById(id) {
  const url = `${tickets}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateTicket(id, body) {
  const url = `${tickets}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTicket(id) {
  const url = `${tickets}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTicket(body) {
  const url = `${tickets}`;
  return {
    method: POST,
    url,
    body,
  };
}
