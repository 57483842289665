import { checkAccess } from "helpers/auth";
import { ROOT } from "constants/routes";
import RootPage from "./container/RootPage";

export default function getRoot(userAuth) {
  const requireAuth = {};
  return {
    path: ROOT,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    component: RootPage,
  };
}
