import { InputBase } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import InsertChart from "@material-ui/icons/InsertChartTwoTone";
// custom
import AutoComplete from "components/AutoComplete";
// reactor
import Good from "components/Good";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CouponGoods extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    coupon: PropTypes.object,
    addCouponGood: PropTypes.func,
    deleteCouponGood: PropTypes.func,
    goods: PropTypes.array,
    updateCouponGood: PropTypes.func,
    createCouponActivity: PropTypes.func,
    deleteCouponActivity: PropTypes.func,
    updateCouponActivity: PropTypes.func,
    activities: PropTypes.array,
  };

  async addCouponGood(selectedGood) {
    const { addCouponGood, coupon, refresh } = this.props;

    await addCouponGood({
      goodID: selectedGood.value.id,
      couponID: coupon.id,
      quantity: 1,
    });
    refresh();
  }

  async addActivityGood(activity) {
    const { createCouponActivity, coupon, refresh } = this.props;
    await createCouponActivity({
      activityID: activity.value.id,
      couponID: coupon.id,
    });
    refresh();
  }

  async deleteCouponGood(couponGood) {
    const { deleteCouponGood, refresh } = this.props;
    await deleteCouponGood(couponGood.relationID);
    refresh();
  }

  async deleteCouponActivity(couponActivity) {
    const { deleteCouponActivity, refresh } = this.props;
    await deleteCouponActivity(couponActivity.relationID);
    refresh();
  }

  render() {
    const {
      goods,
      coupon,
      activities,
      refresh,
      updateCouponActivity,
      updateCouponGood,
    } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutoComplete
            placeHolder={"Add Good to Coupon"}
            onSelect={this.addCouponGood.bind(this)}
            suggestions={
              goods &&
              goods.map((g) => ({
                value: g,
                label: `${g.title}`,
              }))
            }
          />
        </Grid>
        {coupon &&
          coupon.goods &&
          coupon.goods.map((g) => (
            <Grid item key={`good_${g.id}`} xs={12} md={3}>
              <Good
                image
                info
                price
                discount={coupon.percent}
                paper
                good={g}
                footer={
                  <React.Fragment>
                    <br />
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={4}>
                        <Typography display="block" variant="body2">
                          Exclude:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Checkbox
                          defaultChecked={g.exclude}
                          onChange={(e) => {
                            updateCouponGood(g.relationID, {
                              exclude: !g.exclude,
                            });
                          }}
                          color="primary"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography display="block" variant="body2">
                          Limit Depth:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Checkbox
                          defaultChecked={g.limitDepth}
                          onChange={(e) => {
                            updateCouponGood(g.relationID, {
                              limitDepth: !g.limitDepth,
                            });
                          }}
                          color="primary"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography display="block" variant="body2">
                          Quantity:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <InputBase
                          style={{ border: "solid 1px rgba(155,155,155,0.3)" }}
                          defaultValue={g.quantity}
                          onChange={(e) => {
                            if (Number(e.target.value)) {
                              updateCouponGood(g.relationID, {
                                quantity: Number(e.target.value),
                              });
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => this.deleteCouponGood(g)}
                    >
                      Remove From Coupon
                    </Button>
                  </React.Fragment>
                }
              />
            </Grid>
          ))}
        <Grid item xs={12}>
          <AutoComplete
            placeHolder={"Add Activity to Coupon"}
            onSelect={this.addActivityGood.bind(this)}
            suggestions={
              activities &&
              activities.map((a) => ({
                value: a,
                label: `${a.title}`,
              }))
            }
          />
        </Grid>
        {coupon &&
          coupon.activities &&
          coupon.activities.map((a) => (
            <Grid item key={`activity_${a.id}`}>
              <Paper style={{ padding: 10 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <InsertChart />
                  </Grid>
                  <Grid item>
                    <Typography display="block" variant="h6">
                      {a.title}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={a.basePrice}
                          onChange={async () => {
                            await updateCouponActivity(a.relationID, {
                              basePrice: !a.basePrice,
                            });
                            refresh();
                          }}
                          value="basePrice"
                        />
                      }
                      label="Base Price"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={a.usagePrice}
                          onChange={async () => {
                            await updateCouponActivity(a.relationID, {
                              usagePrice: !a.usagePrice,
                            });
                            refresh();
                          }}
                          value="usagePrice"
                        />
                      }
                      label="Usage Price"
                    />
                  </Grid>
                </Grid>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => this.deleteCouponActivity(a)}
                >
                  Remove From Coupon
                </Button>
              </Paper>
            </Grid>
          ))}
      </Grid>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(CouponGoods));
