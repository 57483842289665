import React from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// creative-tim
import Badge from "creativeTim/Badge/Badge";

// constants
import {
  AT_LEAST_1_NUMERICAL_CHAR,
  AT_LEAST_1_ALPHABETICAL_CHAR,
  AT_LEAST_8_CHAR,
} from "constants/regexp";

// styles
import styles from "./styles";

class FormResetPassword extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
    updateUserPassword: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      password: "",
      passwordRetype: "",
      eightChar: false,
      numerical: false,
      alphabetical: false,
      match: false,
      focused: false,
      showPassword: false,
      showPasswordRetype: false,
    };
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;
    const { passwordRetype, password } = this.state;

    if (name === "password") {
      let eightChar = false;
      let numerical = false;
      let alphabetical = false;
      let match = false;

      if (AT_LEAST_8_CHAR.test(value)) {
        eightChar = true;
      }

      if (AT_LEAST_1_NUMERICAL_CHAR.test(value)) {
        numerical = true;
      }

      if (AT_LEAST_1_ALPHABETICAL_CHAR.test(value)) {
        alphabetical = true;
      }

      if (value === passwordRetype) {
        match = true;
      }

      this.setState({
        eightChar,
        numerical,
        alphabetical,
        match,
      });
    }

    if (name === "passwordRetype") {
      let match = false;
      if (value === password) {
        match = true;
      }

      this.setState({ match });
    }

    this.setState({
      [name]: value,
    });
  };

  isValid() {
    const { eightChar, numerical, alphabetical, match } = this.state;

    return match && alphabetical && numerical && eightChar;
  }

  async updateUserPassword() {
    const { NotificationCenter } = this.context;
    const { updateUserPassword, user } = this.props;
    const { password } = this.state;

    const resp = await updateUserPassword(user.id, { password });

    if (resp.success) {
      NotificationCenter.stack(
        {
          title: "Success",
          subtitle: "Your password has been updated.",
          timestamp: new Date().getTime(),
          success: true,
        },
        {
          confirm: {
            label: "Ok",
            level: "success",
          },
        }
      );

      this.setState({
        password: "",
        passwordRetype: "",
        focused: false,
        eightChar: false,
        numerical: false,
        alphabetical: false,
        match: false,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      password,
      passwordRetype,
      eightChar,
      numerical,
      alphabetical,
      match,
      focused,
      showPassword,
      showPasswordRetype,
    } = this.state;

    return (
      <ClickAwayListener onClickAway={() => this.setState({ focused: false })}>
        <Paper className={classes.container}>
          <Typography display="block" variant="h6" gutterBottom>
            Change your Password
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="adornment-password">Password</InputLabel>
                <Input
                  id="adornment-password"
                  onFocus={() => this.setState({ focused: true })}
                  label="Password *"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={this.handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="Toggle password visibility"
                        onClick={() =>
                          this.setState({ showPassword: !showPassword })
                        }
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="adornment-password-retype">
                  Password Retype
                </InputLabel>
                <Input
                  onFocus={() => this.setState({ focused: true })}
                  type={showPasswordRetype ? "text" : "password"}
                  id="passwordRetype"
                  label="Retype Password *"
                  value={passwordRetype}
                  onChange={this.handleChange("passwordRetype")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1"
                        aria-label="Toggle password visibility"
                        onClick={() =>
                          this.setState({
                            showPasswordRetype: !showPasswordRetype,
                          })
                        }
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPasswordRetype ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <br />
          {focused && (
            <Grid container spacing={1}>
              <Grid item>
                <Badge color={eightChar ? "success" : "warning"}>
                  At least 8 characters
                </Badge>
              </Grid>
              <Grid item>
                <Badge color={numerical ? "success" : "warning"}>
                  At least 1 digit
                </Badge>
              </Grid>
              <Grid item>
                <Badge color={alphabetical ? "success" : "warning"}>
                  At least 1 letter
                </Badge>
              </Grid>
              <Grid item>
                <Badge color={match ? "success" : "warning"}>
                  Passwords match
                </Badge>
              </Grid>
            </Grid>
          )}
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              disabled={!this.isValid()}
              onClick={this.updateUserPassword.bind(this)}
            >
              Change
            </Button>
          </DialogActions>
        </Paper>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles)(FormResetPassword);
