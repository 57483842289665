import { networkAction } from "helpers/network/networkAction";

import {
  GET_CARTS,
  GET_CART_BY_ID,
  DELETE_CART,
  UPDATE_CART,
  CREATE_CART,
  VALIDATE_CART,
} from "constants/carts";

import * as Api from "api";

export const getCarts = () => async (dispatch) =>
  networkAction(dispatch, GET_CARTS, Api.getCarts, []);

export const validateCart = (cart) => async (dispatch) =>
  networkAction(dispatch, VALIDATE_CART, Api.validateCart, [cart]);

export const getCartById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CART_BY_ID, Api.getCartById, [id]);

export const deleteCart = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CART, Api.deleteCart, [id]);

export const createCart = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CART, Api.createCart, [body]);

export const updateCart = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CART, Api.updateCart, [id, body]);
