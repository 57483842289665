import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import GraphicEq from "@material-ui/icons/GraphicEqTwoTone";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

// custom
import Table from "components/TablePagination";

import { formattedPrice } from "helpers/stripe";

// constants
import { STATEMENT } from "constants/routes";

// styles
import styles from "./styles";

class StatementsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    statements: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
  };

  onSelect(selectedStatement) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedStatement);
    }
  }

  render() {
    const { statements, history } = this.props;

    if (statements.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "calc(100vh - 140px)", textAlign: "center" }}
        >
          <Grid item>
            <GraphicEq style={{ fontSize: 70 }} />
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              No statements
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Customer does not have any statements yet.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={statements}
        rowStyle={(d) => ({
          borderLeft:
            d.invoiceID === null ? "solid 3px #ffc107" : "solid 3px #9e9e9e",
        })}
        meta={[
          {
            path: "id",
            numeric: false,
            render: () => <GraphicEq />,
            width: 20,
          },
          {
            path: "customerID",
            title: "Customer #",
            numeric: false,
          },
          {
            path: "creationDate",
            title: "Creation",
            numeric: false,
            transform: (t) => moment(t).format("MMMM YYYY"),
            render: (t, d) => (
              <React.Fragment>
                <Typography display="block" variant="body2">
                  {t}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Created on {moment(d.creationDate).calendar()}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "invoiceID",
            title: "Status",
            render: (t, d) => (
              <React.Fragment>
                {d.invoiceID ? (
                  <Chip label="Invoiced" />
                ) : (
                  <Chip label="Current" color="primary" />
                )}
              </React.Fragment>
            ),
          },
          {
            path: "total",
            numeric: true,
            transform: (t) => `USD ${formattedPrice(t)}`,
          },
        ]}
        title={"Statements"}
        onRowSelect={(p) => history.push(`${STATEMENT}?id=${p.id}`)}
      />
    );
  }
}

export default withStyles(styles)(StatementsList);
