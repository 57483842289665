import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// custom
import Table from "components/TablePagination";

// config
import { PLACEHOLDER } from "config";

// styles
import styles from "./styles";

class MarketsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    markets: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedMarket) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedMarket);
    }
  }

  render() {
    const { markets } = this.props;

    return (
      <Table
        data={markets}
        meta={[
          {
            path: "icon",
            numeric: false,
            render: (t, d) => (
              <img
                alt="market"
                src={d.icon && d.icon.length ? d.icon : PLACEHOLDER}
                style={{ height: 30 }}
              />
            ),
            width: 40,
          },
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
        ]}
        title={"Markets"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(MarketsList);
