import React from "react";
import { connect } from "react-redux";

// actions
import { signin } from "actions/sessions";

// component
import WrapperDefaultPage from "../component/WrapperDefaultPage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  signin: () => dispatch(signin()),
});
class DefaultPage extends React.Component {
  static propTypes = {};

  render() {
    return <WrapperDefaultPage />;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
