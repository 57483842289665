import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import SockJS from "sockjs-client";
import store from "./store";
import "./index.css";
import App from "./routes/root/container/RootPage";
import { unregister } from "./registerServiceWorker";

window.SockJS = SockJS;

ReactDOM.render(
  <Provider store={store()}>
    <App />
  </Provider>,
  document.getElementById("root")
);
unregister();
