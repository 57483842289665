import { networkAction } from "helpers/network/networkAction";

import {
  GET_CATEGORIES,
  GET_CATEGORY_BY_ID,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
} from "constants/categories";

import * as Api from "api";

export const getCategories = () => async (dispatch) =>
  networkAction(dispatch, GET_CATEGORIES, Api.getCategories, []);

export const getCategoryById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CATEGORY_BY_ID, Api.getCategoryById, [id]);

export const deleteCategory = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CATEGORY, Api.deleteCategory, [id]);

export const createCategory = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CATEGORY, Api.createCategory, [body]);

export const updateCategory = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CATEGORY, Api.updateCategory, [id, body]);
