import { GET, PUT, DELETE, POST, DATA } from "constants/methods";

const service = "bazaar";
const media = `/${service}/media`;

export function uploadMedia(file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${media}/upload`;

  return {
    method: DATA,
    url,
    body: data,
  };
}

export function getMedia() {
  const url = `${media}`;
  return {
    method: GET,
    url,
  };
}

export function getMediumById(id) {
  const url = `${media}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateMedium(id, body) {
  const url = `${media}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMedium(id) {
  const url = `${media}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMedium(body) {
  const url = `${media}`;
  return {
    method: POST,
    url,
    body,
  };
}
