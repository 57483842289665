import { networkAction } from "helpers/network/networkAction";

import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_BY_ID,
  DELETE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
} from "constants/subscriptions";

import * as Api from "api";

export const getSubscriptions = () => async (dispatch) =>
  networkAction(dispatch, GET_SUBSCRIPTIONS, Api.getSubscriptions, []);

export const getSubscriptionById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SUBSCRIPTION_BY_ID, Api.getSubscriptionById, [
    id,
  ]);

export const deleteSubscription = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SUBSCRIPTION, Api.deleteSubscription, [id]);

export const createSubscription = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SUBSCRIPTION, Api.createSubscription, [body]);

export const updateSubscription = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SUBSCRIPTION, Api.updateSubscription, [
    id,
    body,
  ]);
