import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import People from "@material-ui/icons/PeopleTwoTone";
import Stars from "@material-ui/icons/StarsTwoTone";
import CouponInfo from "components/CouponInfo";
// reactor
import Page from "components/Page";
import { BILLING, CUSTOMER } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperCouponPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    coupon: PropTypes.object,
    updateCoupon: PropTypes.func,
    deleteCoupon: PropTypes.func,
    addCouponGood: PropTypes.func,
    deleteCouponGood: PropTypes.func,
    createCouponActivity: PropTypes.func,
    deleteCouponActivity: PropTypes.func,
    updateCouponActivity: PropTypes.func,
    updateCouponGood: PropTypes.func,
    refresh: PropTypes.func,
    goods: PropTypes.array,
    activities: PropTypes.array,
    markets: PropTypes.array,
    customers: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const {
      coupon,
      updateCoupon,
      refresh,
      addCouponGood,
      deleteCouponGood,
      createCouponActivity,
      deleteCouponActivity,
      updateCouponActivity,
      updateCouponGood,
      deleteCoupon,
      goods,
      customers,
      history,
      markets,
      activities,
    } = this.props;
    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <CouponInfo
            coupon={coupon}
            activities={activities}
            history={history}
            refresh={refresh}
            updateCoupon={updateCoupon}
            customers={customers}
            addCouponGood={addCouponGood}
            deleteCoupon={deleteCoupon}
            deleteCouponGood={deleteCouponGood}
            updateCouponGood={updateCouponGood}
            createCouponActivity={createCouponActivity}
            deleteCouponActivity={deleteCouponActivity}
            updateCouponActivity={updateCouponActivity}
            markets={markets}
            goods={goods}
          />
        );
      default:
        return (
          <Typography display="block" variant="title">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  render() {
    const { classes, history, loading, coupon, customers } = this.props;

    const customer = customers.find((c) => c.id === coupon.customerID);

    return (
      <Page
        helmet="Coupon"
        loading={loading}
        header={
          <Grid container style={{ padding: 10 }}>
            <Grid item>
              <Breadcrumbs arial-label="Breadcrumb" separator={"-"}>
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => history.push(`${BILLING}?index=1`)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                {customer ? (
                  <Chip
                    label={customer.fullName}
                    className={classes.backChip}
                    onClick={() =>
                      history.push(`${CUSTOMER}?id=${customer.id}`)
                    }
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <People className={classes.icon} />
                      </Avatar>
                    }
                  />
                ) : (
                  []
                )}
                <Chip
                  label={`Coupon ${coupon ? coupon.token : "..."}`}
                  className={classes.backChip}
                  variant="contained"
                  color="primary"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Stars className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
            </Grid>
          </Grid>
        }
        loadingMessage={"Loading Coupon"}
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCouponPage);
