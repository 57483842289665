import { GET_PRICINGS } from "constants/pricings";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_PRICINGS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
