import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// custom
import Table from "components/TablePagination";

// styles
import styles from "./styles";

class ActivitiesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    activities: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedActivitie) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedActivitie);
    }
  }

  render() {
    const { activities } = this.props;

    return (
      <Table
        data={activities}
        meta={[
          {
            path: "title",
            title: "Title",
            numeric: false,
          },
        ]}
        title={"Activities"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(ActivitiesList);
