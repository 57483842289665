import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Grow from "@material-ui/core/Grow";
import Drag from "@material-ui/icons/DragIndicator";
import Close from "@material-ui/icons/Close";
import Avatar from "@material-ui/core/Avatar";

// constants
import { PLACEHOLDER } from "config";
import { formattedPrice } from "helpers/stripe";

// styles
import styles from "./styles";

class Good extends Component {
  static propTypes = {
    good: PropTypes.object,
    alt: PropTypes.bool,
    deleteShelveGood: PropTypes.func,
    refresh: PropTypes.func,
    edit: PropTypes.bool,
  };

  render() {
    const { good, alt, deleteShelveGood, refresh, edit } = this.props;

    return (
      <div style={{ position: "relative" }}>
        {alt && edit ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <Grow in>
              <Drag
                style={{
                  cursor: "grab",
                  fontSize: 15,
                  color: "rgb(155,155,155)",
                }}
              />
            </Grow>
          </div>
        ) : (
          []
        )}
        <Grid
          container
          spacing={1}
          style={{ cursor: alt && edit ? "grab" : undefined }}
        >
          <Grid item style={{ width: 92 }}>
            <img
              style={{ width: 75 }}
              alt="assessment"
              src={
                good.pictures && good.pictures.length
                  ? good.pictures[0].url
                  : PLACEHOLDER
              }
            />
          </Grid>
          <Grid
            item
            style={{
              width: "calc(100% - 108px)",
            }}
          >
            <Typography display="block" variant="body2">
              {good.title}
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              {good.description && good.description.length > 100
                ? `${good.description.substring(0, 100)}...`
                : good.description}
            </Typography>
            <Grid container spacing={1}>
              {good &&
                good.pricings &&
                good.pricings.map((p) => (
                  <Grid item key={p.id}>
                    <Chip
                      label={`${formattedPrice(p.currencies[0].price)}${
                        good.pricings.length > 1 ? ` (${p.title}) ` : " "
                      }Buy Now`}
                    />
                  </Grid>
                ))}
              {edit ? (
                <Grid item>
                  <Chip
                    variant="outlined"
                    label={"Remove"}
                    avatar={
                      <Avatar style={{ background: "none" }}>
                        <Close />
                      </Avatar>
                    }
                    onClick={async () => {
                      await deleteShelveGood(good.relationID);
                      refresh();
                    }}
                  />
                </Grid>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Good);
