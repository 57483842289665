import { GET_TICKETS } from "constants/tickets";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_TICKETS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
