import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const settings = `/${service}/settings`;

export function getSettings() {
  const url = `${settings}`;
  return {
    method: GET,
    url,
  };
}

export function getSettingById(id) {
  const url = `${settings}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateSetting(id, body) {
  const url = `${settings}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteSetting(id) {
  const url = `${settings}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createSetting(body) {
  const url = `${settings}`;
  return {
    method: POST,
    url,
    body,
  };
}
