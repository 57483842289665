import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { formattedPrice } from "helpers/stripe";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class InvoiceItem extends Component {
  static propTypes = {
    classes: PropTypes.object,
    invoiceItem: PropTypes.object,
    deleteInvoiceItem: PropTypes.func,
    updateInvoiceItem: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { invoiceItem } = this.props;
    this.state = {
      name: invoiceItem.name,
      description: invoiceItem.description,
      price: invoiceItem.price / 100,
      quantity: invoiceItem.quantity / 100,
      loading: false,
      editable: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async save(name, numeric) {
    const { updateInvoiceItem, refresh, invoiceItem } = this.props;

    if (invoiceItem[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateInvoiceItem(invoiceItem.id, {
      [name]: numeric ? Number(this.state[name] * 100) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  deleteInvoiceItem() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteInvoiceItem, invoiceItem, refresh } = this.props;
    await deleteInvoiceItem(invoiceItem.id);
    refresh();
  }

  render() {
    const { classes, invoiceItem } = this.props;

    const {
      editable,
      name,
      description,
      loading,
      price,
      quantity,
    } = this.state;

    return (
      <Paper className={classes.item}>
        <Grid container justify="space-between" spacing={1}>
          <Grid item xs={3}>
            <Typography display="block" variant="caption" color="textSecondary">
              Name
            </Typography>
            <InputBase
              disabled={loading || !editable}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("name");
              }}
              onBlur={() => this.save("name")}
              fullWidth
              classes={{
                root: editable ? classes.inputActive : classes.inputUnactive,
                disabled: classes.disabled,
              }}
              defaultValue={name}
              onChange={this.handleChange("name")}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography display="block" variant="caption" color="textSecondary">
              Description
            </Typography>
            <InputBase
              disabled={loading || !editable}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("description");
              }}
              onBlur={() => this.save("description")}
              fullWidth
              classes={{
                root: editable ? classes.inputActive : classes.inputUnactive,
                disabled: classes.disabled,
              }}
              defaultValue={description}
              onChange={this.handleChange("description")}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography display="block" variant="caption" color="textSecondary">
              Qty
            </Typography>
            <InputBase
              disabled={loading || !editable}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("quantity", true);
              }}
              onBlur={() => this.save("quantity", true)}
              fullWidth
              classes={{
                root: editable ? classes.inputActive : classes.inputUnactive,
                disabled: classes.disabled,
              }}
              defaultValue={quantity}
              onChange={this.handleChange("quantity")}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography display="block" variant="body2">
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Price (USD)
              </Typography>
              <InputBase
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("price", true);
                }}
                onBlur={() => this.save("price", true)}
                disabled={loading || !editable}
                fullWidth
                classes={{
                  root: editable ? classes.inputActive : classes.inputUnactive,
                  disabled: classes.disabled,
                }}
                defaultValue={price}
                onChange={this.handleChange("price")}
              />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography display="block" variant="body2">
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Total
              </Typography>
              <Typography style={{ marginTop: 6 }}>
                ${formattedPrice(invoiceItem.total)}
              </Typography>
            </Typography>
          </Grid>
          <Divider />
          {/* <Grid item xs={12}>
            <Typography display="block" variant="title">
              <b>Total:</b> USD {invoiceItem.total}
            </Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <Chip
                  label="Delete"
                  variant="outlined"
                  onClick={this.deleteInvoiceItem.bind(this)}
                />
              </Grid>
              <Grid item>
                <Chip
                  label={editable ? "Done" : "Edit"}
                  color={editable ? "primary" : "default"}
                  variant={editable ? "contained" : "outlined"}
                  onClick={() =>
                    this.setState((prevState) => ({
                      editable: !prevState.editable,
                    }))
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(InvoiceItem);
