import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperMarketPage from "../component/WrapperMarketPage";

const mapStateToProps = (state) => ({
  goods: state.goods,
});

const mapDispatchToProps = (dispatch) => ({
  getMarketById: (...args) => dispatch(Actions.getMarketById(...args)),
  getShelveById: (...args) => dispatch(Actions.getShelveById(...args)),
  deleteMarket: (...args) => dispatch(Actions.deleteMarket(...args)),
  updateMarket: (...args) => dispatch(Actions.updateMarket(...args)),
  getGoods: (...args) => dispatch(Actions.getGoods(...args)),

  createAisle: (...args) => dispatch(Actions.createAisle(...args)),
  deleteAisle: (...args) => dispatch(Actions.deleteAisle(...args)),
  updateAisle: (...args) => dispatch(Actions.updateAisle(...args)),

  createBanner: (...args) => dispatch(Actions.createBanner(...args)),
  deleteBanner: (...args) => dispatch(Actions.deleteBanner(...args)),
  updateBanner: (...args) => dispatch(Actions.updateBanner(...args)),

  createShelve: (...args) => dispatch(Actions.createShelve(...args)),
  deleteShelve: (...args) => dispatch(Actions.deleteShelve(...args)),
  updateShelve: (...args) => dispatch(Actions.updateShelve(...args)),

  createShelveGood: (...args) => dispatch(Actions.createShelveGood(...args)),
  deleteShelveGood: (...args) => dispatch(Actions.deleteShelveGood(...args)),
  updateShelveGood: (...args) => dispatch(Actions.updateShelveGood(...args)),
});
class MarketPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    getMarketById: PropTypes.func,
    deleteMarket: PropTypes.func,
    updateMarket: PropTypes.func,
    getGoods: PropTypes.func,
    goods: PropTypes.array,

    createAisle: PropTypes.func,
    deleteAisle: PropTypes.func,
    updateAisle: PropTypes.func,

    createBanner: PropTypes.func,
    deleteBanner: PropTypes.func,
    updateBanner: PropTypes.func,

    createShelve: PropTypes.func,
    deleteShelve: PropTypes.func,
    updateShelve: PropTypes.func,

    createShelveGood: PropTypes.func,
    deleteShelveGood: PropTypes.func,
    updateShelveGood: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, getGoods } = this.props;
    const urlParams = getJsonFromUrl(location);
    getGoods();
    this.state = {
      urlParams,
      marketID: urlParams.id,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { marketID } = this.state;
    const { getMarketById } = this.props;

    const resp = await getMarketById(marketID);
    if (resp.success) {
      const market = resp.payload;
      market.aisles.forEach((a) =>
        a.shelves.forEach((s) => {
          s.loading = true;
        })
      );
      this.setState({
        market,
        loading: false,
        refreshKey: new Date().getTime(),
      });
    }
  }

  render() {
    const {
      location,
      history,
      createAisle,
      deleteAisle,
      updateAisle,
      createBanner,
      deleteBanner,
      updateBanner,
      createShelve,
      deleteShelve,
      updateShelve,
      createShelveGood,
      deleteShelveGood,
      updateShelveGood,
      deleteMarket,
      updateMarket,
      goods,
    } = this.props;

    const { urlParams, loading, market, refreshKey } = this.state;

    return (
      <WrapperMarketPage
        history={history}
        location={location}
        urlParams={urlParams}
        market={market}
        loading={loading}
        createAisle={createAisle}
        deleteAisle={deleteAisle}
        updateAisle={updateAisle}
        createBanner={createBanner}
        deleteBanner={deleteBanner}
        updateBanner={updateBanner}
        createShelve={createShelve}
        deleteShelve={deleteShelve}
        updateShelve={updateShelve}
        createShelveGood={createShelveGood}
        deleteShelveGood={deleteShelveGood}
        updateShelveGood={updateShelveGood}
        deleteMarket={deleteMarket}
        updateMarket={updateMarket}
        refreshKey={refreshKey}
        goods={goods}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MarketPage);
