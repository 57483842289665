import { DOMAIN } from "config";

export function getCookie(cookiename) {
  // Get name followed by anything except a semicolon
  const cookiestring = RegExp(`${cookiename}[^;]+`).exec(document.cookie);

  // Return everything after the equal sign, or an empty string if the cookie name not found
  const ret = decodeURIComponent(
    cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : ""
  );
  return ret;
}

export function setCookie(cookies, key, value) {
  cookies.set(key, value, {
    path: "/",
    domain: DOMAIN,
    secure: true,
    sameSite: "Lax",
  });
}

export function removeCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
