import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Route, Switch, withRouter } from "react-router-dom";
import ReactGA from "react-ga";

import { DEFAULT, SIGNIN } from "constants/routes";

import Signup from "components/Signup";
import NotificationCenter from "components/NotificationCenter";
import Upload from "components/Upload";
import ForgotPassword from "components/ForgotPassword";
import ResetPassword from "components/ResetPassword";
import WebSocket from "components/WebSocket";
import KeyboardEvents from "components/KeyboardEvents";
import UrlActions from "components/UrlActions";
import { withCookies } from "react-cookie";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

// reactor
import AuthChecker from "components/AuthChecker";
import LeftMenu from "components/LeftMenu";
import MessageDrawer from "components/MessageDrawer";
import EnvMessage from "components/EnvMessage";

// custom
import AppBar from "components/AppBar";

import { redirectAccordingToRole } from "helpers/redirect";

import {
  GOOGLE_ANALYTICS_KEY,
  REACT_APP_ROOT_BACKGROUND,
  ENV_MESSAGE,
} from "config";

// styles
import styles from "./styles";

class WrapperRootPage extends React.Component {
  static propTypes = {
    routes: PropTypes.array,
    history: PropTypes.object,
    location: PropTypes.object,
    classes: PropTypes.object,
    theme: PropTypes.object,
    cookies: PropTypes.object,

    // Reducers
    user: PropTypes.object,
    userApps: PropTypes.array,

    // Api
    getCurrentUser: PropTypes.func,
    init: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,

    // errors
    errors: PropTypes.object,

    // app
    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,
    app: PropTypes.object,
    stopImpersonate: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      mobileOpen: false,
      loading: true,
      alert: null,
    };

    if (GOOGLE_ANALYTICS_KEY) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      if (GOOGLE_ANALYTICS_KEY) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  injectRoutes() {
    const { routes } = this.props;

    return (
      <div style={{ flexGrow: 1, display: "flex", position: "relative" }}>
        <Switch>
          {routes.map((route) =>
            route.path === DEFAULT ? (
              <Route key={route.path} component={route.component} />
            ) : (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
              />
            )
          )}
        </Switch>
      </div>
    );
  }

  render() {
    const {
      classes,
      location,
      routes,
      history,
      getCurrentUser,
      init,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      signout,
      storeResetPasswordToken,
      app,
      resetPassword,
      user,
      setDarkMode,
      stopImpersonate,
      userApps,
      setOrganizationByDefault,
      joinOrganization,
    } = this.props;

    const { loading, alert, mobileOpen } = this.state;

    const route = routes.find((r) => r.path === location.pathname);

    return (
      <div>
        <AuthChecker
          routes={routes}
          history={history}
          location={location}
          user={user}
          signinRoute={SIGNIN}
          redirectAccordingToRole={redirectAccordingToRole}
          getCurrentUser={getCurrentUser}
          init={init}
          onReady={() => {
            this.setState({ loading: false });
          }}
        >
          <NotificationCenter errors={errors}>
            <WebSocket user={user}>
              <KeyboardEvents>
                <UrlActions
                  location={location}
                  history={history}
                  validateEmail={validateEmail}
                  storeResetPasswordToken={storeResetPasswordToken}
                >
                  <Signup
                    history={history}
                    signup={signup}
                    validateEmail={validateEmail}
                  >
                    <ForgotPassword
                      history={history}
                      requestResetPassword={requestResetPassword}
                    >
                      <ResetPassword
                        token={app.resetPasswordToken}
                        resetPassword={resetPassword}
                        storeResetPasswordToken={storeResetPasswordToken}
                      >
                        <Upload>
                          {loading ? (
                            <Grid
                              container
                              className={classes.loading}
                              alignContent="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <CircularProgress
                                  style={{ color: "#ffffff" }}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <MessageDrawer
                              background={REACT_APP_ROOT_BACKGROUND}
                            >
                              <EnvMessage envMessage={ENV_MESSAGE} />
                              <div
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                }}
                              >
                                {route && route.withSidebar && (
                                  <LeftMenu
                                    routes={routes}
                                    handleDrawerToggle={this.handleDrawerToggle.bind(
                                      this
                                    )}
                                    mobileOpen={mobileOpen}
                                    history={history}
                                    user={user}
                                    location={location}
                                  />
                                )}
                                <div
                                  style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {route && route.withAppBar && (
                                    <AppBar
                                      onMenuOpen={this.handleDrawerToggle.bind(
                                        this
                                      )}
                                      user={user}
                                      userApps={userApps}
                                      history={history}
                                      app={app}
                                      setDarkMode={setDarkMode}
                                      stopImpersonate={stopImpersonate}
                                      setOrganizationByDefault={
                                        setOrganizationByDefault
                                      }
                                      joinOrganization={joinOrganization}
                                      getCurrentUser={getCurrentUser}
                                      signout={signout}
                                    />
                                  )}
                                  {this.injectRoutes()}
                                  {alert}
                                </div>
                              </div>
                            </MessageDrawer>
                          )}
                        </Upload>
                      </ResetPassword>
                    </ForgotPassword>
                  </Signup>
                </UrlActions>
              </KeyboardEvents>
            </WebSocket>
          </NotificationCenter>
        </AuthChecker>
      </div>
    );
  }
}

export default withCookies(
  withRouter(
    connect()(withStyles(styles, { withTheme: true })(WrapperRootPage))
  )
);
