import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "account";
const organizations = `${REACT_APP_API_PREFIX}/${service}/organizations`;
const organizationUsers = `${REACT_APP_API_PREFIX}/${service}/organizationUsers`;

export function getOrganizations() {
  const url = `${organizations}`;
  return {
    method: GET,
    url,
  };
}

export function getOrganizationById(id) {
  const url = `${organizations}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getOrganizationUsers(id) {
  const url = `${organizations}/${id}/users`;
  return {
    method: GET,
    url,
  };
}

export function joinOrganization(id, body) {
  const url = `${organizations}/${id}/join`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function setOrganizationByDefault(id) {
  const url = `${organizations}/${id}/default`;
  return {
    method: PUT,
    url,
  };
}

export function addUserToOrganization(id, body) {
  const url = `${organizations}/${id}/users`;
  return {
    method: POST,
    url,
    body,
  };
}

export function updateOrganization(id, body) {
  const url = `${organizations}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteOrganization(id) {
  const url = `${organizations}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function deleteOrganizationUser(id) {
  const url = `${organizationUsers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createOrganization(body) {
  const url = `${organizations}`;
  return {
    method: POST,
    url,
    body,
  };
}
