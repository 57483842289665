import { networkAction } from "helpers/network/networkAction";

import {
  GET_PRICINGS,
  GET_PRICING_BY_ID,
  DELETE_PRICING,
  UPDATE_PRICING,
  CREATE_PRICING,
} from "constants/pricings";

import * as Api from "api";

export const getPricings = () => async (dispatch) =>
  networkAction(dispatch, GET_PRICINGS, Api.getPricings, []);

export const getPricingById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PRICING_BY_ID, Api.getPricingById, [id]);

export const deletePricing = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PRICING, Api.deletePricing, [id]);

export const createPricing = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PRICING, Api.createPricing, [body]);

export const updatePricing = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PRICING, Api.updatePricing, [id, body]);
