export default (theme) => ({
  textField: {
    width: "100%",
  },
  userImage: {
    width: "100%",
    textAlign: "center",
    paddingTop: 20,
  },
  progressContainer: {
    textAlign: "center",
    padding: 50,
  },
});
