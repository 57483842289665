import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const activities = `/${service}/activities`;

export function getActivities() {
  const url = `${activities}`;
  return {
    method: GET,
    url,
  };
}

export function getActivityById(id) {
  const url = `${activities}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateActivity(id, body) {
  const url = `${activities}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteActivity(id) {
  const url = `${activities}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createActivity(body) {
  const url = `${activities}`;
  return {
    method: POST,
    url,
    body,
  };
}
