import { networkAction } from "helpers/network/networkAction";

import {
  GET_COUPONS,
  GET_COUPON_BY_ID,
  DELETE_COUPON,
  UPDATE_COUPON,
  CREATE_COUPON,
  ADD_COUPON_GOOD,
  DELETE_COUPON_GOOD,
  UPDATE_COUPON_GOOD,
} from "constants/coupons";

import * as Api from "api";

export const getCoupons = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_COUPONS, Api.getCoupons, [filters]);

export const getCouponById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_COUPON_BY_ID, Api.getCouponById, [id]);

export const deleteCoupon = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_COUPON, Api.deleteCoupon, [id]);

export const addCouponGood = (body) => async (dispatch) =>
  networkAction(dispatch, ADD_COUPON_GOOD, Api.addCouponGood, [body]);

export const deleteCouponGood = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_COUPON_GOOD, Api.deleteCouponGood, [id]);

export const createCoupon = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_COUPON, Api.createCoupon, [body]);

export const updateCoupon = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_COUPON, Api.updateCoupon, [id, body]);

export const updateCouponGood = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_COUPON_GOOD, Api.updateCouponGood, [id, body]);
