import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const pricings = `/${service}/pricings`;

export function getPricings() {
  const url = `${pricings}`;
  return {
    method: GET,
    url,
  };
}

export function getPricingById(id) {
  const url = `${pricings}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updatePricing(id, body) {
  const url = `${pricings}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePricing(id) {
  const url = `${pricings}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPricing(body) {
  const url = `${pricings}`;
  return {
    method: POST,
    url,
    body,
  };
}
