import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const banners = `/${service}/banners`;

export function getBanners() {
  const url = `${banners}`;
  return {
    method: GET,
    url,
  };
}

export function getBannerById(id) {
  const url = `${banners}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateBanner(id, body) {
  const url = `${banners}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteBanner(id) {
  const url = `${banners}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createBanner(body) {
  const url = `${banners}`;
  return {
    method: POST,
    url,
    body,
  };
}
