import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// custom
import Table from "components/TablePagination";

// styles
import styles from "./styles";

class ContactsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    contacts: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedContact) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedContact);
    }
  }

  render() {
    const { contacts } = this.props;

    return (
      <Table
        data={contacts}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "role",
            title: "Role",
            numeric: false,
          },
          {
            path: "email",
            title: "Email",
            numeric: false,
          },
          {
            path: "phone",
            title: "Phone",
            numeric: false,
          },
        ]}
        title={"Contacts"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(ContactsList);
