import { networkAction } from "helpers/network/networkAction";

import {
  GET_MARKETS,
  GET_MARKET_BY_ID,
  DELETE_MARKET,
  UPDATE_MARKET,
  CREATE_MARKET,
} from "constants/markets";

import * as Api from "api";

export const getMarkets = () => async (dispatch) =>
  networkAction(dispatch, GET_MARKETS, Api.getMarkets, []);

export const getMarketById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MARKET_BY_ID, Api.getMarketById, [id]);

export const deleteMarket = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MARKET, Api.deleteMarket, [id]);

export const createMarket = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MARKET, Api.createMarket, [body]);

export const updateMarket = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MARKET, Api.updateMarket, [id, body]);
