import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const bundles = `/${service}/bundles`;

export function getBundles() {
  const url = `${bundles}`;
  return {
    method: GET,
    url,
  };
}

export function getBundleById(id) {
  const url = `${bundles}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateBundle(id, body) {
  const url = `${bundles}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteBundle(id) {
  const url = `${bundles}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createBundle(body) {
  const url = `${bundles}`;
  return {
    method: POST,
    url,
    body,
  };
}
