import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const projects = `/${service}/projects`;

export function getProjects(filters) {
  const url = `${projects}${filters ? `?filters=${filters}` : ""}`;
  return {
    method: GET,
    url,
  };
}

export function getProjectById(id) {
  const url = `${projects}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProject(id, body) {
  const url = `${projects}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProject(id) {
  const url = `${projects}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProject(body) {
  const url = `${projects}`;
  return {
    method: POST,
    url,
    body,
  };
}
