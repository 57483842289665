export default (theme) => ({
  container: {},
  image: {
    width: 45,
    height: 45,
    borderRadius: 4,
    background: "white",
    border: "solid 1px rgba(100, 100, 100, 0.3)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
});
