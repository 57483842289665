import { networkAction } from "helpers/network/networkAction";

import {
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_FULL,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  CREATE_CUSTOMER,
} from "constants/customers";

import * as Api from "api";

export const getCustomers = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_CUSTOMERS, Api.getCustomers, [filters]);

export const getCustomerFull = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CUSTOMER_FULL, Api.getCustomerFull, [id]);

export const getCustomerById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_CUSTOMER_BY_ID, Api.getCustomerById, [id]);

export const deleteCustomer = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_CUSTOMER, Api.deleteCustomer, [id]);

export const createCustomer = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_CUSTOMER, Api.createCustomer, [body]);

export const updateCustomer = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_CUSTOMER, Api.updateCustomer, [id, body]);
