import { ACTIVITY } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import ActivityPage from "./container/ActivityPage";

export default function getSettingsRoute(userAuth) {
  const { account, bazaar } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [bazaar.name]: [account.roles.admin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: ACTIVITY,
    withSidebar: true,
    withAppBar: true,
    component: ActivityPage,
  };
}
