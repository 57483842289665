import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const customers = `/${service}/customers`;

export function getCustomers(filters) {
  const url = `${customers}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCustomerById(id) {
  const url = `${customers}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getCustomerFull(id) {
  const url = `${customers}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateCustomer(id, body) {
  const url = `${customers}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteCustomer(id) {
  const url = `${customers}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createCustomer(body) {
  const url = `${customers}`;
  return {
    method: POST,
    url,
    body,
  };
}
