import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
// custom
import ContactsList from "components/ContactsList";
import FormContact from "components/FormContact";
// reactor
import Page from "components/Page";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperContactPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    contacts: PropTypes.array,
    createContact: PropTypes.func,
    deleteContact: PropTypes.func,
    updateContact: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedContact: undefined,
      formContactOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { contacts } = this.props;

    switch (index) {
      case 0:
        return (
          <ContactsList
            contacts={contacts}
            onSelect={(selectedContact) =>
              this.setState({
                selectedContact,
                formContactOpen: true,
              })
            }
          />
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createContact,
      deleteContact,
      updateContact,
      refresh,
    } = this.props;

    const { index, selectedContact, formContactOpen } = this.state;

    return (
      <Page
        helmet="Contacts"
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formContactOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        loadingMessage={"Fetching all Contacts"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
        <FormContact
          open={formContactOpen}
          contact={selectedContact}
          close={() => {
            this.setState({
              formContactOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedContact: undefined,
                }),
              200
            );
          }}
          createContact={createContact}
          deleteContact={deleteContact}
          updateContact={updateContact}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperContactPage);
