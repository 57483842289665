import { networkAction } from "helpers/network/networkAction";

import { SIGNIN, SIGNOUT } from "constants/sessions";

import * as Api from "api";

export const signin = (body) => async (dispatch) =>
  networkAction(dispatch, SIGNIN, Api.signin, [body]);

export const signout = () => async (dispatch) =>
  networkAction(dispatch, SIGNOUT, Api.signout, []);
