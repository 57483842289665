import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperActivitiePage from "../component/WrapperActivitiePage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  activities: state.activities,
});

const mapDispatchToProps = (dispatch) => ({
  getActivities: () => dispatch(Actions.getActivities()),
  getActivityById: (...args) => dispatch(Actions.getActivityById(...args)),
  createActivity: (...args) => dispatch(Actions.createActivity(...args)),
  deleteActivity: (...args) => dispatch(Actions.deleteActivity(...args)),
  updateActivity: (...args) => dispatch(Actions.updateActivity(...args)),
});
class ActivitiePage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    activities: PropTypes.array,
    getActivities: PropTypes.func,
    getActivityById: PropTypes.func,
    createActivity: PropTypes.func,
    deleteActivity: PropTypes.func,
    updateActivity: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, activities } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: activities.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getActivities } = this.props;
    await Promise.all([getActivities()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      activities,
      getActivityById,
      createActivity,
      deleteActivity,
      updateActivity,
      history,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperActivitiePage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        activities={activities}
        getActivityById={getActivityById}
        createActivity={createActivity}
        deleteActivity={deleteActivity}
        updateActivity={updateActivity}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivitiePage);
