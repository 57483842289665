import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import AttachMoney from "@material-ui/icons/AttachMoneyTwoTone";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CompareArrows from "@material-ui/icons/CompareArrowsTwoTone";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestoreTwoTone";
import Skeleton from "@material-ui/lab/Skeleton";
// custom
import DatetimeGraph from "components/DatetimeGraph";
import RecentPaymentList from "components/RecentPaymentList";
import Widget from "components/Widget";
import { ExportToCsv } from "export-to-csv";
// helpers
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withCookies } from "react-cookie";
// styles
import styles from "./styles";

class PaymentsDashboard extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    dashboard: PropTypes.object,
    cookies: PropTypes.object,
    impersonate: PropTypes.func,
    paymentsRecent: PropTypes.array,
  };

  state = {
    range: "month",
  };

  getRevenue() {
    const { dashboard } = this.props;
    const { range } = this.state;
    let total = 0;

    for (const key in dashboard[range]) {
      if (dashboard[range].hasOwnProperty(key)) {
        const element = dashboard[range][key];
        if (!element.refunded) {
          total += element.total;
        }
      }
    }

    return formattedPrice(total);
  }

  getPulses() {
    const { dashboard } = this.props;
    const { range } = this.state;
    let pulses = 0;

    for (const key in dashboard[range]) {
      if (dashboard[range].hasOwnProperty(key)) {
        const element = dashboard[range][key];
        if (!element.refunded) {
          pulses += 1;
        }
      }
    }

    return pulses;
  }

  getRefunds() {
    const { dashboard } = this.props;
    const { range } = this.state;
    let pulses = 0;

    for (const key in dashboard[range]) {
      if (dashboard[range].hasOwnProperty(key)) {
        const element = dashboard[range][key];
        if (element.refunded) {
          pulses += 1;
        }
      }
    }

    return pulses;
  }

  export() {
    const { paymentsRecent } = this.props;

    const data = [];
    for (const key in paymentsRecent) {
      if (paymentsRecent.hasOwnProperty(key)) {
        const e = paymentsRecent[key];
        data.push({
          fullName: e.customer.fullName,
          email: e.customer.email,
          amount: formattedPrice(e.total),
          refunded: e.refunded || "",
          coupons:
            (e.cart &&
              e.cart.coupons &&
              e.cart.coupons.map((c) => `${c.token},`)) ||
            "",
          date: moment(e.paymentDate).parseZone().format("MM/DD/YYYY HH:mm:ss"),
          cartContent: `${
            e.cart.goods &&
            e.cart.goods.map(
              (a) =>
                `${a.quantity}x ${a.title} (${
                  a.pricings[0].title
                } - $${formattedPrice(a.pricings[0].currencies[0].price)})`
            )
          }`,
        });
      }
    }

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "My Awesome CSV",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  render() {
    const {
      paymentsRecent,
      dashboard,
      impersonate,
      cookies,
      history,
    } = this.props;

    const { range } = this.state;

    if (dashboard === undefined) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography display="block" variant="h6">
              Dashboard
            </Typography>
            <Grid container spacing={1}>
              <Grid item>
                <Skeleton
                  variant="rect"
                  width={103}
                  height={32}
                  style={{ borderRadius: 90 }}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  variant="rect"
                  width={110}
                  height={32}
                  style={{ borderRadius: 90 }}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  variant="rect"
                  width={100}
                  height={32}
                  style={{ borderRadius: 90 }}
                />
              </Grid>
              <Grid item>
                <Skeleton
                  variant="rect"
                  width={106}
                  height={32}
                  style={{ borderRadius: 90 }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rect" width={"100%"} height={218} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rect" width={"100%"} height={218} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rect" width={"100%"} height={218} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width={"100%"} height={216} />
          </Grid>
          <Grid item xs={12}>
            <Typography display="block" variant="h6">
              Recent Payments{" "}
              <Chip
                label="Export"
                icon={<CloudDownload />}
                onClick={this.export.bind(this)}
              />
            </Typography>
            <div style={{ paddingTop: 8 }}>
              <RecentPaymentList
                payments={paymentsRecent.sort(
                  (a, b) =>
                    moment(b.paymentDate).valueOf() -
                    moment(a.paymentDate).valueOf()
                )}
                onSelect={() => console.log("Select")}
                history={history}
                cookies={cookies}
                impersonate={impersonate}
              />
            </div>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography display="block" variant="h6">
            Dashboard
          </Typography>
          <Grid container spacing={1}>
            <Grid item>
              <Chip
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ range: "week" })}
                variant={range === "week" ? "container" : "outlined"}
                color={range === "week" ? "primary" : undefined}
                label="Week to Date"
              />
            </Grid>
            <Grid item>
              <Chip
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ range: "month" })}
                variant={range === "month" ? "container" : "outlined"}
                color={range === "month" ? "primary" : undefined}
                label="Month To Date"
              />
            </Grid>
            <Grid item>
              <Chip
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ range: "year" })}
                variant={range === "year" ? "container" : "outlined"}
                color={range === "year" ? "primary" : undefined}
                label="Year To Date"
              />
            </Grid>
            <Grid item>
              <Chip
                style={{ cursor: "pointer" }}
                onClick={() => this.setState({ range: "previous" })}
                variant={range === "previous" ? "container" : "outlined"}
                color={range === "previous" ? "primary" : undefined}
                label="Previous Year"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Widget
            icon={<AttachMoney style={{ color: "white" }} />}
            title={"Revenues"}
            subtitle={"USD"}
            color={"#3f51b5"}
            getValue={this.getRevenue.bind(this)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Widget
            icon={<CompareArrows style={{ color: "white" }} />}
            title={"Number of Transactions"}
            subtitle={"Valid Transaction(s)"}
            color={"#2196f3"}
            getValue={this.getPulses.bind(this)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Widget
            icon={<SettingsBackupRestore style={{ color: "white" }} />}
            title={"Number of Refunds"}
            subtitle={"Refund(s)"}
            color={"#00bcd4"}
            getValue={this.getRefunds.bind(this)}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {dashboard[range] && dashboard[range].length ? (
              <DatetimeGraph
                data={dashboard[range].sort(
                  (a, b) =>
                    moment(b.paymentDate).valueOf() -
                    moment(a.paymentDate).valueOf()
                )}
                key={range}
                height={200}
                dateKey={"paymentDate"}
                dataKey={"total"}
                color={"#2196f3"}
              />
            ) : (
              []
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography display="block" variant="h6">
            Recent Payments{" "}
            <Chip
              label="Export"
              icon={<CloudDownload />}
              onClick={this.export.bind(this)}
            />
          </Typography>
          <div style={{ paddingTop: 8 }}>
            <RecentPaymentList
              payments={paymentsRecent.sort(
                (a, b) =>
                  moment(b.paymentDate).valueOf() -
                  moment(a.paymentDate).valueOf()
              )}
              onSelect={() => console.log("Select")}
              history={history}
              cookies={cookies}
              impersonate={impersonate}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(withStyles(styles)(PaymentsDashboard));
