import subscriptions from "./subscriptions";
import searchPresets from "./searchPresets";
import events from "./events";
import tickets from "./tickets";
import shelveGoods from "./shelveGoods";
import banners from "./banners";
import shelves from "./shelves";
import aisles from "./aisles";
import markets from "./markets";
import categories from "./categories";
import contacts from "./contacts";
import settings from "./settings";
import invoices from "./invoices";
import organizations from "./organizations";
import statements from "./statements";
import pricings from "./pricings";
import customers from "./customers";
import projects from "./projects";
import payments from "./payments";
import paymentsRecent from "./paymentsRecent";
import carts from "./carts";
import coupons from "./coupons";
import activities from "./activities";
import goods from "./goods";
import rules from "./rules";
import roles from "./roles";
import groups from "./groups";
import users from "./users";
import errors from "./errors";
import app from "./app";
import user from "./user";
import userApps from "./userApps";
import serviceNames from "./serviceNames";
import ticketTemplates from "./ticketTemplates";
import { combineReducers } from "redux"; //eslint-disable-line
export default combineReducers({
  errors,
  user,
  app,
  users,
  groups,
  roles,
  rules,
  userApps,
  serviceNames,
  goods,
  activities,
  coupons,
  carts,
  payments,
  paymentsRecent,
  projects,
  customers,
  pricings,
  statements,
  invoices,
  organizations,
  settings,
  contacts,
  categories,
  markets,
  aisles,
  shelves,
  banners,
  shelveGoods,
  tickets,
  events,
  ticketTemplates,
  searchPresets,
  subscriptions,
});
