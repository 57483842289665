import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperProjectPage from "../component/WrapperProjectPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  projects: state.projects,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: () => dispatch(Actions.getProjects()),
  getProjectById: (...args) => dispatch(Actions.getProjectById(...args)),
  createProject: (...args) => dispatch(Actions.createProject(...args)),
  deleteProject: (...args) => dispatch(Actions.deleteProject(...args)),
  updateProject: (...args) => dispatch(Actions.updateProject(...args)),
});
class ProjectPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    projects: PropTypes.array,
    getProjects: PropTypes.func,
    getProjectById: PropTypes.func,
    createProject: PropTypes.func,
    deleteProject: PropTypes.func,
    updateProject: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, projects } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: projects.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getProjects } = this.props;
    await Promise.all([getProjects()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      projects,
      getProjectById,
      createProject,
      deleteProject,
      updateProject,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperProjectPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        projects={projects}
        getProjectById={getProjectById}
        createProject={createProject}
        deleteProject={deleteProject}
        updateProject={updateProject}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
