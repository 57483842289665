import { networkAction } from "helpers/network/networkAction";

import {
  FETCH_GOOD,
  GET_GOODS,
  GET_GOOD_BY_ID,
  DELETE_GOOD,
  UPDATE_GOOD,
  CREATE_GOOD,
  GET_GOOD_SALES,
  UPLOAD_GOOD_MEDIUM,
  GET_GOOD_MEDIA,
} from "constants/goods";

import * as Api from "api";

export const getGoods = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_GOODS, Api.getGoods, [filters]);

export const fetchGood = (resourceURL) => async (dispatch) =>
  networkAction(dispatch, FETCH_GOOD, Api.fetchGood, [resourceURL]);

export const uploadGoodMedium = (id, file) => async (dispatch) =>
  networkAction(dispatch, UPLOAD_GOOD_MEDIUM, Api.uploadGoodMedium, [id, file]);

export const getGoodById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GOOD_BY_ID, Api.getGoodById, [id]);

export const getGoodMedia = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GOOD_MEDIA, Api.getGoodMedia, [id]);

export const getGoodSales = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GOOD_SALES, Api.getGoodSales, [id]);

export const deleteGood = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_GOOD, Api.deleteGood, [id]);

export const createGood = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_GOOD, Api.createGood, [body]);

export const updateGood = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_GOOD, Api.updateGood, [id, body]);
