import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const invoiceItems = `/${service}/invoiceItems`;

export function getInvoiceItems() {
  const url = `${invoiceItems}`;
  return {
    method: GET,
    url,
  };
}

export function getInvoiceItemById(id) {
  const url = `${invoiceItems}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateInvoiceItem(id, body) {
  const url = `${invoiceItems}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInvoiceItem(id) {
  const url = `${invoiceItems}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInvoiceItem(body) {
  const url = `${invoiceItems}`;
  return {
    method: POST,
    url,
    body,
  };
}
