import { networkAction } from "helpers/network/networkAction";

import {
  GET_SETTINGS,
  GET_SETTING_BY_ID,
  DELETE_SETTING,
  UPDATE_SETTING,
  CREATE_SETTING,
} from "constants/settings";

import * as Api from "api";

export const getSettings = () => async (dispatch) =>
  networkAction(dispatch, GET_SETTINGS, Api.getSettings, []);

export const getSettingById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_SETTING_BY_ID, Api.getSettingById, [id]);

export const deleteSetting = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_SETTING, Api.deleteSetting, [id]);

export const createSetting = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_SETTING, Api.createSetting, [body]);

export const updateSetting = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_SETTING, Api.updateSetting, [id, body]);
