import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ShoppingCart from "@material-ui/icons/ShoppingCartTwoTone";
// custom
import Table from "components/TablePagination";
// custom
import { CART } from "constants/routes";
import { buildForURL } from "helpers";
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CartsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    carts: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
  };

  onSelect(selectedCart) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedCart);
    }
  }

  render() {
    const { carts, history } = this.props;

    if (carts.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "calc(100vh - 140px)", textAlign: "center" }}
        >
          <Grid item>
            <ShoppingCart style={{ fontSize: 70 }} />
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              No carts
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              Customer does not have any carts.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={carts}
        meta={[
          {
            path: "id",
            numeric: false,
            render: () => <ShoppingCart />,
            width: 20,
          },
          {
            path: "creationDate",
            title: "Creation",
            numeric: false,
            transform: (t) => moment(t).parseZone().fromNow(),
            render: (t, d) => (
              <React.Fragment>
                <Typography display="block" variant="body2">
                  {t}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  {moment(d.creationDate).calendar()}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "subTotal",
            title: "Sub Total",
            numeric: false,
            transform: (t) => `USD ${formattedPrice(t)}`,
          },
          {
            path: "total",
            title: "Total",
            numeric: false,
            transform: (t) => `USD ${formattedPrice(t)}`,
          },
        ]}
        title={"Carts"}
        onRowSelect={(p) =>
          history.push({
            pathname: CART,
            search: buildForURL({
              id: p.id,
              back: `${window.location.pathname}${window.location.search}`,
            }),
          })
        }
      />
    );
  }
}

export default withStyles(styles)(CartsList);
