import { POST } from "constants/methods";

export function callback(project, body) {
  const url = `${project}/callbacks`;
  return {
    method: POST,
    url,
    body,
  };
}
