import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// components
import PricingCurrency from "components/PricingCurrency";

// constants
import { BILLING } from "constants/routes";

// styles
import styles from "./styles";

class PricingInfo extends Component {
  static propTypes = {
    classes: PropTypes.object,
    pricing: PropTypes.object,
    refresh: PropTypes.func,
    history: PropTypes.object,
    updatePricing: PropTypes.func,
    deletePricing: PropTypes.func,
    createPricingCurrency: PropTypes.func,
    updatePricingCurrency: PropTypes.func,
    deletePricingCurrency: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { pricing } = this.props;
    this.state = {
      ...pricing,
      renewalDiscount: pricing.renewalDiscount / 100,
      price: pricing.price / 100,
      lowPrice: pricing.lowPrice / 100,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async save(name, numeric) {
    const { updatePricing, refresh, pricing } = this.props;

    if (pricing[name] === this.state[name]) return;

    if (name === "renewalDiscount") {
      this.state.renewalDiscount = Math.round(
        Number(this.state.renewalDiscount) * 100
      );
    }

    if (name === "price") {
      this.state.price = Math.round(Number(this.state.price) * 100);
    }

    if (name === "lowPrice") {
      this.state.lowPrice = Math.round(Number(this.state.lowPrice) * 100);
    }

    this.setState({
      loading: true,
    });

    await updatePricing(pricing.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  deletePricing() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deletePricing, pricing, history } = this.props;
    await deletePricing(pricing.id);
    history.push(BILLING);
  }

  async newPricingCurrency() {
    const { createPricingCurrency, pricing, refresh } = this.props;
    await createPricingCurrency({
      currency: "USD",
      pricingID: pricing.id,
    });
    refresh();
  }

  render() {
    const { classes } = this.props;
    const {
      title,
      description,
      subscription,
      monthPeriod,
      renewalDiscount,
      flexible,
      price,
      lowPrice,
      maxQuantity,
      minQuantity,
      loading,
    } = this.state;

    return (
      <Grid
        container
        spacing={2}
        className={classes.container}
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography display="block" variant="h6">
                  Information
                </Typography>
                <Typography display="block" color="textSecondary">
                  Set Price's Important Values
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div style={{ width: "60%" }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Title:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("title");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("title")}
                      className={classes.inputBase}
                      defaultValue={title}
                      onChange={this.handleChange("title")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Description:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("description");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("description")}
                      className={classes.inputBase}
                      defaultValue={description}
                      onChange={this.handleChange("description")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Price:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("price", true);
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("price", true)}
                      className={classes.inputBase}
                      defaultValue={price}
                      onChange={this.handleChange("price")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Flexible:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={flexible}
                          onChange={(e) => {
                            this.setState({ flexible: e.target.checked }, () =>
                              this.save("flexible")
                            );
                          }}
                          value="active"
                          color="primary"
                        />
                      }
                      label="Flexible"
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Low Price:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("lowPrice", true);
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("lowPrice", true)}
                      className={classes.inputBase}
                      defaultValue={lowPrice}
                      onChange={this.handleChange("lowPrice")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Min Quantity:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("minQuantity", true);
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("minQuantity", true)}
                      className={classes.inputBase}
                      defaultValue={minQuantity}
                      onChange={this.handleChange("minQuantity")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Max Quantity:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("maxQuantity", true);
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("maxQuantity", true)}
                      className={classes.inputBase}
                      defaultValue={maxQuantity}
                      onChange={this.handleChange("maxQuantity")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Subscription:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={subscription}
                          onChange={(e) =>
                            this.setState(
                              { subscription: e.target.checked },
                              () => this.save("subscription")
                            )
                          }
                          value="active"
                          color="primary"
                        />
                      }
                      label="Subscription"
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Month Period:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("monthPeriod", true);
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("monthPeriod", true)}
                      className={classes.inputBase}
                      defaultValue={monthPeriod}
                      onChange={this.handleChange("monthPeriod")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Renewal Discount:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter")
                          this.save("renewalDiscount", true);
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("renewalDiscount", true)}
                      className={classes.inputBase}
                      defaultValue={renewalDiscount}
                      onChange={this.handleChange("renewalDiscount")}
                    />
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography
                  display="block"
                  variant="h6"
                  style={{ color: "#f44336" }}
                >
                  Danger Zone
                </Typography>
                <Typography display="block" color="textSecondary">
                  Delete the pricing
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <Button
                style={{
                  color: "white",
                  background: "#f44336",
                }}
                onClick={this.deletePricing.bind(this)}
              >
                Delete pricing
              </Button>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PricingInfo);
