import { GET_MARKETS } from "constants/markets";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_MARKETS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
