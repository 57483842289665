import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Calendar from "@material-ui/icons/CalendarTodayTwoTone";
import Money from "@material-ui/icons/AttachMoneyTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { formattedPrice } from "helpers/stripe";

// constants
import { CUSTOMERS } from "constants/routes";

// styles
import styles from "./styles";

class CustomerInfo extends Component {
  static propTypes = {
    classes: PropTypes.object,
    customer: PropTypes.object,
    contacts: PropTypes.array,
    subscriptions: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { customer } = this.props;
    this.state = {
      fullName: customer.fullName,
      email: customer.email,
      address1: customer.address1,
      address2: customer.address2,
      subscriptionID: customer.subscriptionID,
      city: customer.city,
      zip: customer.zip,
      state: customer.state,
      country: customer.country,
      taxNumber: customer.taxNumber,
      companyName: customer.companyName,
      phone: customer.phone,
      autoPayment: customer.autoPayment,
      subscriber: customer.subscriber,
      total: 0,
      loading: false,
    };
  }

  componentDidMount() {
    this.getTotalPurchase();
    this.getLastPayment();
    this.getUnpaidInvoices();
  }

  getTotalPurchase() {
    const { customer } = this.props;
    const { payments } = customer;
    let total = 0;
    if (payments && payments.length) {
      for (const key in payments) {
        if (payments.hasOwnProperty(key)) {
          const payment = payments[key];
          if (payment.paid && !payment.refunded && payment.total) {
            total += payment.total;
          }
        }
      }
    }

    this.setState({ total });
  }

  getLastPayment() {
    const { customer } = this.props;
    const { payments } = customer;

    if (payments) {
      this.setState({ lastPayment: payments[payments.length - 1] });
    }
  }

  getUnpaidInvoices() {
    const { customer } = this.props;
    const { invoices } = customer;
    let unpaidInvoices = 0;

    for (const key in invoices) {
      if (invoices.hasOwnProperty(key)) {
        const invoice = invoices[key];
        if (!invoice.paid) {
          unpaidInvoices += 1;
        }
      }
    }
    this.setState({ unpaidInvoices });
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async save(name, numeric) {
    const { updateCustomer, refresh, customer } = this.props;

    if (customer[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateCustomer(customer.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  deleteCustomer() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteCustomer, customer, history } = this.props;
    await deleteCustomer(customer.id);
    history.push(CUSTOMERS);
  }

  render() {
    const { classes, customer, contacts, subscriptions } = this.props;
    const {
      total,
      lastPayment,
      unpaidInvoices,
      fullName,
      email,
      address1,
      address2,
      city,
      zip,
      state,
      country,
      loading,
      taxNumber,
      phone,
      companyName,
      autoPayment,
      subscriptionID,
      subscriber,
    } = this.state;

    return (
      <Grid
        style={{ marginLeft: -8 }}
        container
        spacing={2}
        className={classes.container}
        alignItems="stretch"
      >
        <Grid item xs={12} md={4}>
          <Paper
            className="card"
            style={{ color: "#4caf50", height: "calc(100% - 90px)" }}
          >
            <Paper className="cardHeader" style={{ background: "#4caf50" }}>
              <Money style={{ color: "white" }} />
            </Paper>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography display="block" variant="h6" color="inherit">
                  Total Purchase
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item>
                <Typography display="block" variant="h3">
                  USD {formattedPrice(total)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            className="card"
            style={{ color: "#8bc34a", height: "calc(100% - 90px)" }}
          >
            <Paper className="cardHeader" style={{ background: "#8bc34a" }}>
              <Calendar style={{ color: "white" }} />
            </Paper>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography display="block" variant="h6" color="inherit">
                  Last Invoice
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item>
                <Typography display="block" variant="h3">
                  {customer.invoices &&
                  customer.invoices[0] &&
                  customer.invoices[0].creationDate
                    ? moment(customer.invoices[0].creationDate).fromNow()
                    : "None"}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            className="card"
            style={{ color: "#cddc39", height: "calc(100% - 90px)" }}
          >
            <Paper className="cardHeader" style={{ background: "#cddc39" }}>
              <InsertDriveFile style={{ color: "white" }} />
            </Paper>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography display="block" variant="h6" color="inherit">
                  Unpaid Invoice(s)
                </Typography>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item>
                <Typography display="block" variant="h3">
                  {unpaidInvoices}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* <Grid item xs={12}>
          <Paper>
            <DatetimeGraph
              data={customer && customer.invoices ? customer.invoices : []}
              height={200}
              dateKey={'creationDate'}
              dataKey={'total'}
              color="#4caf50"
              price
            />
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography display="block" variant="h6">
                  Contact & Billing Info
                </Typography>
                <Typography display="block" color="textSecondary">
                  Contact, Full Name, Address & Email
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div style={{ width: "100%" }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Contact:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        inputProps={{
                          name: "Project",
                          id: "project-simple",
                        }}
                        value={customer.contactID}
                        onChange={(e) => {
                          this.state.contactID = e.target.value; // eslint-disable-line
                          this.save("contactID");
                        }}
                      >
                        {contacts.map((c) => (
                          <MenuItem key={`contact_${c.id}`} value={c.id}>
                            {c.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Company Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("companyName");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("companyName")}
                      className={classes.inputBase}
                      defaultValue={companyName}
                      onChange={this.handleChange("companyName")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Full Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("fullName");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("fullName")}
                      className={classes.inputBase}
                      defaultValue={fullName}
                      onChange={this.handleChange("fullName")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Email:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("email");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("email")}
                      className={classes.inputBase}
                      defaultValue={email}
                      onChange={this.handleChange("email")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Phone:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("phone");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("phone")}
                      className={classes.inputBase}
                      defaultValue={phone}
                      onChange={this.handleChange("phone")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Address Line 1:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("address1");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("address1")}
                      className={classes.inputBase}
                      defaultValue={address1}
                      onChange={this.handleChange("address1")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Address Line 2:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("address2");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("address2")}
                      className={classes.inputBase}
                      defaultValue={address2}
                      onChange={this.handleChange("address2")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      City:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("city");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("city")}
                      className={classes.inputBase}
                      defaultValue={city}
                      onChange={this.handleChange("city")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Zip:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("zip");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("zip")}
                      className={classes.inputBase}
                      defaultValue={zip}
                      onChange={this.handleChange("zip")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      State:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("state");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("state")}
                      className={classes.inputBase}
                      defaultValue={state}
                      onChange={this.handleChange("state")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Country:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("country");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("country")}
                      className={classes.inputBase}
                      defaultValue={country}
                      onChange={this.handleChange("country")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Tax Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("taxNumber");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("taxNumber")}
                      className={classes.inputBase}
                      defaultValue={taxNumber}
                      onChange={this.handleChange("taxNumber")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <Typography display="block" variant="body2">
                      Subscription:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl className={classes.formControl} fullWidth>
                      <Select
                        inputProps={{
                          name: "sub",
                          id: "sub-simple",
                        }}
                        value={subscriptionID}
                        onChange={(e) => {
                          this.state.subscriptionID = e.target.value; // eslint-disable-line
                          this.save("subscriptionID");
                        }}
                      >
                        {subscriptions.map((c) => (
                          <MenuItem key={`sub_${c.id}`} value={c.id}>
                            {c.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={autoPayment}
                          onChange={() => {
                            this.state.autoPayment = !autoPayment;
                            this.save("autoPayment");
                          }}
                          value="autoPayment"
                        />
                      }
                      label="Auto Payment"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={subscriber}
                          onChange={() => {
                            this.state.subscriber = !subscriber;
                            this.save("subscriber");
                          }}
                          value="subscriber"
                        />
                      }
                      label="Subscriber"
                    />
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography
                  display="block"
                  variant="h6"
                  style={{ color: "#f44336" }}
                >
                  Danger Zone
                </Typography>
                <Typography display="block" color="textSecondary">
                  Delete the customer
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <Button
                style={{
                  color: "white",
                  background: "#f44336",
                }}
                onClick={this.deleteCustomer.bind(this)}
              >
                Delete Customer
              </Button>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CustomerInfo);
