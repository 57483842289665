import { networkAction } from "helpers/network/networkAction";

import {
  UPLOAD_MEDIA,
  GET_MEDIA,
  GET_MEDIUM_BY_ID,
  DELETE_MEDIUM,
  UPDATE_MEDIUM,
  CREATE_MEDIUM,
} from "constants/media";

import * as Api from "api";

export const getMedia = () => async (dispatch) =>
  networkAction(dispatch, GET_MEDIA, Api.getMedia, []);

export const uploadMedia = (file) => async (dispatch) =>
  networkAction(dispatch, UPLOAD_MEDIA, Api.uploadMedia, [file]);

export const getMediumById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MEDIUM_BY_ID, Api.getMediumById, [id]);

export const deleteMedium = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MEDIUM, Api.deleteMedium, [id]);

export const createMedium = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MEDIUM, Api.createMedium, [body]);

export const updateMedium = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MEDIUM, Api.updateMedium, [id, body]);
