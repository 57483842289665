// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperInvoicePage from "../component/WrapperInvoicePage";

const mapStateToProps = (state) => ({
  goods: state.goods,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceById: (...args) => dispatch(Actions.getInvoiceById(...args)),
  getCustomerById: (...args) => dispatch(Actions.getCustomerById(...args)),
  updateInvoice: (...args) => dispatch(Actions.updateInvoice(...args)),
  updatePayment: (...args) => dispatch(Actions.updatePayment(...args)),
  deleteInvoiceItem: (...args) => dispatch(Actions.deleteInvoiceItem(...args)),
  createInvoiceItem: (...args) => dispatch(Actions.createInvoiceItem(...args)),
  updateInvoiceItem: (...args) => dispatch(Actions.updateInvoiceItem(...args)),
  createPayment: (...args) => dispatch(Actions.createPayment(...args)),
  refundPayment: (...args) => dispatch(Actions.refundPayment(...args)),
  getGoods: (...args) => dispatch(Actions.getGoods(...args)),
});
class InvoicePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getInvoiceById: PropTypes.func,
    getCustomerById: PropTypes.func,
    updateInvoice: PropTypes.func,
    deleteInvoice: PropTypes.func,
    createPayment: PropTypes.func,
    refundPayment: PropTypes.func,
    updatePayment: PropTypes.func,

    deleteInvoiceItem: PropTypes.func,
    createInvoiceItem: PropTypes.func,
    updateInvoiceItem: PropTypes.func,

    getGoods: PropTypes.func,
    goods: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, goods, getGoods } = this.props;
    const urlParams = getJsonFromUrl(location);
    if (!goods || !goods.length) {
      getGoods();
    }
    this.state = {
      urlParams,
      invoiceID: urlParams.id,
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { invoiceID } = this.state;
    const { getInvoiceById, getCustomerById } = this.props;
    const resps = await Promise.all([getInvoiceById(invoiceID)]);

    const cust = await getCustomerById(resps[0].payload.customerID);
    this.setState({
      invoice: resps[0].payload,
      loading: false,
      customer: cust.payload,
      refreshKey: Date.now(),
    });
  }

  render() {
    const {
      location,
      history,
      updateInvoice,
      deleteInvoice,
      deleteInvoiceItem,
      createInvoiceItem,
      updateInvoiceItem,
      refundPayment,
      updatePayment,
      createPayment,
      goods,
    } = this.props;

    const { urlParams, loading, invoice, customer, refreshKey } = this.state;

    return (
      <WrapperInvoicePage
        history={history}
        location={location}
        urlParams={urlParams}
        invoice={invoice}
        customer={customer}
        loading={loading}
        updatePayment={updatePayment}
        refundPayment={refundPayment}
        updateInvoice={updateInvoice}
        deleteInvoice={deleteInvoice}
        deleteInvoiceItem={deleteInvoiceItem}
        createInvoiceItem={createInvoiceItem}
        updateInvoiceItem={updateInvoiceItem}
        createPayment={createPayment}
        refreshKey={refreshKey}
        goods={goods}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InvoicePage);
