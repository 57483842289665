import { networkAction } from "helpers/network/networkAction";

import {
  GET_COUPON_ACTIVITIES,
  GET_COUPON_ACTIVITY_BY_ID,
  DELETE_COUPON_ACTIVITY,
  UPDATE_COUPON_ACTIVITY,
  CREATE_COUPON_ACTIVITY,
} from "constants/couponActivities";

import * as Api from "api";

export const getCouponActivities = () => async (dispatch) =>
  networkAction(dispatch, GET_COUPON_ACTIVITIES, Api.getCouponActivities, []);

export const getCouponActivityById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_COUPON_ACTIVITY_BY_ID,
    Api.getCouponActivityById,
    [id]
  );

export const deleteCouponActivity = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_COUPON_ACTIVITY, Api.deleteCouponActivity, [
    id,
  ]);

export const createCouponActivity = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_COUPON_ACTIVITY, Api.createCouponActivity, [
    body,
  ]);

export const updateCouponActivity = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_COUPON_ACTIVITY, Api.updateCouponActivity, [
    id,
    body,
  ]);
