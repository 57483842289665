import { networkAction } from "helpers/network/networkAction";

import {
  GET_RULES,
  GET_RULE_BY_ID,
  DELETE_RULE,
  UPDATE_RULE,
  CREATE_RULE,
} from "constants/rules";

import * as Api from "api";

export const getRules = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_RULES, Api.getRules, [filters]);

export const getRuleById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_RULE_BY_ID, Api.getRuleById, [id]);

export const deleteRule = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_RULE, Api.deleteRule, [id]);

export const createRule = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_RULE, Api.createRule, [body]);

export const updateRule = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_RULE, Api.updateRule, [id, body]);
