import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AdvancedSearch from "components/AdvancedSearchRefactor";
import FormPayment from "components/FormPayment";
// components
import Page from "components/Page";
import PaymentsDashboard from "components/PaymentsDashboard";
import RecentPaymentList from "components/RecentPaymentList";
import { updateURLParameter } from "helpers";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withCookies } from "react-cookie";
// styles
import styles from "./styles";

class WrapperPaymentPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    getCustomers: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    payments: PropTypes.array,
    paymentsRecent: PropTypes.array,
    createPayment: PropTypes.func,
    deletePayment: PropTypes.func,
    updatePayment: PropTypes.func,
    cookies: PropTypes.object,
    projects: PropTypes.array,
    coupons: PropTypes.object,
    goods: PropTypes.object,
    customers: PropTypes.object,
    impersonate: PropTypes.func,
    getGoods: PropTypes.func,
    getPayments: PropTypes.func,
    getProjects: PropTypes.func,
    getCoupons: PropTypes.func,
    dashboard: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedPayment: undefined,
      formPaymentOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      payments,
      history,
      paymentsRecent,
      impersonate,
      cookies,
      coupons,
      projects,
      goods,
      customers,
      getPayments,
      dashboard,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <PaymentsDashboard
            payments={payments}
            history={history}
            dashboard={dashboard}
            impersonate={impersonate}
            paymentsRecent={paymentsRecent}
          />
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AdvancedSearch
                history={history}
                location={window.location}
                refresh={getPayments}
                keyword="payments"
                rowCount={payments.length}
                metaDataEndpoint="/bazaar/payments/searchMetadata"
                exportEndpoint="/bazaar/payments/csv"
                metaDataOveride={{
                  couponID: {
                    type: "autocomplete",
                    data: coupons,
                    valueKey: "id",
                    labelKey: "token",
                  },
                  goodID: {
                    type: "autocomplete",
                    data: goods,
                    valueKey: "id",
                    labelKey: "title",
                  },
                  customerID: {
                    type: "autocomplete",
                    data: customers,
                    valueKey: "id",
                    labelKey: "fullName",
                  },
                  projectID: {
                    type: "autocomplete",
                    data: projects,
                    valueKey: "id",
                    labelKey: "name",
                  },
                }}
              >
                <RecentPaymentList
                  payments={payments.sort(
                    (a, b) =>
                      moment(b.paymentDate).valueOf() -
                      moment(a.paymentDate).valueOf()
                  )}
                  onSelect={() => console.log("Select")}
                  history={history}
                  cookies={cookies}
                  impersonate={impersonate}
                />
              </AdvancedSearch>
            </Grid>
          </Grid>
        );
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createPayment,
      deletePayment,
      updatePayment,
      customer,
      getPayments,
      refresh,
    } = this.props;

    const { index, selectedPayment, formPaymentOpen } = this.state;

    return (
      <Page
        helmet="Payments"
        loading={loading}
        loadingMessage={"Fetching all Payments"}
        tabs={
          <React.Fragment>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push({
                  search: updateURLParameter("index", v),
                });
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="Dashboard"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="All Records"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </React.Fragment>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
        <FormPayment
          open={formPaymentOpen}
          payment={selectedPayment}
          customer={customer}
          close={() => {
            this.setState({
              formPaymentOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedPayment: undefined,
                }),
              200
            );
          }}
          createPayment={createPayment}
          deletePayment={deletePayment}
          updatePayment={updatePayment}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withCookies(withStyles(styles)(WrapperPaymentPage));
