import { networkAction } from "helpers/network/networkAction";

import {
  GET_BUNDLES,
  GET_BUNDLE_BY_ID,
  DELETE_BUNDLE,
  UPDATE_BUNDLE,
  CREATE_BUNDLE,
} from "constants/bundles";

import * as Api from "api";

export const getBundles = () => async (dispatch) =>
  networkAction(dispatch, GET_BUNDLES, Api.getBundles, []);

export const getBundleById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_BUNDLE_BY_ID, Api.getBundleById, [id]);

export const deleteBundle = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_BUNDLE, Api.deleteBundle, [id]);

export const createBundle = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_BUNDLE, Api.createBundle, [body]);

export const updateBundle = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_BUNDLE, Api.updateBundle, [id, body]);
