export default (theme) => ({
  container: {
    padding: 24,
  },
  userImage: {
    width: "100%",
    textAlign: "center",
    paddingTop: 20,
  },
  badge: {
    margin: 10,
    float: "left",
  },
});
