import { networkAction } from "helpers/network/networkAction";

import {
  GET_INVOICES,
  GET_INVOICE_PREVIEW,
  GET_INVOICE_BY_ID,
  DELETE_INVOICE,
  UPDATE_INVOICE,
  CREATE_INVOICE,
} from "constants/invoices";

import * as Api from "api";

export const getInvoices = () => async (dispatch) =>
  networkAction(dispatch, GET_INVOICES, Api.getInvoices, []);

export const getInvoiceById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_INVOICE_BY_ID, Api.getInvoiceById, [id]);

export const getInvoicePreview = (id) => async (dispatch) =>
  networkAction(dispatch, GET_INVOICE_PREVIEW, Api.getInvoicePreview, [id]);

export const deleteInvoice = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_INVOICE, Api.deleteInvoice, [id]);

export const createInvoice = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_INVOICE, Api.createInvoice, [body]);

export const updateInvoice = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_INVOICE, Api.updateInvoice, [id, body]);
