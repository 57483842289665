import { GET, PUT, DELETE, POST, DATA } from "constants/methods";

const service = "bazaar";
const events = `/${service}/events`;

export function getEvents() {
  const url = `${events}`;
  return {
    method: GET,
    url,
  };
}

export function createEventMedia(id, file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${events}/${id}/media`;

  return {
    method: DATA,
    url,
    body: data,
  };
}

export function getEventById(id) {
  const url = `${events}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateEvent(id, body) {
  const url = `${events}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteEvent(id) {
  const url = `${events}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createEvent(body) {
  const url = `${events}`;
  return {
    method: POST,
    url,
    body,
  };
}
