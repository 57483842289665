import { GET_COUPONS } from "constants/coupons";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_COUPONS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
