import { networkAction } from "helpers/network/networkAction";

import {
  GET_ACTIVITIES,
  GET_ACTIVITY_BY_ID,
  DELETE_ACTIVITY,
  UPDATE_ACTIVITY,
  CREATE_ACTIVITY,
} from "constants/activities";

import * as Api from "api";

export const getActivities = () => async (dispatch) =>
  networkAction(dispatch, GET_ACTIVITIES, Api.getActivities, []);

export const getActivityById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ACTIVITY_BY_ID, Api.getActivityById, [id]);

export const deleteActivity = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ACTIVITY, Api.deleteActivity, [id]);

export const createActivity = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ACTIVITY, Api.createActivity, [body]);

export const updateActivity = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ACTIVITY, Api.updateActivity, [id, body]);
