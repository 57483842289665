export default (theme) => ({
  appGrid: {
    height: 47,
  },
  AppBarGreetings: {
    color: "white",
    padding: 12,
  },
  white: {
    color: "white",
  },
  appBar: {
    background:
      theme.palette.type === "dark" ? "#424242" : theme.palette.secondary[800],
  },
});
