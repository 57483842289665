import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperCategoriePage from "../component/WrapperCategoriePage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: () => dispatch(Actions.getCategories()),
  getCategoryById: (...args) => dispatch(Actions.getCategoryById(...args)),
  createCategory: (...args) => dispatch(Actions.createCategory(...args)),
  deleteCategory: (...args) => dispatch(Actions.deleteCategory(...args)),
  updateCategory: (...args) => dispatch(Actions.updateCategory(...args)),
});
class CategoriePage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    categories: PropTypes.array,
    getCategories: PropTypes.func,
    getCategoryById: PropTypes.func,
    createCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    updateCategory: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, categories } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: categories.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getCategories } = this.props;
    await Promise.all([getCategories()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      categories,
      getCategoryById,
      createCategory,
      deleteCategory,
      updateCategory,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCategoriePage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        categories={categories}
        getCategoryById={getCategoryById}
        createCategory={createCategory}
        deleteCategory={deleteCategory}
        updateCategory={updateCategory}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoriePage);
