import { networkAction } from "helpers/network/networkAction";

import {
  GET_PROJECTS,
  GET_PROJECT_BY_ID,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  CREATE_PROJECT,
} from "constants/projects";

import * as Api from "api";

export const getProjects = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECTS, Api.getProjects, [filters]);

export const getProjectById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PROJECT_BY_ID, Api.getProjectById, [id]);

export const deleteProject = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PROJECT, Api.deleteProject, [id]);

export const createProject = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PROJECT, Api.createProject, [body]);

export const updateProject = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PROJECT, Api.updateProject, [id, body]);
