import { networkAction } from "helpers/network/networkAction";

import {
  GET_TICKET_TEMPLATES,
  GET_TICKET_TEMPLATE_BY_ID,
  DELETE_TICKET_TEMPLATE,
  UPDATE_TICKET_TEMPLATE,
  CREATE_TICKET_TEMPLATE,
} from "constants/ticketTemplates";

import * as Api from "api";

export const getTicketTemplates = () => async (dispatch) =>
  networkAction(dispatch, GET_TICKET_TEMPLATES, Api.getTicketTemplates, []);

export const getTicketTemplateById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_TICKET_TEMPLATE_BY_ID,
    Api.getTicketTemplateById,
    [id]
  );

export const deleteTicketTemplate = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TICKET_TEMPLATE, Api.deleteTicketTemplate, [
    id,
  ]);

export const createTicketTemplate = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TICKET_TEMPLATE, Api.createTicketTemplate, [
    body,
  ]);

export const updateTicketTemplate = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TICKET_TEMPLATE, Api.updateTicketTemplate, [
    id,
    body,
  ]);
