import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import InputBase from "@material-ui/core/InputBase";

// styles
import styles from "./styles";

class PricingCurrency extends Component {
  static propTypes = {
    classes: PropTypes.object,
    pricingCurrency: PropTypes.func,
    updatePricingCurrency: PropTypes.func,
    deletePricingCurrency: PropTypes.func,
    refresh: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { pricingCurrency } = this.props;
    this.state = {
      ...pricingCurrency,
      price: pricingCurrency.price / 100,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async save(name, numeric) {
    const { updatePricingCurrency, refresh, pricingCurrency } = this.props;

    if (pricingCurrency[name] === this.state[name]) return;

    if (name === "price") {
      this.state.price = Math.round(Number(this.state.price) * 100);
    }

    this.setState({
      loading: true,
    });

    await updatePricingCurrency(pricingCurrency.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  deletePricingCurrency() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deletePricingCurrency, pricingCurrency, refresh } = this.props;
    await deletePricingCurrency(pricingCurrency.id);
    refresh();
  }

  render() {
    const { classes } = this.props;

    const { price, currency, loading } = this.state;

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <InputBase
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("price", true);
              }}
              disabled={loading}
              fullWidth
              onBlur={() => this.save("price", true)}
              className={classes.inputBase}
              defaultValue={price}
              onChange={this.handleChange("price", true)}
            />
          </Grid>
          <Grid item style={{ width: 80 }}>
            <InputBase
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("currency");
              }}
              disabled={loading}
              onBlur={() => this.save("currency")}
              className={classes.inputBase}
              defaultValue={currency}
              onChange={this.handleChange("currency")}
            />
          </Grid>
          <Grid item style={{ width: 60 }}>
            <IconButton onClick={this.confirmDelete.bind(this)}>
              <Close fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(PricingCurrency);
