import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ScheduleIcon from "@material-ui/icons/Schedule";
// import PropTypes from 'prop-types';
// material-ui
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment-timezone";
import React from "react";

export default function DateTime({
  date,
  onChange,
  onBlur,
  disabled,
  tooltip,
  error,
  tzName,
  onKeyPress,
  helperText,
  noTime,
  noDate,
  noAt,
  at,
}) {
  const tz = tzName || moment.tz.guess();
  const _date = moment.tz(date, tz);

  const isFirstRun = React.useRef(true);
  const [d, setDate] = React.useState(date ? _date : null);
  const [t, setTime] = React.useState(date ? _date : null);

  React.useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    setDate(moment.tz(date, tz));
    setTime(moment.tz(date, tz));
  }, [date, tz]);

  const commit = (e) => {
    if (onChange) {
      onChange({
        target: {
          value: moment.tz(e, tz).utc().format(),
        },
      });
      setTimeout(onBlur, 200);
    }
  };

  const handleDateChange = (e) => {
    setDate(e);

    if (e === null) return;
    let _d = e.format("YYYY-MM-DD");

    if (_d === "Invalid date") return;

    let _t = "00:00";
    if (t) {
      _t = t.format("HH:mm");

      if (_t === "Invalid date") {
        _t = "00:00";
      }
    }

    commit(`${_d}T${_t}:00`);
  };

  const handleTimeChange = (e) => {
    setTime(e);

    if (e === null) return;
    let _t = e.format("HH:mm");

    if (_t === "Invalid date") return;

    let _d = moment().format("YYYY-MM-DD");
    if (d) {
      _d = d.format("YYYY-MM-DD");

      if (_d === "Invalid date") {
        _d = moment().format("YYYY-MM-DD");
      }
    }

    commit(`${_d}T${_t}:00`);
  };

  const JSX = (
    <Grid container alignItems="center">
      {noDate ? (
        []
      ) : (
        <Grid item style={{ paddingRight: "4px" }}>
          <KeyboardDatePicker
            onKeyPress={onKeyPress}
            inputVariant="outlined"
            variant="inline"
            value={d}
            error={error}
            placeholder="MM/DD/YYYY"
            autoOk
            disabled={disabled}
            helperText={helperText}
            onChange={handleDateChange}
            showTodayButton
            size="small"
            style={{ width: 170 }}
            keyboardIcon={<CalendarTodayIcon style={{ fontSize: 20 }} />}
            KeyboardButtonProps={{ size: "small" }}
            format="MM/DD/YYYY"
          />
        </Grid>
      )}
      {noTime ? (
        []
      ) : (
        <>
          {!noAt ? (
            <Grid item style={{ padding: "0px 4px" }}>
              <Typography variant="body2">{at || "at"}</Typography>
            </Grid>
          ) : (
            []
          )}
          <Grid item style={{ paddingLeft: "4px" }}>
            <KeyboardTimePicker
              onKeyPress={onKeyPress}
              variant="inline"
              helperText={helperText}
              error={error}
              inputVariant="outlined"
              value={t}
              placeholder="HH:MM AM"
              autoOk
              disabled={disabled}
              showTodayButton
              size="small"
              style={{ width: 150 }}
              keyboardIcon={<ScheduleIcon style={{ fontSize: 20 }} />}
              KeyboardButtonProps={{ size: "small" }}
              onChange={handleTimeChange}
            />
          </Grid>
        </>
      )}
    </Grid>
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {tooltip ? <Tooltip title={tooltip}>{JSX}</Tooltip> : JSX}
    </MuiPickersUtilsProvider>
  );
}
