import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/InputBase";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Grow from "@material-ui/core/Grow";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import Drag from "@material-ui/icons/DragIndicator";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import { PLACEHOLDER } from "config";

// custom
import ShelveGoods from "components/ShelveGoods";

// styles
import styles from "./styles";

class Shelve extends Component {
  static propTypes = {
    classes: PropTypes.object,
    edit: PropTypes.bool,
    shelve: PropTypes.object,
    updateShelve: PropTypes.func,
    deleteShelve: PropTypes.func,
    createShelveGood: PropTypes.func,
    deleteShelveGood: PropTypes.func,
    updateShelveGood: PropTypes.func,
    refresh: PropTypes.func,
    goods: PropTypes.array,
    alt: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { shelve } = this.props;
    this.state = {
      ...shelve,
      loading: false,
      anchorEl: null,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  async save(name, numeric) {
    const { updateShelve, refresh, shelve } = this.props;

    if (shelve[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await updateShelve(shelve.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  delete(e) {
    const { NotificationCenter } = this.context;
    e.preventDefault();
    e.stopPropagation();
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteShelve, shelve, refresh } = this.props;
    await deleteShelve(shelve.id);
    refresh();
  }

  render() {
    const {
      classes,
      createShelveGood,
      deleteShelveGood,
      updateShelveGood,
      shelve,
      refresh,
      edit,
      alt,
      goods,
    } = this.props;

    const { name, description, loading, anchorEl } = this.state;

    const goodsUserCanAdd = goods.filter((g) =>
      shelve.goods
        ? shelve.goods.findIndex((sg) => sg.id === g.id) === -1
        : true
    );

    return (
      <div>
        <Grid container justify="space-between">
          {alt && edit ? (
            <Grow in>
              <Grid item>
                <Drag style={{ cursor: "grab" }} />
              </Grid>
            </Grow>
          ) : (
            []
          )}
          <Grid item style={{ flexGrow: 1 }}>
            <InputBase
              fullWidth
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("name");
              }}
              disabled={loading || edit !== true}
              onBlur={() => this.save("name")}
              value={name}
              onChange={this.handleChange("name")}
              placeholder="Shelf's Name"
              classes={{
                root: classes.h6,
                input: classes.input,
                disabled: classes.disabled,
              }}
            />
            <InputBase
              fullWidth
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("name");
              }}
              disabled={loading || edit !== true}
              onBlur={() => this.save("description")}
              value={description}
              onChange={this.handleChange("description")}
              placeholder="Shelf's Description"
              classes={{
                root: classes.subheading,
                input: classes.input,
                disabled: classes.disabled,
              }}
            />
          </Grid>
          {edit ? (
            <Grid item>
              <Chip
                avatar={
                  <Avatar style={{ background: "none" }}>
                    <Delete />
                  </Avatar>
                }
                label={"Shelf"}
                variant="outlined"
                onClick={this.delete.bind(this)}
                style={{
                  zIndex: 9999,
                }}
              />
            </Grid>
          ) : (
            []
          )}
          {edit ? (
            <Grid item xs={12}>
              <Chip
                aria-owns={anchorEl ? "simple-menu" : undefined}
                avatar={
                  <Avatar style={{ background: "none" }}>
                    <Add />
                  </Avatar>
                }
                label={"Good"}
                variant="outlined"
                style={{
                  zIndex: 9999,
                }}
                onClick={this.handleClick}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                {goodsUserCanAdd.map((g) => (
                  <MenuItem
                    onClick={async () => {
                      this.handleClose();
                      await createShelveGood({
                        goodID: g.id,
                        shelfID: shelve.id,
                      });
                      refresh();
                    }}
                  >
                    <ListItemIcon>
                      <img
                        src={
                          g.pictures && g.pictures.length
                            ? g.pictures[0].url
                            : PLACEHOLDER
                        }
                        alt={"good"}
                        style={{
                          width: 40,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography display="block" variant="body2">
                        {g.title}
                      </Typography>
                      <Typography
                        display="block"
                        variant="caption"
                        color="textSecondary"
                      >
                        {g.description && g.description.length > 100
                          ? `${g.description.substring(0, 100)}...`
                          : g.description}
                      </Typography>
                    </ListItemText>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          ) : (
            []
          )}
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <ShelveGoods
              goods={shelve.goods}
              createShelveGood={createShelveGood}
              deleteShelveGood={deleteShelveGood}
              updateShelveGood={updateShelveGood}
              refresh={refresh}
              alt={alt}
              edit={edit}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "10px 0px 0px 0px" }}>
            <Divider />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Shelve);
