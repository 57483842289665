import red from "@material-ui/core/colors/red";

export default (theme) => ({
  container: {
    padding: 5,
  },
  delete: {
    color: red[500],
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  userImage: {
    width: "100%",
    textAlign: "center",
    paddingTop: 20,
  },
  textField: {
    width: "100%",
  },
  progressContainer: {
    textAlign: "center",
    padding: 50,
  },
  dialogActions: {
    justifyContent: "space-between",
  },
  default: {
    background: theme.palette.background.default,
    borderRadius: 4,
    border: "solid 1px rgba(155,155,155,0.3)",
    marginTop: 20,
    padding: 20,
  },
});
