import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const pricingCurrencies = `/${service}/pricingCurrencies`;

export function getPricingCurrencies() {
  const url = `${pricingCurrencies}`;
  return {
    method: GET,
    url,
  };
}

export function getPricingCurrencyById(id) {
  const url = `${pricingCurrencies}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updatePricingCurrency(id, body) {
  const url = `${pricingCurrencies}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePricingCurrency(id) {
  const url = `${pricingCurrencies}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPricingCurrency(body) {
  const url = `${pricingCurrencies}`;
  return {
    method: POST,
    url,
    body,
  };
}
