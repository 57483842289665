import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperPaymentPage from "../component/WrapperPaymentPage";

const mapStateToProps = (state) => ({
  projects: state.projects,
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentById: (...args) => dispatch(Actions.getPaymentById(...args)),
  getCartById: (...args) => dispatch(Actions.getCartById(...args)),
  updatePayment: (...args) => dispatch(Actions.updatePayment(...args)),
  refundPayment: (...args) => dispatch(Actions.refundPayment(...args)),
  getProjects: (...args) => dispatch(Actions.getProjects(...args)),
  callback: (...args) => dispatch(Actions.callback(...args)),
});
class PaymentPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    getCartById: PropTypes.func,
    getPaymentById: PropTypes.func,
    updatePayment: PropTypes.func,
    refundPayment: PropTypes.func,
    projects: PropTypes.array,
    getProjects: PropTypes.func,
    callback: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, getProjects } = this.props;
    const urlParams = getJsonFromUrl(location);
    getProjects();
    this.state = {
      urlParams,
      paymentID: urlParams.id,
      loading: true,
      payment: {},
      cart: {},
    };
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getPaymentById } = this.props;
    const { paymentID } = this.state;

    const resp0 = await getPaymentById(paymentID);
    const payment = resp0.payload;
    this.setState({
      loading: false,
      payment,
    });
  }

  render() {
    const {
      location,
      history,
      updatePayment,
      refundPayment,
      projects,
      callback,
    } = this.props;

    const { urlParams, payment, loading } = this.state;

    return (
      <WrapperPaymentPage
        history={history}
        location={location}
        urlParams={urlParams}
        updatePayment={updatePayment}
        payment={payment}
        refundPayment={refundPayment}
        loading={loading}
        projects={projects}
        callback={callback}
        refresh={this.refresh.bind(this)}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
