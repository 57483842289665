import { networkAction } from "helpers/network/networkAction";

import {
  GET_EVENTS,
  GET_EVENT_BY_ID,
  DELETE_EVENT,
  UPDATE_EVENT,
  CREATE_EVENT,
  CREATE_EVENT_MEDIA,
} from "constants/events";

import * as Api from "api";

export const getEvents = () => async (dispatch) =>
  networkAction(dispatch, GET_EVENTS, Api.getEvents, []);

export const createEventMedia = (id, file) => async (dispatch) =>
  networkAction(dispatch, CREATE_EVENT_MEDIA, Api.createEventMedia, [id, file]);

export const getEventById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_EVENT_BY_ID, Api.getEventById, [id]);

export const deleteEvent = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_EVENT, Api.deleteEvent, [id]);

export const createEvent = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_EVENT, Api.createEvent, [body]);

export const updateEvent = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_EVENT, Api.updateEvent, [id, body]);
