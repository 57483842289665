import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const aisles = `/${service}/aisles`;

export function getAisles() {
  const url = `${aisles}`;
  return {
    method: GET,
    url,
  };
}

export function getAisleById(id) {
  const url = `${aisles}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateAisle(id, body) {
  const url = `${aisles}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteAisle(id) {
  const url = `${aisles}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createAisle(body) {
  const url = `${aisles}`;
  return {
    method: POST,
    url,
    body,
  };
}
