export default (theme) => ({
  container: {},
  paper: {
    padding: 20,
    position: "relative",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  inputBase: {
    fontSize: "0.875rem",
    background: theme.palette.background.default,
    padding: 2,
    border: "solid 1px #cfd8dc",
    borderRadius: 4,
  },
});
