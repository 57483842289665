import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "account";
const groups = `${REACT_APP_API_PREFIX}/${service}/groups`;
const groupRoles = `${REACT_APP_API_PREFIX}/${service}/groupRoles`;

export function getGroups() {
  const url = `${groups}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function getGroupById(id) {
  const url = `${groups}/${id}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function getGroupRoles(id) {
  const url = `${groups}/${id}/roles`;
  return {
    service,
    method: GET,
    url,
  };
}

export function createGroupRole(id, body) {
  const url = `${groups}/${id}/roles`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}

export function deleteGroupRole(id, roleID) {
  const url = `${groupRoles}/${roleID}`;
  return {
    service,
    method: DELETE,
    url,
  };
}

export function updateGroup(id, body) {
  const url = `${groups}/${id}`;
  return {
    service,
    method: PUT,
    url,
    body,
  };
}

export function deleteGroup(id) {
  const url = `${groups}/${id}`;
  return {
    service,
    method: DELETE,
    url,
  };
}

export function createGroup(body) {
  const url = `${groups}`;
  return {
    service,
    method: POST,
    url,
    body,
  };
}
