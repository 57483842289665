import { SUBSCRIPTIONS } from "constants/routes";
import { checkAccess } from "helpers/auth";
import { serviceRoles } from "config/access";
import SubscriptionsPage from "./container/SubscriptionsPage";

export default function getRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
  };
  return {
    path: SUBSCRIPTIONS,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: SubscriptionsPage,
  };
}
