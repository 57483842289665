import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperGoodPage from "../component/WrapperGoodPage";

const mapStateToProps = (state) => ({
  pricings: state.pricings,
  goods: state.goods,
  categories: state.categories,
  projects: state.projects,
  activities: state.activities,
});

const mapDispatchToProps = (dispatch) => ({
  getGoodById: (...args) => dispatch(Actions.getGoodById(...args)),
  getGoodSales: (...args) => dispatch(Actions.getGoodSales(...args)),
  uploadGoodMedium: (...args) => dispatch(Actions.uploadGoodMedium(...args)),
  updateGood: (...args) => dispatch(Actions.updateGood(...args)),
  getGoods: (...args) => dispatch(Actions.getGoods(...args)),
  deleteGood: (...args) => dispatch(Actions.deleteGood(...args)),
  createPricingCurrency: (...args) =>
    dispatch(Actions.createPricingCurrency(...args)),
  updatePricingCurrency: (...args) =>
    dispatch(Actions.updatePricingCurrency(...args)),
  deletePricingCurrency: (...args) =>
    dispatch(Actions.deletePricingCurrency(...args)),
  createPricing: (...args) => dispatch(Actions.createPricing(...args)),
  getPricingById: (...args) => dispatch(Actions.getPricingById(...args)),
  getPricings: (...args) => dispatch(Actions.getPricings(...args)),
  getGoodMedia: (...args) => dispatch(Actions.getGoodMedia(...args)),
  deleteMedium: (...args) => dispatch(Actions.deleteMedium(...args)),
  updateMedium: (...args) => dispatch(Actions.updateMedium(...args)),
  createBundle: (...args) => dispatch(Actions.createBundle(...args)),
  deleteBundle: (...args) => dispatch(Actions.deleteBundle(...args)),
  updateBundle: (...args) => dispatch(Actions.updateBundle(...args)),
  getCategories: (...args) => dispatch(Actions.getCategories(...args)),
  getProjects: (...args) => dispatch(Actions.getProjects(...args)),
  getActivities: (...args) => dispatch(Actions.getActivities(...args)),
  createGoodCategory: (...args) =>
    dispatch(Actions.createGoodCategory(...args)),
  deleteGoodCategory: (...args) =>
    dispatch(Actions.deleteGoodCategory(...args)),
});
class GoodPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    // Good
    getGoodById: PropTypes.func,
    updateGood: PropTypes.func,
    deleteGood: PropTypes.func,
    getGoodMedia: PropTypes.func,
    createPricingCurrency: PropTypes.func,
    updatePricingCurrency: PropTypes.func,
    deletePricingCurrency: PropTypes.func,
    uploadGoodMedium: PropTypes.func,
    deleteMedium: PropTypes.func,
    getProjects: PropTypes.func,
    updateMedium: PropTypes.func,
    createPricing: PropTypes.func,
    getPricings: PropTypes.func,
    pricings: PropTypes.array,
    createBundle: PropTypes.func,
    getActivities: PropTypes.func,
    deleteBundle: PropTypes.func,
    updateBundle: PropTypes.func,
    getGoods: PropTypes.func,
    createGoodCategory: PropTypes.func,
    deleteGoodCategory: PropTypes.func,
    goods: PropTypes.array,
    categories: PropTypes.array,
    projects: PropTypes.array,
    activities: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const {
      location,
      getPricings,
      getGoods,
      getProjects,
      getActivities,
    } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      goodID: urlParams.id,
      loading: true,
    };
    getGoods();
    getPricings();
    getProjects();
    getActivities();
  }

  componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const { getGoodById, getGoodSales, getGoodMedia } = this.props;
    const { goodID } = this.state;
    const resps = await Promise.all([
      getGoodById(goodID),
      getGoodSales(goodID),
      getGoodMedia(goodID),
    ]);

    const good = resps[0].payload;
    good.pictures = resps[2].payload;

    this.setState({
      good,
      sales: resps[1].payload,
      loading: false,
      refreshKey: Date.now(),
    });
  }

  render() {
    const {
      location,
      history,
      updateGood,
      createPricingCurrency,
      updatePricingCurrency,
      deletePricingCurrency,
      uploadGoodMedium,
      updateMedium,
      deleteMedium,
      createGoodCategory,
      deleteGoodCategory,
      deleteGood,
      createPricing,
      pricings,
      createBundle,
      deleteBundle,
      activities,
      updateBundle,
      goods,
      categories,
      projects,
    } = this.props;

    const { urlParams, loading, good, sales, refreshKey } = this.state;

    return (
      <WrapperGoodPage
        history={history}
        activities={activities}
        location={location}
        pricings={pricings}
        sales={sales}
        urlParams={urlParams}
        loading={loading}
        refresh={this.refresh.bind(this)}
        updateGood={updateGood}
        good={good}
        uploadGoodMedium={uploadGoodMedium}
        deleteMedium={deleteMedium}
        updateMedium={updateMedium}
        createGoodCategory={createGoodCategory}
        deleteGoodCategory={deleteGoodCategory}
        categories={categories}
        createPricingCurrency={createPricingCurrency}
        updatePricingCurrency={updatePricingCurrency}
        deletePricingCurrency={deletePricingCurrency}
        createBundle={createBundle}
        deleteBundle={deleteBundle}
        updateBundle={updateBundle}
        deleteGood={deleteGood}
        goods={goods}
        projects={projects}
        createPricing={createPricing}
        refreshKey={refreshKey}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GoodPage);
