import React, { Component } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";
import Drag from "@material-ui/icons/DragIndicator";

// custom
import FormBanner from "components/FormBanner";
import Banner from "components/Banner";

// styles
import styles from "./styles";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  marginLeft: -grid,
  marginRight: grid,

  // change background colour if dragging
  flex: 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = () => ({
  display: "flex",
  overflow: "auto",
  width: "100%",
});

class Banners extends Component {
  static propTypes = {
    classes: PropTypes.object,
    banners: PropTypes.array,
    deleteBanner: PropTypes.func,
    updateBanner: PropTypes.func,
    createBanner: PropTypes.func,
    edit: PropTypes.bool,
    aisleID: PropTypes.number,
  };

  constructor(...args) {
    super(...args);
    const { banners } = this.props;
    this.state = {
      banners,
      formBannerOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.aisleID !== nextProps.aisleID) {
      this.setState({
        banners: nextProps.banners,
      });
    }
  }

  async onDragEnd(result) {
    // dropped outside the list
    const { updateBanner, refresh } = this.props;
    const { banners } = this.state;
    if (!result.destination) {
      return;
    }

    const m = reorder(
      this.state.banners,
      result.source.index,
      result.destination.index
    );

    this.setState({
      banners: m,
    });
    await updateBanner(banners[result.source.index].id, {
      order: result.destination.index + 1,
    });
    refresh();
  }

  render() {
    const {
      createBanner,
      deleteBanner,
      updateBanner,
      aisleID,
      refresh,
      edit,
      alt,
    } = this.props;
    const { banners, selectedBanner, formBannerOpen } = this.state;

    return (
      <Grid container>
        {edit ? (
          <Grid item>
            <Chip
              label="+ New Banner"
              variant="outlined"
              onClick={async () => {
                await createBanner({
                  aisleID,
                  align: "left",
                  name: "My New Banner",
                  content: "Click on the top right pen to edit",
                }).then(refresh);
              }}
            />
          </Grid>
        ) : (
          []
        )}
        <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {banners.map((b, index) => (
                  <Draggable key={b.id} draggableId={b.id} index={index}>
                    {(p, s) => (
                      <div
                        ref={p.innerRef}
                        {...p.draggableProps}
                        {...p.dragHandleProps}
                        style={getItemStyle(
                          s.isDragging,
                          p.draggableProps.style
                        )}
                      >
                        <Grid
                          item
                          style={{
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            {edit ? (
                              <IconButton
                                style={{
                                  padding: 5,
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                }}
                                onClick={() =>
                                  this.setState({
                                    formBannerOpen: true,
                                    selectedBanner: b,
                                  })
                                }
                              >
                                <Edit
                                  style={{ fontSize: 18, color: "white" }}
                                />
                              </IconButton>
                            ) : (
                              []
                            )}
                            {alt && edit ? (
                              <Drag
                                style={{
                                  color: "white",
                                  position: "absolute",
                                  top: 5,
                                  left: 0,
                                  fontSize: 15,
                                }}
                              />
                            ) : (
                              []
                            )}
                            <Banner {...b} />
                          </div>
                        </Grid>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <FormBanner
          open={formBannerOpen}
          banner={selectedBanner}
          close={() => {
            this.setState({
              formBannerOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedBanner: undefined,
                }),
              200
            );
          }}
          createBanner={createBanner}
          deleteBanner={deleteBanner}
          updateBanner={updateBanner}
          refresh={refresh}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(Banners);
