import { networkAction } from "helpers/network/networkAction";

import {
  GET_BANNERS,
  GET_BANNER_BY_ID,
  DELETE_BANNER,
  UPDATE_BANNER,
  CREATE_BANNER,
} from "constants/banners";

import * as Api from "api";

export const getBanners = () => async (dispatch) =>
  networkAction(dispatch, GET_BANNERS, Api.getBanners, []);

export const getBannerById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_BANNER_BY_ID, Api.getBannerById, [id]);

export const deleteBanner = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_BANNER, Api.deleteBanner, [id]);

export const createBanner = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_BANNER, Api.createBanner, [body]);

export const updateBanner = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_BANNER, Api.updateBanner, [id, body]);
