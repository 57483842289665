import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
// constants
import { GOOD } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormGood extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    good: PropTypes.object,
    projects: PropTypes.array,

    fetchGood: PropTypes.func,
    createGood: PropTypes.func,
    updateGood: PropTypes.func,
    deleteGood: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      projectID: "",
      itemID: "",
      title: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.good);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(good) {
    this.setState({
      loading: false,
      projectID: good ? good.projectID : "",
      itemID: good ? good.itemID : "",
      title: good ? good.title : "",
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "If you delete this good, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteGood, close, refresh, good } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteGood(good.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Good has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateGood,
      createGood,
      good,
      refresh,
      close,
      history,
    } = this.props;

    const { projectID, itemID, title } = this.state;

    const { NotificationCenter } = this.context;

    let resp;
    this.setState({ loading: true });
    if (good) {
      resp = await updateGood(good.id, {
        projectID,
        itemID,
        title,
      });
    } else {
      resp = await createGood({
        projectID: projectID || 0,
        itemID: itemID || 0,
        title: title || "My New Good",
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: good ? "Good has been updated." : "Good has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      refresh();
      setTimeout(() => {
        NotificationCenter.hide();
        history.push(`${GOOD}?id=${resp.payload.id}`);
      }, 1500);
    }
  }

  async fetchGood(projectID) {
    const { projects, fetchGood } = this.props;
    const project = projects.find((p) => p.id === projectID);

    if (project) {
      this.setState({
        goodLoading: true,
      });
      const resp = await fetchGood(project.resourceURL);
      this.setState({
        goodLoading: false,
        goods: resp.payload,
      });
    }
  }

  render() {
    const { classes, fullScreen, open, good, close, projects } = this.props;

    const { projectID, loading, goodLoading, goods, selectedGood } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        classes={{
          paper: classes.paper,
        }}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {good ? `${good.name}` : "Let's Create a New Good"}
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  src="https://storage.googleapis.com/college101-images/cart.png"
                  alt="cart"
                />
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="project-simple">
                      Select the Project
                    </InputLabel>
                    <Select
                      inputProps={{
                        name: "Select the Project",
                        id: "project-simple",
                      }}
                      value={projectID}
                      onChange={(e) => {
                        this.handleChange("projectID")(e);
                        this.fetchGood(e.target.value);
                      }}
                    >
                      {projects.map((p) => (
                        <MenuItem key={`role_${p.id}`} value={p.id}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {goodLoading ? (
                  <Grid item xs={12}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  []
                )}
                {goods && goods.length ? (
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="project-simple">
                        Select the Good
                      </InputLabel>
                      <Select
                        inputProps={{
                          name: "Select the Good",
                          id: "project-simple",
                        }}
                        value={selectedGood && selectedGood.id}
                        onChange={(g) => {
                          this.setState({
                            selectedGood: g,
                            title: g.name,
                            itemID: g.id,
                          });
                        }}
                      >
                        {goods.map((p) => (
                          <MenuItem key={`good_${p.id}`} value={p.id}>
                            {p.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : (
                  <div />
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {good && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Close
              </Button>
              <Button color="primary" autoFocus onClick={this.save.bind(this)}>
                <SaveIcon className={classes.leftIcon} />
                {good ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormGood));
