// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CustomersList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    customers: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.func,
    getCustomers: PropTypes.func,
    subscriptions: PropTypes.array,
    getSubscriptions: PropTypes.func,
  };

  onSelect(selectedCustomer) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedCustomer);
    }
  }

  render() {
    const { customers, history, getCustomers, subscriptions } = this.props;

    return (
      <AdvancedSearch
        history={history}
        rowCount={customers.length}
        location={window.location}
        refresh={getCustomers}
        keyword="customers"
        metaDataEndpoint="/bazaar/customers/searchMetadata"
        metaDataOveride={{
          subscriptionID: {
            type: "autocomplete",
            data: subscriptions,
            valueKey: "id",
            labelKey: "name",
          },
        }}
      >
        <Table
          data={customers}
          meta={[
            {
              path: "fullName",
              title: "Full Name",
              numeric: false,
            },
            {
              path: "email",
              title: "Email",
              numeric: false,
            },
            {
              path: "subscriptionID",
              title: "Subscription",
              render: (t, d) => {
                const sub = subscriptions.find(
                  (s) => s.id === d.subscriptionID
                );
                if (sub) return `${sub.name}`;
                return "";
              },
              numeric: false,
            },
            {
              path: "address1",
              title: "Address",
              numeric: false,
              transform: (text, d) =>
                `${d.address1} ${d.address2} ${d.zip} ${d.city} ${d.state} ${d.country}`,
              render: (text, d) => (
                <React.Fragment>
                  <Typography display="block" variant="body2">
                    {d.address1}
                  </Typography>
                  <Typography display="block" variant="body2">
                    {d.address2}
                  </Typography>
                  <Typography display="block" variant="body2">
                    {d.zip} {d.city} {d.state}
                  </Typography>
                  <Typography display="block" variant="body2">
                    {d.country}
                  </Typography>
                </React.Fragment>
              ),
            },
          ]}
          title={"Customers"}
          onRowSelect={this.onSelect.bind(this)}
        />
      </AdvancedSearch>
    );
  }
}

export default withStyles(styles)(CustomersList);
