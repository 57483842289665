import { networkAction } from "helpers/network/networkAction";

import {
  GET_ROLES,
  GET_ROLE_BY_ID,
  DELETE_ROLE,
  UPDATE_ROLE,
  CREATE_ROLE,
} from "constants/roles";

import * as Api from "api";

export const getRoles = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_ROLES, Api.getRoles, [filters]);

export const getRoleById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ROLE_BY_ID, Api.getRoleById, [id]);

export const deleteRole = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ROLE, Api.deleteRole, [id]);

export const createRole = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ROLE, Api.createRole, [body]);

export const updateRole = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ROLE, Api.updateRole, [id, body]);
