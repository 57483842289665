import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import PaymentActions from "components/PaymentActions";
// custom
import Table from "components/Table";
// helpers
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class RecentPaymentList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    payments: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.func,
    cookies: PropTypes.object,
    impersonate: PropTypes.func,
    simple: PropTypes.bool,
  };

  onSelect(selectedMarket) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedMarket);
    }
  }

  render() {
    const { payments, history, cookies, impersonate, simple } = this.props;

    return (
      <Table
        data={payments}
        noHeader
        rowStyle={(d) => ({
          opacity: !d.refunded ? 1 : 0.5,
        })}
        meta={[
          {
            title: "Customer",
            path: "customer",
            numeric: false,
            transform: (t, d) =>
              `${d.customer && d.customer.fullName} ${
                d.customer && d.customer.email
              }`,
            render: (c, d) => (
              <React.Fragment>
                <Typography display="block" variant="body2">
                  {d.customerFullName || `${d.customer && d.customer.fullName}`}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  {d.customerEmail || `${d.customer && d.customer.email}`}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "total",
            title: "Total",
            numeric: false,
            transform: (t, d) =>
              `$${formattedPrice(t)}${d.refunded ? " (Refunded)" : ""}`,
          },
          {
            path: "paymentMethod",
            title: "Method",
          },
          {
            path: "transactionID",
            title: "Transaction ID",
          },
          {
            path: "cart",
            title: "Cart",
            numeric: false,
            transform: (t) =>
              t && t.goods && t.goods.map((a) => `${a.quantity}x ${a.title}`),
            render: (t, d) => (
              <Grid container>
                <Grid item xs={12}>
                  {t}
                </Grid>
                {d.cart &&
                  d.cart.coupons &&
                  d.cart.coupons.map((c) => (
                    <Grid item>
                      <Chip label={c.token} />
                    </Grid>
                  ))}
              </Grid>
            ),
          },
          {
            path: "paymentDate",
            title: "date",
            numeric: false,
            render: (t) => (
              <React.Fragment>
                <Typography display="block" variant="body2">
                  {moment(t).parseZone().fromNow()}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  {moment(t).parseZone().format("MM/DD/YYYY HH:mm:ss")}
                </Typography>
              </React.Fragment>
            ),
          },
          {
            path: "id",
            numeric: true,
            width: 120,
            render: (t, d) => (
              <div style={{ textAlign: "right" }}>
                <PaymentActions
                  d={d}
                  history={history}
                  cookies={cookies}
                  impersonate={impersonate}
                />
              </div>
            ),
          },
        ]}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(RecentPaymentList);
