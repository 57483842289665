import { GET_CUSTOMERS } from "constants/customers";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_CUSTOMERS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
