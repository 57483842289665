import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const goodCategories = `/${service}/goodCategories`;

export function getGoodCategories() {
  const url = `${goodCategories}`;
  return {
    method: GET,
    url,
  };
}

export function getGoodCategoryById(id) {
  const url = `${goodCategories}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateGoodCategory(id, body) {
  const url = `${goodCategories}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteGoodCategory(id) {
  const url = `${goodCategories}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createGoodCategory(body) {
  const url = `${goodCategories}`;
  return {
    method: POST,
    url,
    body,
  };
}
