import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumberTwoTone";
import TableCoupon from "components/TableCoupon";
// custom
import Table from "components/TablePagination";
// constants
import { COUPON } from "constants/routes";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class CouponsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    coupons: PropTypes.array,
    onSelect: PropTypes.func,
    history: PropTypes.object,
    noPaper: PropTypes.bool,
    noTitle: PropTypes.bool,
  };

  onSelect(selectedCoupon) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedCoupon);
    }
  }

  render() {
    const { coupons, history, noTitle, noPaper } = this.props;

    if (coupons.length === 0) {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ height: "calc(100vh - 140px)", textAlign: "center" }}
        >
          <Grid item>
            <ConfirmationNumberIcon style={{ fontSize: 70 }} />
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              No coupons
            </Typography>
            <Typography display="block" variant="caption" color="textSecondary">
              No coupons have been found.
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Table
        data={coupons}
        meta={[
          {
            path: "token",
            title: "Token",
            numeric: false,
            component: TableCoupon,
          },
          {
            path: "percent",
            title: "Percent Off",
            numeric: false,
            postfix: "% Off",
          },
          {
            path: "maxCount",
            title: "Usage",
            numeric: false,
            transform: (maxCount, datum) => `${datum.useCount}/${maxCount}`,
          },
          {
            path: "expirationDate",
            title: "Expiration Date",
            transform: (text) => moment(text).calendar(),
            numeric: false,
          },
        ]}
        noPaper={noPaper}
        title={noTitle ? undefined : "Coupons"}
        onRowSelect={(p) => history.push(`${COUPON}?id=${p.id}`)}
      />
    );
  }
}

export default withStyles(styles)(CouponsList);
