import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";

// custom
import Table from "components/TablePagination";

// styles
import styles from "./styles";

class SubscriptionsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    subscriptions: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedSubscription) {
    const { onSelect } = this.props;
    this.setState({ selectedSubscription });
    if (onSelect) {
      onSelect(selectedSubscription);
    }
  }

  render() {
    const { subscriptions } = this.props;

    return (
      <Table
        data={subscriptions}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "description",
            title: "Description",
            numeric: false,
          },
        ]}
        title={"Subscriptions"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(SubscriptionsList);
