import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";

// custom
import Table from "components/TablePagination";

// styles
import styles from "./styles";

class ProjectsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    projects: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedProject) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedProject);
    }
  }

  render() {
    const { projects } = this.props;

    return (
      <Table
        data={projects}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
          },
          {
            path: "itemType",
            title: "Type",
            numeric: false,
          },
          {
            path: "resourceURL",
            title: "Resource URL",
            numeric: false,
          },
        ]}
        title={"Projects"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(ProjectsList);
