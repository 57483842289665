import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

// custom
import Table from "components/TablePagination";

// helpers
import { formattedPrice } from "helpers/stripe";

// styles
import styles from "./styles";

class PricingsList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    pricings: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedPricing) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedPricing);
    }
  }

  render() {
    const { pricings } = this.props;

    return (
      <Table
        data={pricings}
        meta={[
          {
            path: "title",
            title: "Title",
            numeric: false,
          },
          {
            path: "description",
            title: "Description",
            numeric: false,
          },
          {
            path: "description",
            title: "Currencies",
            render: (t, d) => (
              <Grid container>
                {d.currencies &&
                  d.currencies.map((c) => (
                    <Grid item key={`currencies_${c.id}`}>
                      <Chip
                        label={`${formattedPrice(c.price)} ${c.currency}`}
                      />
                    </Grid>
                  ))}
              </Grid>
            ),
          },
        ]}
        title={"Pricings"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(PricingsList);
