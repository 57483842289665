import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Highlight from "react-highlight";

// styles
import styles from "./styles";

class Code extends Component {
  static propTypes = {
    value: PropTypes.node,
    language: PropTypes.string,
    dark: PropTypes.bool,
  };

  render() {
    const { value, language, dark } = this.props;

    return (
      <div style={{ maxWidth: 1200 }}>
        <link
          rel="stylesheet"
          href={
            !dark
              ? "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/atom-one-light.min.css"
              : "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/atom-one-dark.min.css"
          }
        />
        <Highlight className={language}>{value}</Highlight>
      </div>
    );
  }
}

export default withStyles(styles)(Code);
