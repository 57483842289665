import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Back from "@material-ui/icons/ArrowBack";
import Monetization from "@material-ui/icons/MonetizationOnTwoTone";
// reactor
import Page from "components/Page";
import PricingInfo from "components/PricingInfo";
// constants
import { BILLING } from "constants/routes";
import { goBack } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperPricingPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    pricing: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    refresh: PropTypes.func,
    updatePricing: PropTypes.func,
    deletePricing: PropTypes.func,
    createPricingCurrency: PropTypes.func,
    updatePricingCurrency: PropTypes.func,
    deletePricingCurrency: PropTypes.func,
  };

  render() {
    const {
      classes,
      history,
      pricing,
      loading,
      refresh,
      updatePricing,
      deletePricing,
      createPricingCurrency,
      updatePricingCurrency,
      deletePricingCurrency,
    } = this.props;

    return (
      <Page
        helmet="Pricing"
        loadingMessage={"Loading Pricing"}
        loading={loading}
        header={
          <Grid container style={{ padding: 10 }}>
            <Grid item>
              <Breadcrumbs
                arial-label="Breadcrumb"
                separator={"-"}
                style={{
                  color: "white",
                }}
              >
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => goBack(history, BILLING)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                {pricing ? (
                  <Chip
                    label={pricing.title}
                    className={classes.backChip}
                    onClick={() => history.push(BILLING)}
                    variant="contained"
                    color="primary"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Monetization className={classes.icon} />
                      </Avatar>
                    }
                  />
                ) : (
                  []
                )}
              </Breadcrumbs>
            </Grid>
          </Grid>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {pricing ? (
            <PricingInfo
              pricing={pricing}
              history={history}
              refresh={refresh}
              updatePricing={updatePricing}
              deletePricing={deletePricing}
              createPricingCurrency={createPricingCurrency}
              updatePricingCurrency={updatePricingCurrency}
              deletePricingCurrency={deletePricingCurrency}
            />
          ) : (
            []
          )}
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperPricingPage);
