import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const shelveGoods = `/${service}/shelfGoods`;

export function getShelveGoods() {
  const url = `${shelveGoods}`;
  return {
    method: GET,
    url,
  };
}

export function getShelveGoodById(id) {
  const url = `${shelveGoods}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateShelveGood(id, body) {
  const url = `${shelveGoods}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteShelveGood(id) {
  const url = `${shelveGoods}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createShelveGood(body) {
  const url = `${shelveGoods}`;
  return {
    method: POST,
    url,
    body,
  };
}
