import React, { Component } from "react";
import PropTypes from "prop-types";
import { Elements, StripeProvider } from "react-stripe-elements";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";

// custom
import CheckoutForm from "components/CheckoutForm";
import Code from "components/Code";

// reactor
import Page from "components/Page";

// helpers
import { copyTextToClipboard } from "helpers/url";

// constant
import { STRIPE_TOKEN } from "config";

// styles
import styles from "./styles";

class WrapperCreditcardPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    app: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  state = {
    stripeResp: undefined,
  };

  copy(text) {
    const { NotificationCenter } = this.context;
    copyTextToClipboard(text);
    NotificationCenter.stack(
      {
        title: "Copied to Clipboard",
        body: `${text} has been copied`,
        info: true,
      },
      {
        cancel: {
          label: "Close",
          level: "normal",
          callback: () => console.log("close"),
        },
      }
    );
  }

  render() {
    const { classes, app } = this.props;
    const { stripeResp } = this.state;

    return (
      <Page helmet="Stripe Token">
        <Paper className={classes.paper} style={{ marginTop: 30 }}>
          <img
            src="https://storage.googleapis.com/rhapsody/logos/credit-card.png"
            style={{
              height: 80,
            }}
            alt="business"
          />
          <Typography display="block" variant="h6">
            Payment Method
          </Typography>
          <Typography display="block" variant="body" color="textSecondary">
            Enter your Credit or Debit Card Information:
          </Typography>
          <div className={classes.stripe}>
            <StripeProvider apiKey={STRIPE_TOKEN}>
              <Elements>
                <CheckoutForm
                  submitContainer={this.submitContainer}
                  onComplete={(sr) => this.setState({ stripeResp: sr })}
                />
              </Elements>
            </StripeProvider>
          </div>
          <Typography display="block" variant="caption" color="textSecondary">
            We use Stripe to secure your transaction. Your information is saved
            with Stripe servers only.
          </Typography>
          <div ref={(ref) => (this.submitContainer = ref)} />
        </Paper>
        {stripeResp ? (
          <Grow in>
            <Paper className={classes.paper}>
              <Typography display="block" variant="h6">
                Yay !
              </Typography>
              <Typography display="block" variant="body" color="textSecondary">
                The card has been generated
              </Typography>
              <Grid
                container
                spacing={2}
                style={{ marginTop: 20 }}
                justify="center"
              >
                <Grid item>
                  <Chip
                    onClick={() => this.copy(stripeResp.token.card.id)}
                    label={stripeResp.token.card.id}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    onClick={() => this.copy(stripeResp.token.id)}
                    label={stripeResp.token.id}
                  />
                </Grid>
              </Grid>
              <Code
                value={JSON.stringify(stripeResp.token, null, "\t")}
                language={"json"}
                dark={app.darkMode}
              />
            </Paper>
          </Grow>
        ) : (
          []
        )}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCreditcardPage);
