import { STORE_RESET_PASSWORD_TOKEN, SET_DARK_MODE } from "constants/app";

export const storeResetPasswordToken = (token) => async (dispatch) =>
  dispatch({
    type: STORE_RESET_PASSWORD_TOKEN,
    token,
  });

export const setDarkMode = (token) => async (dispatch) =>
  dispatch({
    type: SET_DARK_MODE,
    token,
  });
