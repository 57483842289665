import { networkAction } from "helpers/network/networkAction";

import {
  GET_AISLES,
  GET_AISLE_BY_ID,
  DELETE_AISLE,
  UPDATE_AISLE,
  CREATE_AISLE,
} from "constants/aisles";

import * as Api from "api";

export const getAisles = () => async (dispatch) =>
  networkAction(dispatch, GET_AISLES, Api.getAisles, []);

export const getAisleById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_AISLE_BY_ID, Api.getAisleById, [id]);

export const deleteAisle = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_AISLE, Api.deleteAisle, [id]);

export const createAisle = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_AISLE, Api.createAisle, [body]);

export const updateAisle = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_AISLE, Api.updateAisle, [id, body]);
