import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
// material-ui
import Typography from "@material-ui/core/Typography";
import Back from "@material-ui/icons/ArrowBack";
import Money from "@material-ui/icons/AttachMoneyTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GraphicEq from "@material-ui/icons/GraphicEqTwoTone";
import InsertDriveFile from "@material-ui/icons/InsertDriveFileTwoTone";
import People from "@material-ui/icons/PeopleTwoTone";
import Usage from "@material-ui/icons/VerticalAlignBottomTwoTone";
import CouponsList from "components/CouponsList";
// reactor
import Page from "components/Page";
import StatementGraph from "components/StatementGraph";
import UsagesList from "components/UsagesList";
// constants
import { CUSTOMER, CUSTOMERS, INVOICE } from "constants/routes";
import { goBack } from "helpers";
import { formattedPrice } from "helpers/stripe";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperStatementPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    statement: PropTypes.object,
    deleteStatement: PropTypes.func,
    updateStatement: PropTypes.func,
    refresh: PropTypes.object,
    loading: PropTypes.bool,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      newCoupon: "",
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  getGraphConfig() {
    const { statement } = this.props;
    const data = [];
    const colors = [];
    const { usages } = statement;
    for (const key in usages) {
      if (usages.hasOwnProperty(key)) {
        const element = usages[key];

        data.push([
          new Date(element.date).getTime(),
          Number(formattedPrice(element.quantity)),
        ]);
        colors.push("#777777");
      }
    }

    const options = {
      chart: {
        id: "CustomerSatisfaction",
        height: "200px",
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
        labels: {
          style: {
            colors,
          },
        },
      },
      yaxis: {
        min: 0,
        labels: {
          style: {
            color: "#777777",
          },
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
    };
    const series = [
      {
        name: "Usage",
        data,
      },
    ];

    const config = { options, series, type: "area" };
    return config;
  }

  deleteStatement() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteStatement, statement, history } = this.props;
    await deleteStatement(statement.id);
    history.push(CUSTOMERS);
  }

  async addCoupon() {
    const { updateStatement, statement, refresh } = this.props;
    const { newCoupon } = this.state;
    updateStatement(statement.id, {
      coupons: [{ token: newCoupon }],
    });

    refresh();
  }

  render() {
    const { classes, history, loading, statement } = this.props;

    const { newCoupon } = this.state;

    let status = "Current";
    if (statement && statement.invoiceID) status = "Invoiced";
    if (statement && statement.invoice && statement.invoice.paid)
      status = "Paid";

    return (
      <Page
        helmet="Statement"
        loadingMessage={"Loading Statement"}
        loading={loading}
        header={
          <Grid container style={{ padding: 10 }}>
            <Grid item>
              <Breadcrumbs arial-label="Breadcrumb" separator={"-"}>
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => goBack(history, CUSTOMERS)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                {statement && statement.customerID ? (
                  <Chip
                    label={`Customer #${statement.customerID}`}
                    className={classes.chip}
                    onClick={() =>
                      history.push(`${CUSTOMER}?id=${statement.customerID}`)
                    }
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <People className={classes.icon} />
                      </Avatar>
                    }
                  />
                ) : (
                  []
                )}
                {statement && statement.invoiceID ? (
                  <Chip
                    label={`Invoice #${statement.invoiceID}`}
                    className={classes.chip}
                    onClick={() =>
                      history.push(`${INVOICE}?id=${statement.invoiceID}`)
                    }
                    variant="outlined"
                    avatar={
                      <Avatar className={classes.avatar}>
                        <InsertDriveFile className={classes.icon} />
                      </Avatar>
                    }
                  />
                ) : (
                  []
                )}
                <Chip
                  label={`Statement #${statement ? statement.invoiceID : ""}`}
                  className={classes.chip}
                  variant="contained"
                  color="primary"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <GraphicEq className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
            </Grid>
          </Grid>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Paper
                className="card"
                style={{ color: "#ffc107", height: "calc(100% - 90px)" }}
              >
                <Paper className="cardHeader" style={{ background: "#ffc107" }}>
                  <Usage style={{ color: "white" }} />
                </Paper>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Typography display="block" variant="h6" color="inherit">
                      Usage
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography display="block" variant="h3">
                      {statement && statement.usages
                        ? statement.usages.length
                        : "-"}
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      Since{" "}
                      {statement
                        ? moment(statement.creationDate).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper
                className="card"
                style={{ color: "#ff9800", height: "calc(100% - 90px)" }}
              >
                <Paper className="cardHeader" style={{ background: "#ff9800" }}>
                  <Money style={{ color: "white" }} />
                </Paper>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Typography display="block" variant="h6" color="inherit">
                      Current Total
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography display="block" variant="h3">
                      {statement
                        ? `USD ${formattedPrice(statement.total)}`
                        : ""}
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      Subtotal{" "}
                      {statement
                        ? `USD ${formattedPrice(statement.subTotal)}`
                        : ""}
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      Discount{" "}
                      {statement
                        ? `USD ${formattedPrice(statement.discount)}`
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper
                className="card"
                style={{ color: "#ff5722", height: "calc(100% - 90px)" }}
              >
                <Paper className="cardHeader" style={{ background: "#ff5722" }}>
                  <InsertDriveFile style={{ color: "white" }} />
                </Paper>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Typography display="block" variant="h6" color="inherit">
                      Status
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography display="block" variant="h3">
                      {status}
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      {statement &&
                      statement.invoice &&
                      statement.invoice.creationDate
                        ? `Invoiced on ${moment(
                            statement.invoice.creationDate
                          ).calendar()}`
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <StatementGraph
                  data={statement && statement.usages ? statement.usages : []}
                  height={200}
                  min={
                    statement
                      ? moment(statement.creationDate).toDate().getTime()
                      : undefined
                  }
                  max={
                    statement
                      ? moment(statement.creationDate)
                          .add("months", 1)
                          .subtract("days", 1)
                          .toDate()
                          .getTime()
                      : undefined
                  }
                  dateKey={"date"}
                  dataKey={"quantity"}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography display="block" variant="h6">
                      Usages
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      View all Records.
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  <div style={{ width: "100%" }}>
                    <UsagesList usages={statement ? statement.usages : []} />
                  </div>
                </ExpansionPanelDetails>
                <Divider />
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography display="block" variant="h6">
                      Coupons
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      View Coupons Related to the Statement.
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  <div style={{ width: "100%" }}>
                    <CouponsList
                      coupons={statement ? statement.coupons : []}
                      history={history}
                      noPaper
                      noTitle
                    />
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      style={{ marginTop: 20 }}
                    >
                      <Grid item>
                        <Typography display="block" variant="body2">
                          Add Coupon:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <InputBase
                          disabled={loading}
                          fullWidth
                          className={classes.inputBase}
                          defaultValue={newCoupon}
                          onChange={this.handleChange("newCoupon")}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={this.addCoupon.bind(this)}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </ExpansionPanelDetails>
                <Divider />
              </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography
                      display="block"
                      variant="h6"
                      style={{ color: "#f44336" }}
                    >
                      Danger Zone
                    </Typography>
                    <Typography display="block" color="textSecondary">
                      Delete the Statement.
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  <Button
                    style={{
                      color: "white",
                      background: "#f44336",
                    }}
                    onClick={this.deleteStatement.bind(this)}
                  >
                    Delete Statement
                  </Button>
                </ExpansionPanelDetails>
                <Divider />
              </ExpansionPanel>
            </Grid>
            {/* <Grid item xs={12} md={8}>
              <UsagesList
                usages={statement ? statement.usages : []}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper style={{ padding: 20 }}>
                <Typography display="block" variant="h6">
                  About the Statement
                </Typography>
                {
                  statement
                    ? (
                      <React.Fragment>
                        <Grid container justify="space-between" spacing={2}>
                          <Grid item>
                            <Typography display="block" variant="body2">Sub Total:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography display="block" variant="body2">
                              USD {formattedPrice(statement.subTotal)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justify="space-between" spacing={2}>
                          <Grid item>
                            <Typography display="block" variant="body2">Discount:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography display="block" variant="body2">
                              USD {formattedPrice(statement.discount)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Divider /> 
                        <Grid container justify="space-between" spacing={2}>
                          <Grid item>
                            <Typography display="block" variant="h6">Total:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography display="block" variant="h6">
                              USD {formattedPrice(statement.total)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ) : []
                }
              </Paper>
            </Grid> */}
          </Grid>
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperStatementPage);
