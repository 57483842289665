import { PROJECTS } from "constants/routes";
import { serviceRoles } from "config/access";
import { checkAccess } from "helpers/auth";
import ProjectPage from "./container/ProjectPage";

export default function getSettingsRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: PROJECTS,
    withSidebar: true,
    withAppBar: true,
    component: ProjectPage,
  };
}
