import { networkAction } from "helpers/network/networkAction";

import {
  GET_GOOD_CATEGORIES,
  GET_GOOD_CATEGORY_BY_ID,
  DELETE_GOOD_CATEGORY,
  UPDATE_GOOD_CATEGORY,
  CREATE_GOOD_CATEGORY,
} from "constants/goodCategories";

import * as Api from "api";

export const getGoodCategories = () => async (dispatch) =>
  networkAction(dispatch, GET_GOOD_CATEGORIES, Api.getGoodCategories, []);

export const getGoodCategoryById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GOOD_CATEGORY_BY_ID, Api.getGoodCategoryById, [
    id,
  ]);

export const deleteGoodCategory = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_GOOD_CATEGORY, Api.deleteGoodCategory, [id]);

export const createGoodCategory = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_GOOD_CATEGORY, Api.createGoodCategory, [body]);

export const updateGoodCategory = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_GOOD_CATEGORY, Api.updateGoodCategory, [
    id,
    body,
  ]);
