import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperCustomerPage from "../component/WrapperCustomerPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  customers: state.customers,
  subscriptions: state.subscriptions,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomers: (...args) => dispatch(Actions.getCustomers(...args)),
  getCustomerById: (...args) => dispatch(Actions.getCustomerById(...args)),
  createCustomer: (...args) => dispatch(Actions.createCustomer(...args)),
  deleteCustomer: (...args) => dispatch(Actions.deleteCustomer(...args)),
  updateCustomer: (...args) => dispatch(Actions.updateCustomer(...args)),
  getSubscriptions: (...args) => dispatch(Actions.getSubscriptions(...args)),
});
class CustomerPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    customers: PropTypes.array,
    subscriptions: PropTypes.array,
    getCustomers: PropTypes.func,
    getCustomerById: PropTypes.func,
    createCustomer: PropTypes.func,
    deleteCustomer: PropTypes.func,
    updateCustomer: PropTypes.func,
    getSubscriptions: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, customers, getSubscriptions } = this.props;
    getSubscriptions();

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: customers.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getCustomers } = this.props;
    const { urlParams } = this.state;
    let filters;
    if (urlParams.filters) {
      filters = JSON.parse(urlParams.filters);
    }
    await Promise.all([getCustomers(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      history,
      customers,
      getCustomerById,
      createCustomer,
      deleteCustomer,
      subscriptions,
      updateCustomer,
      getCustomers,
      getSubscriptions,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperCustomerPage
        history={history}
        refresh={this.refresh.bind(this)}
        loading={loading}
        getSubscriptions={getSubscriptions}
        getCustomers={getCustomers}
        customers={customers}
        subscriptions={subscriptions}
        getCustomerById={getCustomerById}
        createCustomer={createCustomer}
        deleteCustomer={deleteCustomer}
        updateCustomer={updateCustomer}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
