import { Fab } from "@material-ui/core";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import CouponsList from "components/CouponsList";
import FormCoupon from "components/FormCoupon";
// components
import Page from "components/Page";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperCouponPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    goods: PropTypes.array,
    coupons: PropTypes.array,
    createCoupon: PropTypes.func,
    getCouponById: PropTypes.func,
    deleteCoupon: PropTypes.func,
    updateCoupon: PropTypes.func,
    addCouponGood: PropTypes.func,
    deleteCouponGood: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedCoupon: undefined,
      formCouponOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { coupons, history } = this.props;

    switch (index) {
      case 0:
        return <CouponsList coupons={coupons} history={history} />;
      default:
    }

    return [];
  }

  render() {
    const {
      classes,
      history,
      loading,
      createCoupon,
      deleteCoupon,
      updateCoupon,
      refresh,
      goods,
      getCouponById,
      addCouponGood,
      deleteCouponGood,
    } = this.props;

    const { index, selectedCoupon, formCouponOpen } = this.state;

    return (
      <Page
        helmet="Coupons"
        loading={loading}
        loadingMessage={"Fetching all Coupons"}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formCouponOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <React.Fragment>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({ index: v });
                history.push({
                  search: updateURLParameter("index", v),
                });
              }}
              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="All"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </React.Fragment>
        }
      >
        {this.getStepContent()}
        <FormCoupon
          open={formCouponOpen}
          coupon={selectedCoupon}
          goods={goods}
          close={() => {
            this.setState({
              formCouponOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedCoupon: undefined,
                }),
              200
            );
          }}
          createCoupon={createCoupon}
          deleteCoupon={deleteCoupon}
          updateCoupon={updateCoupon}
          addCouponGood={addCouponGood}
          deleteCouponGood={deleteCouponGood}
          getCouponById={getCouponById}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCouponPage);
