import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperSubscriptionsPage from "../component/WrapperSubscriptionsPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
});

const mapDispatchToProps = (dispatch) => ({
  getSubscriptions: () => dispatch(Actions.getSubscriptions()),
  getSubscriptionById: (...args) =>
    dispatch(Actions.getSubscriptionById(...args)),
  createSubscription: (...args) =>
    dispatch(Actions.createSubscription(...args)),
  deleteSubscription: (...args) =>
    dispatch(Actions.deleteSubscription(...args)),
  updateSubscription: (...args) =>
    dispatch(Actions.updateSubscription(...args)),
});
class SubscriptionsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    subscriptions: PropTypes.array,
    getSubscriptions: PropTypes.func,
    getSubscriptionById: PropTypes.func,
    createSubscription: PropTypes.func,
    deleteSubscription: PropTypes.func,
    updateSubscription: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, subscriptions } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: subscriptions.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getSubscriptions } = this.props;
    await Promise.all([getSubscriptions()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      subscriptions,
      getSubscriptionById,
      createSubscription,
      deleteSubscription,
      updateSubscription,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperSubscriptionsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        subscriptions={subscriptions}
        getSubscriptionById={getSubscriptionById}
        createSubscription={createSubscription}
        deleteSubscription={deleteSubscription}
        updateSubscription={updateSubscription}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsPage);
