export default (theme) => ({
  container: {
    padding: 5,
  },
  noPadding: {
    padding: 0,
  },
  checkboxPadding: {
    padding: "0px 8px 0px 20px",
  },
  chip: {
    border:
      theme.palette.type === "dark"
        ? "solid 1px rgba(255,255,255,0.3)"
        : "solid 1px rgba(155,155,155,0.3)",
    background:
      theme.palette.type === "dark"
        ? "rgba(255,255,255,0.1)"
        : "solid 1px rgba(155,155,155,0.3)",
    color: theme.palette.type === "dark" ? "white" : "#616161",
    padding: "2px 6px",
    fontSize: 12,
    borderRadius: 16,
  },
  // preset: {
  //   padding: "2px 6px",
  //   border:
  //     theme.palette.type === "dark"
  //       ? "solid 1px rgba(255,255,255,0.3)"
  //       : "solid 1px rgba(155,155,155,0.3)",
  //   fontSize: 12,
  //   borderRadius: 16,
  //   display: "flex",
  //   alignItems: "center",
  // },
  inputBase: {
    background: theme.palette.type === "dark" ? "#616161" : "white",
    borderRadius: 4,
    fontFamily: "monospace",
    color: theme.palette.type === "dark" ? "white" : "black",
    border: `solid 1px ${
      theme.palette.type === "dark" ? "#757575" : "#CCCCCC"
    }`,
    padding: "4px 8px",
  },
});
