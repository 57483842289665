import { networkAction } from "helpers/network/networkAction";

import {
  GET_EVENT_PRICINGS,
  GET_EVENT_PRICING_BY_ID,
  DELETE_EVENT_PRICING,
  UPDATE_EVENT_PRICING,
  CREATE_EVENT_PRICING,
} from "constants/eventPricings";

import * as Api from "api";

export const getEventPricings = () => async (dispatch) =>
  networkAction(dispatch, GET_EVENT_PRICINGS, Api.getEventPricings, []);

export const getEventPricingById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_EVENT_PRICING_BY_ID, Api.getEventPricingById, [
    id,
  ]);

export const deleteEventPricing = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_EVENT_PRICING, Api.deleteEventPricing, [id]);

export const createEventPricing = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_EVENT_PRICING, Api.createEventPricing, [body]);

export const updateEventPricing = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_EVENT_PRICING, Api.updateEventPricing, [
    id,
    body,
  ]);
