import Fab from "@material-ui/core/Fab";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import FormSubscription from "components/FormSubscription";
import Page from "components/Page";
// components
import SubscriptionsList from "components/SubscriptionsList";
import { updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperSubscriptionsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //subscriptions
    subscriptions: PropTypes.array,
    getSubscriptionById: PropTypes.func,
    createSubscription: PropTypes.func,
    deleteSubscription: PropTypes.func,
    updateSubscription: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedSubscription: undefined,
      formSubscriptionOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { subscriptions } = this.props;

    switch (index) {
      case 0:
        return (
          <SubscriptionsList
            subscriptions={subscriptions}
            onSelect={(selectedSubscription) =>
              this.setState({
                selectedSubscription,
                formSubscriptionOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createSubscription,
      deleteSubscription,
      updateSubscription,
      refresh,
    } = this.props;

    const { index, selectedSubscription, formSubscriptionOpen } = this.state;

    return (
      <Page
        helmet={"Subscriptions"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formSubscriptionOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Subscriptions"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormSubscription
          open={formSubscriptionOpen}
          subscription={selectedSubscription}
          close={() => {
            this.setState({
              formSubscriptionOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedSubscription: undefined,
                }),
              200
            );
          }}
          createSubscription={createSubscription}
          deleteSubscription={deleteSubscription}
          updateSubscription={updateSubscription}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperSubscriptionsPage);
