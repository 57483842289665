import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

// helpers
import { formattedPrice } from "helpers/stripe";

// constants
import { ACTIVITIES, BILLING } from "constants/routes";

// styles
import styles from "./styles";

class ActivityInfo extends Component {
  static propTypes = {
    classes: PropTypes.object,
    updateActivity: PropTypes.func,
    deleteActivity: PropTypes.func,
    refresh: PropTypes.func,
    activity: PropTypes.object,
    pricings: PropTypes.array,
    history: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { activity } = this.props;
    this.state = {
      ...activity,
      loading: false,
    };
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  async save(name, numeric) {
    const { updateActivity, refresh, activity } = this.props;

    if (activity[name] === this.state[name]) return;

    if (name === "expirationDate") {
      this.state.expirationDate = moment(this.state.expirationDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    this.setState({
      loading: true,
    });

    await updateActivity(activity.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  deleteActivity() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your delete action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteActivity, activity, history } = this.props;
    await deleteActivity(activity.id);
    history.push(ACTIVITIES);
  }

  render() {
    const { classes, pricings, history } = this.props;
    const {
      basePriceID,
      usagePriceID,
      title,
      description,
      usagePriceUnit,
      loading,
    } = this.state;

    return (
      <Grid
        style={{ marginLeft: -8 }}
        container
        spacing={2}
        className={classes.container}
      >
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography display="block" variant="h6">
                  General
                </Typography>
                <Typography display="block" color="textSecondary">
                  Title & Description.
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div style={{ width: "100%" }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4} md={2}>
                    <Typography display="block" variant="body2">
                      Title:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={4}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("title");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("title")}
                      className={classes.inputBase}
                      defaultValue={title}
                      onChange={this.handleChange("title")}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4} md={2}>
                    <Typography display="block" variant="body2">
                      Description:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={4}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("description");
                      }}
                      disabled={loading}
                      fullWidth
                      multiline
                      onBlur={() => this.save("description")}
                      className={classes.inputBase}
                      defaultValue={description}
                      onChange={this.handleChange("description")}
                    />
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography display="block" variant="h6">
                  Pricings & Units
                </Typography>
                <Typography display="block" color="textSecondary">
                  Select the Price for the Activity.
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <div style={{ width: "100%" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4} md={2}>
                    <Typography display="block" variant="body2">
                      Base Price:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={4}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="project-simple">
                        Select a Pricing
                      </InputLabel>
                      <Select
                        inputProps={{
                          name: "Select the Pricings",
                          id: "Pricings-simple",
                        }}
                        value={basePriceID}
                        onChange={(e) => {
                          this.state.basePriceID = e.target.value; //eslint-disable-line
                          this.save("basePriceID");
                        }}
                      >
                        {pricings.map((p) => (
                          <MenuItem key={`pricings_${p.id}`} value={p.id}>
                            {p.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4} md={2}>
                    <Typography display="block" variant="body2">
                      Usage Price:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={4}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="project-simple">
                        Select a Pricing
                      </InputLabel>
                      <Select
                        inputProps={{
                          name: "Select the Pricings",
                          id: "Pricings-simple",
                        }}
                        value={usagePriceID}
                        onChange={(e) => {
                          this.state.usagePriceID = e.target.value; //eslint-disable-line
                          this.save("usagePriceID");
                        }}
                      >
                        {pricings.map((p) => (
                          <MenuItem key={`pricings_${p.id}`} value={p.id}>
                            {p.title}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography
                        display="block"
                        className="link"
                        variant="caption"
                        color="primary"
                        onClick={() => history.push(`${BILLING}`)}
                      >
                        View All Pricings
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={4} md={2}>
                    <Typography display="block" variant="body2">
                      Usage Price Unit:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={4}>
                    <InputBase
                      onKeyPress={(e) => {
                        if (e.key === "Enter") this.save("usagePriceUnit");
                      }}
                      disabled={loading}
                      fullWidth
                      onBlur={() => this.save("usagePriceUnit")}
                      className={classes.inputBase}
                      defaultValue={usagePriceUnit}
                      onChange={this.handleChange("usagePriceUnit")}
                    />
                  </Grid>
                </Grid>
              </div>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography
                  display="block"
                  variant="h6"
                  style={{ color: "#f44336" }}
                >
                  Danger Zone
                </Typography>
                <Typography display="block" color="textSecondary">
                  Delete the Activity.
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <Button
                style={{
                  color: "white",
                  background: "#f44336",
                }}
                onClick={this.deleteActivity.bind(this)}
              >
                Delete Activity
              </Button>
            </ExpansionPanelDetails>
            <Divider />
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ActivityInfo);
