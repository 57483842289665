export const routeNames = [
  "Default",
  "Signin",
  "MyAccount",
  "Goods",
  "Activities",
  "Coupons",
  "Payments",
  "Payment",
  "Good",
  "Projects",
  "Creditcard",
  "Customers",
  "Billing",
  "Customer",
  "Coupon",
  "Cart",
  "Statement",
  "Invoice",
  "Activity",
  "Contacts",
  "Settings",
  "Pricing",
  "Categories",
  "Markets",
  "Market",
  "Tickets",
  "Events",
  "Event",
  "Subscriptions",
];
