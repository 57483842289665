import { networkAction } from "helpers/network/networkAction";

import {
  GET_PRICING_CURRENCIES,
  GET_PRICING_CURRENCY_BY_ID,
  DELETE_PRICING_CURRENCY,
  UPDATE_PRICING_CURRENCY,
  CREATE_PRICING_CURRENCY,
} from "constants/pricingCurrencies";

import * as Api from "api";

export const getPricingCurrencies = () => async (dispatch) =>
  networkAction(dispatch, GET_PRICING_CURRENCIES, Api.getPricingCurrencies, []);

export const getPricingCurrencyById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_PRICING_CURRENCY_BY_ID,
    Api.getPricingCurrencyById,
    [id]
  );

export const deletePricingCurrency = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PRICING_CURRENCY, Api.deletePricingCurrency, [
    id,
  ]);

export const createPricingCurrency = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PRICING_CURRENCY, Api.createPricingCurrency, [
    body,
  ]);

export const updatePricingCurrency = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PRICING_CURRENCY, Api.updatePricingCurrency, [
    id,
    body,
  ]);
