import { GET_SEARCH_PRESETS } from "constants/searchPresets";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_SEARCH_PRESETS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
