import { networkAction } from "helpers/network/networkAction";

import {
  GET_GROUPS,
  GET_GROUP_ROLES,
  DELETE_GROUP_ROLE,
  CREATE_GROUP_ROLE,
  GET_GROUP_BY_ID,
  DELETE_GROUP,
  UPDATE_GROUP,
  CREATE_GROUP,
} from "constants/groups";

import * as Api from "api";

export const getGroups = () => async (dispatch) =>
  networkAction(dispatch, GET_GROUPS, Api.getGroups, []);

export const getGroupRoles = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GROUP_ROLES, Api.getGroupRoles, [id]);

export const createGroupRole = (id, body) => async (dispatch) =>
  networkAction(dispatch, CREATE_GROUP_ROLE, Api.createGroupRole, [id, body]);

export const deleteGroupRole = (id, roleID) => async (dispatch) =>
  networkAction(dispatch, DELETE_GROUP_ROLE, Api.deleteGroupRole, [id, roleID]);

export const getGroupById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_GROUP_BY_ID, Api.getGroupById, [id]);

export const deleteGroup = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_GROUP, Api.deleteGroup, [id]);

export const createGroup = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_GROUP, Api.createGroup, [body]);

export const updateGroup = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_GROUP, Api.updateGroup, [id, body]);
