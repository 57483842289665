import { GET, PUT, DELETE, POST } from "constants/methods";

const service = "bazaar";
const markets = `/${service}/markets`;

export function getMarkets() {
  const url = `${markets}`;
  return {
    method: GET,
    url,
  };
}

export function getMarketById(id) {
  const url = `${markets}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateMarket(id, body) {
  const url = `${markets}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMarket(id) {
  const url = `${markets}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMarket(body) {
  const url = `${markets}`;
  return {
    method: POST,
    url,
    body,
  };
}
