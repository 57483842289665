import { Fab } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Back from "@material-ui/icons/ArrowBack";
import People from "@material-ui/icons/PeopleTwoTone";
import CartsList from "components/CartsList";
import CustomerInfo from "components/CustomerInfo";
import FormPayment from "components/FormPayment";
import InvoicesList from "components/InvoicesList";
// reactor
import Page from "components/Page";
import RecentPaymentsList from "components/RecentPaymentList";
import { CUSTOMERS } from "constants/routes";
import { goBack, updateURLParameter } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperCustomerPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    customer: PropTypes.object,
    updateCustomer: PropTypes.func,
    getInvoiceByID: PropTypes.func,
    validateCart: PropTypes.func,
    refresh: PropTypes.func,
    deleteCustomer: PropTypes.func,
    createPayment: PropTypes.func,
    createInvoice: PropTypes.func,
    loading: PropTypes.bool,
    contacts: PropTypes.array,
    goods: PropTypes.array,
    subscriptions: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getFab() {
    const { index } = this.state;
    const { createInvoice, customer, refresh } = this.props;
    const { NotificationCenter } = this.context;

    switch (index) {
      case 1:
        return (
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => this.setState({ formPaymentOpen: true })}
          >
            <AddIcon />
          </Fab>
        );
      case 2:
        return (
          <Fab
            color="primary"
            aria-label="Add"
            onClick={() => {
              NotificationCenter.sweetAlert(
                {
                  title: "You are about to create a new invoice",
                  subtitle: "Please confirm your action",
                  timestamp: new Date().getTime(),
                  info: true,
                },
                {
                  cancel: {
                    label: "Cancel",
                    level: "default",
                    callback: () => console.log("Callback"),
                  },
                  confirm: {
                    label: "Yes, do it!",
                    level: "info",
                    callback: () =>
                      createInvoice({ customerID: customer.id }).then(refresh),
                  },
                }
              );
            }}
          >
            <AddIcon />
          </Fab>
        );
      default:
        return [];
    }
  }

  getStepContent() {
    const {
      history,
      customer,
      contacts,
      updateCustomer,
      deleteCustomer,
      subscriptions,
      refresh,
    } = this.props;
    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <CustomerInfo
            customer={customer}
            contacts={contacts}
            subscriptions={subscriptions}
            refresh={refresh}
            updateCustomer={updateCustomer}
            deleteCustomer={deleteCustomer}
          />
        );
      case 2:
        return (
          <InvoicesList
            history={history}
            invoices={customer ? customer.invoices : []}
          />
        );
      case 3:
        return (
          <CartsList history={history} carts={customer ? customer.carts : []} />
        );
      case 1:
        return (
          <RecentPaymentsList
            history={history}
            payments={customer && customer.payments ? customer.payments : []}
          />
        );
      // case 2:
      //   return (
      //     <StatementsList
      //       history={history}
      //       statements={customer ? customer.statements : []}
      //     />
      //   );
      // case 4:
      //   return (
      //     <CouponsList
      //       history={history}
      //       coupons={customer ? customer.coupons : []}
      //     />
      //   );
      default:
        return (
          <Typography display="block" variant="title">
            You have gone too far, Sonny Jim !
          </Typography>
        );
    }
  }

  render() {
    const {
      classes,
      history,
      customer,
      loading,
      refresh,
      createPayment,
      validateCart,
      getInvoiceByID,
      goods,
    } = this.props;

    const { index, formPaymentOpen } = this.state;

    return (
      <Page
        helmet="Customer"
        loading={loading}
        loadingMessage={"Loading Customer"}
        fab={this.getFab()}
        header={
          <Grid
            container
            style={{ padding: "10px 0px 0px 10px" }}
            justify="space-between"
          >
            <Grid item>
              <Breadcrumbs
                arial-label="Breadcrumb"
                separator={"-"}
                style={{
                  color: "white",
                }}
              >
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => goBack(history, CUSTOMERS)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
                <Chip
                  label={customer && customer.fullName}
                  className={classes.backChip}
                  variant="contained"
                  color="primary"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <People className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
            </Grid>
          </Grid>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Info"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
            {/* <Tab
              label={(
                <Badge color="primary" badgeContent={customer ? customer.statements.length : 0}>
                  Statements
                </Badge>
              )}
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            /> */}
            <Tab
              label={
                <Badge
                  color="primary"
                  badgeContent={
                    customer && customer.payments ? customer.payments.length : 0
                  }
                >
                  Payments
                </Badge>
              }
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
            {/* <Tab
              label={(
                <Badge color="primary" badgeContent={customer ? customer.coupons.length : 0}>
                  Coupons
                </Badge>
              )}
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            /> */}
            <Tab
              label={
                <Badge
                  color="primary"
                  badgeContent={customer ? customer.invoices.length : 0}
                >
                  Invoices
                </Badge>
              }
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
            <Tab
              label={
                <Badge
                  color="primary"
                  badgeContent={customer ? customer.carts.length : 0}
                >
                  Carts
                </Badge>
              }
              classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            />
          </Tabs>
        }
      >
        <div
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "100%",
            width: "100%",
          }}
        >
          {this.getStepContent()}
        </div>
        <FormPayment
          open={formPaymentOpen}
          getInvoiceByID={getInvoiceByID}
          close={() => {
            this.setState({
              formPaymentOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedPayment: undefined,
                }),
              200
            );
          }}
          validateCart={validateCart}
          createPayment={createPayment}
          customer={customer}
          goods={goods}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCustomerPage);
