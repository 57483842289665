import { networkAction } from "helpers/network/networkAction";

import {
  GET_TICKETS,
  GET_TICKET_BY_ID,
  DELETE_TICKET,
  UPDATE_TICKET,
  CREATE_TICKET,
} from "constants/tickets";

import * as Api from "api";

export const getTickets = () => async (dispatch) =>
  networkAction(dispatch, GET_TICKETS, Api.getTickets, []);

export const getTicketById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TICKET_BY_ID, Api.getTicketById, [id]);

export const deleteTicket = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TICKET, Api.deleteTicket, [id]);

export const createTicket = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TICKET, Api.createTicket, [body]);

export const updateTicket = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TICKET, Api.updateTicket, [id, body]);
