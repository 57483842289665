import Avatar from "@material-ui/core/Avatar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import Back from "@material-ui/icons/ArrowBack";
import Edit from "@material-ui/icons/Edit";
import Banners from "components/Banners";
// custom
import FormAisle from "components/FormAisle";
import FormMarket from "components/FormMarket";
// reactor
import Page from "components/Page";
import Shelves from "components/Shelves";
// config
import { PLACEHOLDER } from "config";
// routes
import { MARKETS } from "constants/routes";
import { goBack, updateURLParameter } from "helpers";
import hotkeys from "hotkeys-js";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperMarketPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    market: PropTypes.object,
    loading: PropTypes.bool,
    refresh: PropTypes.func,
    refreshKey: PropTypes.number,
    goods: PropTypes.array,

    createAisle: PropTypes.func,
    deleteAisle: PropTypes.func,
    updateAisle: PropTypes.func,

    createBanner: PropTypes.func,
    deleteBanner: PropTypes.func,
    updateBanner: PropTypes.func,

    createShelve: PropTypes.func,
    deleteShelve: PropTypes.func,
    updateShelve: PropTypes.func,

    createShelveGood: PropTypes.func,
    deleteShelveGood: PropTypes.func,
    updateShelveGood: PropTypes.func,

    deleteMarket: PropTypes.func,
    updateMarket: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      formAisleOpen: false,
      formMarketOpen: false,
      alt: false,
      edit: false,
    };

    hotkeys("*", (e) => {
      if (hotkeys.alt) {
        this.setState({ alt: true });
      }
    });

    document.addEventListener("keyup", () => {
      this.setState({ alt: false });
    });
  }

  render() {
    const {
      classes,
      history,
      loading,
      refresh,
      market,
      createAisle,
      deleteAisle,
      updateAisle,
      createBanner,
      deleteBanner,
      updateBanner,
      createShelve,
      updateShelve,
      deleteShelve,
      createShelveGood,
      deleteShelveGood,
      updateShelveGood,
      refreshKey,
      deleteMarket,
      updateMarket,
      goods,
    } = this.props;

    const {
      index,
      selectedAisle,
      formAisleOpen,
      alt,
      edit,
      formMarketOpen,
    } = this.state;

    const aisle = market && market.aisles[index];

    return (
      <Page
        helmet="Market"
        loadingMessage={"Loading Market"}
        loading={loading}
        header={
          <Grid container style={{ padding: 10 }} justify="space-between">
            <Grid item>
              <Breadcrumbs
                arial-label="Breadcrumb"
                separator={"-"}
                style={{
                  color: "white",
                }}
              >
                <Chip
                  label={"Back"}
                  className={classes.backChip}
                  onClick={() => goBack(history, MARKETS)}
                  variant="outlined"
                  avatar={
                    <Avatar className={classes.avatar}>
                      <Back className={classes.icon} />
                    </Avatar>
                  }
                />
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Chip
                    label={market && market.name}
                    variant="outlined"
                    avatar={
                      <Avatar style={{ background: "none" }}>
                        <Edit />
                      </Avatar>
                    }
                    onClick={() => this.setState({ formMarketOpen: true })}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={edit}
                        onChange={() => this.setState({ edit: !edit })}
                        value="edit"
                      />
                    }
                    label="Edit Mode"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push({
                search: updateURLParameter("index", v),
              });
            }}
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{ root: classes.tabsRoot, indicator: "tabsIndicator" }}
          >
            {market &&
              market.aisles &&
              market.aisles.map((a) => (
                <Tab
                  label={
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <img
                          src={a.icon}
                          alt="category"
                          style={{ height: 20, width: 20 }}
                          className={classes.icon}
                        />
                      </Grid>
                      <Grid item>
                        <Typography display="block" variant="body2">
                          {a.name}
                        </Typography>
                      </Grid>
                      {edit ? (
                        <Grid item>
                          <IconButton
                            style={{ padding: 5 }}
                            onClick={() =>
                              this.setState({
                                formAisleOpen: true,
                                selectedAisle: a,
                              })
                            }
                          >
                            <Edit style={{ fontSize: 18 }} />
                          </IconButton>
                        </Grid>
                      ) : (
                        []
                      )}
                    </Grid>
                  }
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected,
                  }}
                />
              ))}
            {edit ? (
              <Chip
                label="Aisle"
                variant="outlined"
                style={{ marginTop: 5 }}
                avatar={
                  <Avatar style={{ background: "none" }}>
                    <Add />
                  </Avatar>
                }
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  await createAisle({
                    marketID: market.id,
                    icon: PLACEHOLDER,
                    name: "New Aisle",
                  });
                  refresh();
                }}
              />
            ) : (
              []
            )}
          </Tabs>
        }
      >
        <div
          key={`${refreshKey}_${aisle ? aisle.id : 0}`}
          style={{
            maxWidth: 1500,
            margin: "auto",
            height: "calc(100% - 10px)",
            width: "calc(100% - 20px)",
            padding: "10px 10px 0px 10px",
          }}
        >
          {aisle ? (
            <Grid container>
              <Grid item xs={12}>
                <Banners
                  banners={aisle.banners}
                  createBanner={createBanner}
                  deleteBanner={deleteBanner}
                  updateBanner={updateBanner}
                  refresh={refresh}
                  edit={edit}
                  aisleID={aisle.id}
                  alt={alt}
                />
              </Grid>
            </Grid>
          ) : (
            []
          )}
          {aisle ? (
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={12}>
                <Shelves
                  shelves={aisle.shelves}
                  createShelve={createShelve}
                  deleteShelve={deleteShelve}
                  updateShelve={updateShelve}
                  createShelveGood={createShelveGood}
                  deleteShelveGood={deleteShelveGood}
                  updateShelveGood={updateShelveGood}
                  refresh={refresh}
                  aisleID={aisle.id}
                  edit={edit}
                  goods={goods}
                  alt={alt}
                />
              </Grid>
            </Grid>
          ) : (
            []
          )}
        </div>
        <FormAisle
          open={formAisleOpen}
          aisle={selectedAisle}
          close={() => {
            this.setState({
              formAisleOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedAisle: undefined,
                }),
              200
            );
          }}
          createAisle={createAisle}
          deleteAisle={deleteAisle}
          updateAisle={updateAisle}
          refresh={refresh}
        />
        <FormMarket
          open={formMarketOpen}
          market={market}
          close={() => {
            this.setState({
              formMarketOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedMarket: undefined,
                }),
              200
            );
          }}
          deleteMarket={deleteMarket}
          updateMarket={updateMarket}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperMarketPage);
